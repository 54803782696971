import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import styled from 'styled-components';
import profilePic from '../images/Anonymous-Profile-pic.jpg';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background: #FFFFFF;
  border-bottom: 1px solid #EAEAEA;
`;

const Logo = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  color: #000000;
  cursor: pointer;
  margin: 0;
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

const NavButton = styled.button`
  background: none;
  border: none;
  color: #666;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.5rem 0;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #000;
    transition: width 0.2s ease;
  }

  &:hover {
    color: #000;
    &:after {
      width: 100%;
    }
  }
`;

const ActionButton = styled(NavButton)`
  background-color: ${props => props.primary ? '#000' : '#FFF'};
  color: ${props => props.primary ? '#FFF' : '#000'};
  border: 1px solid #000;
  padding: 0.5rem 1rem;
  border-radius: 4px;

  &:hover {
    background-color: ${props => props.primary ? '#333' : '#F5F5F5'};
    &:after {
      width: 0;
    }
  }
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const ProfilePic = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  object-fit: cover;
`;

const Header = () => {
  const navigate = useNavigate();
  const { user, setUser } = useUser();

  const handleNavigation = (path) => () => navigate(path);

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('authToken');
    navigate('/');
  };

  return (
    <HeaderContainer>
      <Logo onClick={handleNavigation('/')}>CvAnalyst</Logo>
      <Nav>
        {/*<NavButton onClick={handleNavigation('/search-jobs')}>
          Job Search
        </NavButton>
        <NavButton onClick={handleNavigation('/resume-enhancer')}>
          Resume
        </NavButton>
        <NavButton onClick={handleNavigation('/cover-letter-generator')}>
          Cover Letter
        </NavButton>*/}
         <NavButton onClick={handleNavigation('/linkedin-job-id')}>
          Job Details
        </NavButton>
        
        <NavButton onClick={handleNavigation('/features')}>
          Features
        </NavButton>
        <NavButton onClick={handleNavigation('/billing')}>
          Billing
        </NavButton>
        {user ? (
          <ProfileContainer>
            <NavButton onClick={handleNavigation('/profile')}>
              Profile
            </NavButton>
            <ActionButton onClick={handleLogout}>
              Logout
            </ActionButton>
            <ProfilePic src={profilePic} alt="Profile" />
          </ProfileContainer>
        ) : (
          <ActionButton primary onClick={handleNavigation('/signin')}>
            Login
          </ActionButton>
        )}
      </Nav>
    </HeaderContainer>
  );
};

export default Header;
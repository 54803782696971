import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaBriefcase, FaFileAlt, FaFileWord, FaComments, FaQuestion, FaSearch } from 'react-icons/fa';

const Features = () => {
  const navigate = useNavigate();

  const features = [
    {
      title: 'Job Details Extractor',
      description: 'Extract and analyze job details from LinkedIn job postings',
      path: '/linkedin-job-id',
      icon: <FaBriefcase />
    },
    {
      title: 'Resume Enhancer',
      description: 'Optimize your resume with AI-powered suggestions and improvements',
      path: '/resume-enhancer',
      icon: <FaFileAlt />
    },
    {
      title: 'Cover Letter Generator',
      description: 'Create personalized cover letters tailored to specific job descriptions',
      path: '/cover-letter-generator',
      icon: <FaFileWord />
    },
    {
      title: 'Interview Response Enhancer',
      description: 'Get AI-powered help with STAR method interview responses',
      path: '/interview-enhancer',
      icon: <FaComments />
    },
    {
      title: 'Strategic Question Generator',
      description: 'Generate thoughtful questions to ask during your interview',
      path: '/question-generator',
      icon: <FaQuestion />
    },
    {
      title: 'Pre-Interview Assistant',
      description: 'Research companies and prepare for interviews with comprehensive insights',
      path: '/pre-interview-assistant',
      icon: <FaSearch />
    }
  ];

  return (
    <Container>
      <ContentWrapper>
        <Title>Tools</Title>
        <Subtitle>Everything you need to land your dream job</Subtitle>
        
        <FeaturesGrid>
          {features.map((feature, index) => (
            <FeatureCard 
              key={index}
              onClick={() => navigate(feature.path)}
            >
              <IconContainer>
                {feature.icon}
              </IconContainer>
              <FeatureTitle>{feature.title}</FeatureTitle>
              <FeatureDescription>{feature.description}</FeatureDescription>
              <UseButton>Use Tool</UseButton>
            </FeatureCard>
          ))}
        </FeaturesGrid>
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const ContentWrapper = styled.div`
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: 600;
  color: #000;
  margin-bottom: 1rem;
  text-align: center;
`;

const Subtitle = styled.p`
  text-align: center;
  color: #666;
  margin-bottom: 3rem;
  font-size: 1.2rem;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 1rem;
`;

const FeatureCard = styled.div`
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  border-radius: 8px;
  padding: 2rem;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    border-color: #000;
  }
`;

const IconContainer = styled.div`
  font-size: 1.5rem;
  color: #000;
  margin-bottom: 1.5rem;
  padding: 1rem;
  background: #F5F5F5;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;

  ${FeatureCard}:hover & {
    background: #000;
    color: #FFF;
  }
`;

const FeatureTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 1rem;
`;

const FeatureDescription = styled.p`
  color: #666;
  font-size: 0.9rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
  flex-grow: 1;
`;

const UseButton = styled.button`
  background: #000;
  color: #FFF;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  opacity: 0;
  transform: translateY(10px);
  transition: all 0.2s ease;

  ${FeatureCard}:hover & {
    opacity: 1;
    transform: translateY(0);
  }

  &:hover {
    background: #333;
  }
`;

export default Features;
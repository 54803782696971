import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaSpinner, FaCheckCircle, FaTimesCircle, FaExclamationTriangle, FaUpload } from 'react-icons/fa';
import axios from 'axios';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://cvanalystiobackend.onrender.com';

const COLORS = {
  primary: {
    main: '#000000',
    light: '#1A1A1A',
    dark: '#000000'
  },
  text: {
    primary: '#000000',
    secondary: '#4A4A4A',
    light: '#717171',
    white: '#FFFFFF'
  },
  background: {
    primary: '#FFFFFF',
    secondary: '#F8F8F8',
    accent: '#F3F4F6'
  },
  border: '#E5E5E5',
  success: '#4CAF50',
  warning: '#FFA726',
  error: '#FF5252'
};

const AtsScan = () => {
  const [scanStep, setScanStep] = useState('upload');
  const [scanResults, setScanResults] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resume, setResume] = useState(null);
  const [resumeName, setResumeName] = useState('');
  const [email, setEmail] = useState('');
  const [showEmailForm, setShowEmailForm] = useState(false);

  const scanSteps = [
    { id: 'format', text: 'Analyzing document format...', icon: FaSpinner },
    { id: 'keywords', text: 'Identifying key terms...', icon: FaSpinner },
    { id: 'structure', text: 'Evaluating resume structure...', icon: FaSpinner },
    { id: 'compatibility', text: 'Checking ATS compatibility...', icon: FaSpinner }
  ];

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        setError("File size too large. Please upload a file under 5MB.");
        return;
      }
      if (!file.name.match(/\.docx$/i)) {
        setError("Please upload a DOCX file only. This ensures the best compatibility with ATS systems.");
        return;
      }
      setResume(file);
      setResumeName(file.name);
      setError(null);
    }
  };

  const handleStartScan = async (e) => {
    e.preventDefault();
    if (!resume) {
      setError("Please upload a resume");
      return;
    }

    try {
      setLoading(true);
      setError(null);
      setScanStep('scanning');

      // Get context from localStorage
      const atsContext = JSON.parse(localStorage.getItem('atsContext'));
      if (!atsContext) {
        throw new Error('Missing context information. Please start over.');
      }

      // Create form data
      const formData = new FormData();
      formData.append('resume', resume);
      formData.append('current_title', atsContext.currentTitle);
      formData.append('experience', atsContext.experience);
      formData.append('target_title', atsContext.targetTitle);

      // Call analyze_resume endpoint
      const response = await axios.post(
        `${BACKEND_API_URL}/analyze_resume`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data.status === 'success') {
        setScanResults(response.data.analysis);
        setShowEmailForm(true);
        setScanStep('emailCapture');
      } else {
        throw new Error('Analysis failed');
      }
    } catch (err) {
      setError(err.message || 'An error occurred during analysis');
      setScanStep('error');
    } finally {
      setLoading(false);
    }
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setError("Please enter your email");
      return;
    }
    
    try {
      setLoading(true);
      setError(null);
      
      const formData = new FormData();
      formData.append('email', email);
      formData.append('analysis_result', JSON.stringify(scanResults));
      
      const response = await axios.post(
        `${BACKEND_API_URL}/save_ats_results`, 
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          timeout: 60000, // 1 minute timeout for email submission
          timeoutErrorMessage: 'Request timed out - please try again'
        }
      );
      
      setScanStep('success');
      
    } catch (err) {
      let errorMessage;
      
      if (err.response?.status === 400 && err.response?.data?.detail?.includes('Free trial already used')) {
        errorMessage = 'This email has already been used for a free trial. Please use a different email or upgrade to Pro for unlimited access.';
      } else if (err.response?.status === 500 && (
        err.response?.data?.detail?.includes('duplicate key') || 
        err.response?.data?.detail?.includes('already exists')
      )) {
        errorMessage = 'This email has already been used for a free trial. Please use a different email or upgrade to Pro for unlimited access.';
      } else if (err.message === 'Network Error') {
        errorMessage = 'Network error - please check your connection and try again';
      } else if (err.message === 'Request timed out - please try again') {
        errorMessage = err.message;
      } else {
        errorMessage = 'Something went wrong. Please try again later.';
      }
      
      setError(errorMessage);
      
      // If it's a duplicate email error, show the upgrade button
      if (errorMessage.includes('already been used')) {
        setScanStep('upgrade');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleGetEnhancedResume = async () => {
    try {
      setLoading(true);
      
      // Get context and resume from localStorage
      const atsContext = JSON.parse(localStorage.getItem('atsContext'));
      const resume = localStorage.getItem('resume');

      // Create form data
      const formData = new FormData();
      formData.append('resume', resume);
      formData.append('current_title', atsContext.currentTitle);
      formData.append('experience', atsContext.experience);
      formData.append('target_title', atsContext.targetTitle);
      formData.append('analysis_result', JSON.stringify(scanResults));

      // Call get_enhanced_resume endpoint
      const response = await axios.post(
        `${BACKEND_API_URL}/get_enhanced_resume`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data.status === 'success') {
        // Handle the enhanced resume (e.g., download or display)
        const blob = new Blob([response.data.enhanced_resume], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'enhanced_resume.docx';
        a.click();
      } else {
        throw new Error('Enhancement failed');
      }
    } catch (err) {
      setError(err.message || 'An error occurred while enhancing the resume');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      {scanStep === 'upload' && (
        <UploadSection>
          <Title>Ready to scan your resume</Title>
          <Subtitle>Upload your resume to check its ATS compatibility score.</Subtitle>
          
          <Form onSubmit={handleStartScan}>
            <FileUploadContainer>
              <FileInput
                type="file"
                onChange={handleFileChange}
                accept=".docx"
                id="resume-upload"
              />
              <FileUploadLabel htmlFor="resume-upload">
                <FaUpload />
                {resumeName || "Upload Resume (DOCX only)"}
              </FileUploadLabel>
              <FileHelperText>We require DOCX format to ensure accurate ATS analysis</FileHelperText>
            </FileUploadContainer>

            <SubmitButton type="submit" disabled={loading}>
              {loading ? <FaSpinner className="spinning" /> : "Analyze My Resume →"}
            </SubmitButton>
          </Form>
          
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </UploadSection>
      )}

      {scanStep === 'scanning' && (
        <ScanningSection>
          <Title>Analyzing Your Resume</Title>
          <Subtitle>Please wait while we scan your document</Subtitle>
          
          <StepsList>
            {scanSteps.map((step, index) => (
              <ScanStep key={step.id}>
                <StepIcon>
                  <step.icon className={index === scanSteps.findIndex(s => s.icon === FaSpinner) ? 'spinning' : ''} />
                </StepIcon>
                <StepText>{step.text}</StepText>
              </ScanStep>
            ))}
          </StepsList>
        </ScanningSection>
      )}

      {scanStep === 'emailCapture' && (
        <EmailCaptureSection>
          <BlurredPreview>
            <ScoreCircle score={scanResults.score}>
              <ScoreText>{scanResults.score}%</ScoreText>
              <ScoreLabel>Match Score</ScoreLabel>
            </ScoreCircle>
            <SummaryText>{scanResults.summary}</SummaryText>
            <BlurOverlay />
          </BlurredPreview>
          
          {showEmailForm && (
            <EmailForm onSubmit={handleEmailSubmit}>
              <EmailTitle>Enter your email to get your detailed ATS analysis</EmailTitle>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
              />
              <SubmitButton type="submit" disabled={loading}>
                {loading ? 'Processing...' : 'Get My Analysis'}
              </SubmitButton>
              <SmallText>One free attempt per user. No spam, just your ATS analysis.</SmallText>
            </EmailForm>
          )}
          
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </EmailCaptureSection>
      )}

      {scanStep === 'success' && (
        <SuccessSection>
          <SuccessTitle>🎉 Check Your Email!</SuccessTitle>
          <SuccessMessage>
            We've sent your detailed ATS analysis to {email}. 
            Don't forget to check your spam folder if you don't see it in your inbox.
          </SuccessMessage>
          <UpgradeMessage>
            Want unlimited ATS scans and resume optimizations?
          </UpgradeMessage>
          <UpgradeButton
            as="a"
            href="https://analyticpinnacle.thrivecart.com/cv-analyst/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Upgrade to Pro - 20% Off
          </UpgradeButton>
        </SuccessSection>
      )}

      {scanStep === 'upgrade' && (
        <SuccessSection>
          <UpgradeMessage>
            You've already used your free trial. Upgrade to Pro for unlimited access!
          </UpgradeMessage>
          <UpgradeButton
            as="a"
            href="https://analyticpinnacle.thrivecart.com/cv-analyst/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Upgrade to Pro - 20% Off
          </UpgradeButton>
        </SuccessSection>
      )}

      {scanStep === 'error' && (
        <ErrorSection>
          <ErrorIcon>
            <FaTimesCircle />
          </ErrorIcon>
          <ErrorMessage>{error}</ErrorMessage>
          <RetryButton onClick={() => setScanStep('upload')}>
            Try Again
          </RetryButton>
        </ErrorSection>
      )}
    </Container>
  );
};

// Animation keyframes
const spin = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled components
const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const Title = styled.h1`
  font-size: 2rem;
  color: ${COLORS.text.primary};
  margin-bottom: 1rem;
  text-align: center;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  color: ${COLORS.text.secondary};
  margin-bottom: 2rem;
  text-align: center;
`;

const ScanningSection = styled.div`
  animation: ${fadeIn} 0.6s ease-out;
`;

const StepsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 500px;
  margin: 0 auto;
`;

const ScanStep = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: ${COLORS.background.secondary};
  border-radius: 8px;
`;

const StepIcon = styled.div`
  .spinning {
    animation: ${spin} 1s linear infinite;
  }
`;

const StepText = styled.span`
  color: ${COLORS.text.primary};
`;

const ResultsSection = styled.div`
  animation: ${fadeIn} 0.6s ease-out;
`;

const ScoreSection = styled.div`
  text-align: center;
  margin-bottom: 3rem;
`;

const ScoreCircle = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 8px solid ${props => {
    if (props.score >= 80) return COLORS.success;
    if (props.score >= 60) return COLORS.warning;
    return COLORS.error;
  }};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

const ScoreText = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  color: ${COLORS.text.primary};
`;

const ScoreLabel = styled.div`
  font-size: 1rem;
  color: ${COLORS.text.secondary};
`;

const AnalysisSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 2rem 0;
`;

const Section = styled.div`
  background: ${COLORS.background.secondary};
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: ${COLORS.text.primary};
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 1rem 0 0 0;
`;

const ListItem = styled.li`
  padding: 1rem;
  margin: 0.5rem 0;
  background: ${COLORS.background.primary};
  border-radius: 8px;
  border-left: 4px solid ${props => {
    switch (props.type) {
      case 'high':
        return COLORS.error;
      case 'medium':
        return COLORS.warning;
      case 'low':
        return COLORS.success;
      case 'strength':
        return COLORS.success;
      case 'improvement':
        return COLORS.warning;
      case 'pass':
        return COLORS.success;
      case 'fail':
        return COLORS.error;
      default:
        return COLORS.border;
    }
  }};
  line-height: 1.5;

  .recommendation-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .points-impact {
    color: ${COLORS.text.secondary};
    font-size: 0.9rem;
  }

  .recommendation-action {
    font-size: 1.1rem;
    margin: 0.5rem 0;
    color: ${COLORS.text.primary};
  }

  .recommendation-example {
    background: ${COLORS.background.secondary};
    padding: 0.75rem;
    border-radius: 4px;
    margin-top: 0.5rem;
  }

  .example-label {
    font-weight: bold;
    margin-bottom: 0.25rem;
    color: ${COLORS.text.secondary};
  }

  .example-before {
    color: ${COLORS.text.light};
    padding-left: 1rem;
    border-left: 2px solid ${COLORS.warning};
    margin-bottom: 0.25rem;
  }

  .example-after {
    color: ${COLORS.text.primary};
    padding-left: 1rem;
    border-left: 2px solid ${COLORS.success};
  }
`;

const KeywordList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
`;

const KeywordItem = styled.div`
  background: ${props => props.found ? 
    `${COLORS.success}10` : 
    `${COLORS.warning}10`
  };
  border: 1px solid ${props => props.found ? 
    COLORS.success : 
    COLORS.warning
  };
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 0.5rem;
  width: 100%;

  .keyword {
    font-weight: bold;
    color: ${props => props.found ? COLORS.success : COLORS.warning};
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }

  .context {
    color: ${COLORS.text.secondary};
    font-size: 0.9rem;
    margin-bottom: ${props => props.found ? '0' : '0.5rem'};
  }

  .effectiveness {
    display: inline-block;
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
    font-size: 0.8rem;
    margin-top: 0.5rem;
    background: ${props => {
      switch (props.effectiveness) {
        case 'high':
          return `${COLORS.success}20`;
        case 'medium':
          return `${COLORS.warning}20`;
        case 'low':
          return `${COLORS.error}20`;
        default:
          return 'transparent';
      }
    }};
    color: ${props => {
      switch (props.effectiveness) {
        case 'high':
          return COLORS.success;
        case 'medium':
          return COLORS.warning;
        case 'low':
          return COLORS.error;
        default:
          return COLORS.text.secondary;
      }
    }};
  }

  .example {
    background: ${COLORS.background.primary};
    padding: 0.5rem;
    border-radius: 4px;
    margin-top: 0.5rem;
    font-size: 0.9rem;
    color: ${COLORS.text.primary};
  }
`;

const ActionSection = styled.div`
  text-align: center;
  margin-top: 3rem;
`;

const ActionButton = styled.button`
  padding: 1rem 2rem;
  background-color: ${COLORS.primary.main};
  color: ${COLORS.text.white};
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: ${COLORS.primary.light};
    transform: translateY(-2px);
  }
`;

const ErrorSection = styled.div`
  text-align: center;
  padding: 3rem;
`;

const ErrorIcon = styled.div`
  font-size: 3rem;
  color: ${COLORS.error};
  margin-bottom: 1rem;
`;

const ErrorMessage = styled.p`
  color: ${COLORS.text.primary};
  margin-bottom: 2rem;
`;

const RetryButton = styled(ActionButton)`
  background-color: ${COLORS.primary.main};
`;

const UploadSection = styled.div`
  text-align: center;
  padding: 2rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 500px;
  margin: 0 auto;
`;

const FileUploadContainer = styled.div`
  position: relative;
`;

const FileInput = styled.input`
  display: none;
`;

const FileUploadLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  border: 2px dashed ${COLORS.border};
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s;

  &:hover {
    border-color: ${COLORS.primary.main};
  }
`;

const FileHelperText = styled.p`
  font-size: 0.8rem;
  color: ${COLORS.text.secondary};
  margin-top: 0.5rem;
`;

const SubmitButton = styled.button`
  padding: 1rem 2rem;
  background-color: ${COLORS.primary.main};
  color: ${COLORS.text.white};
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: ${COLORS.primary.light};
    transform: translateY(-2px);
  }
`;

const SummaryText = styled.p`
  font-size: 1.1rem;
  color: ${COLORS.text.secondary};
  text-align: center;
  margin-top: 1rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
`;

const EmailCaptureSection = styled.div`
  text-align: center;
  padding: 2rem;
`;

const BlurredPreview = styled.div`
  position: relative;
  background: ${COLORS.background.secondary};
  padding: 2rem;
  border-radius: 12px;
  margin-bottom: 2rem;
  overflow: hidden;
`;

const BlurOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: ${COLORS.text.primary};
`;

const EmailForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 400px;
  margin: 0 auto;
`;

const EmailTitle = styled.h3`
  margin-bottom: 1rem;
`;

const Input = styled.input`
  padding: 0.8rem;
  border: 1px solid ${COLORS.border};
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;
  
  &:focus {
    outline: none;
    border-color: ${COLORS.primary.main};
  }
`;

const SmallText = styled.p`
  font-size: 0.8rem;
  color: ${COLORS.text.light};
`;

const SuccessSection = styled.div`
  text-align: center;
  padding: 2rem;
`;

const SuccessTitle = styled.h2`
  color: ${COLORS.success};
  margin-bottom: 1rem;
`;

const SuccessMessage = styled.p`
  margin-bottom: 2rem;
`;

const UpgradeMessage = styled.p`
  font-size: 1.2rem;
  margin-bottom: 1rem;
`;

const UpgradeButton = styled(SubmitButton)`
  display: inline-block;
  text-decoration: none;
  background-color: ${COLORS.success};

  &:hover {
    background-color: ${COLORS.success}dd;
  }
`;

export default AtsScan; 
import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const Poof = () => {
  const [selectedPlan, setSelectedPlan] = useState('1monthbasic');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState(''); // Add email state

  const handleCheckout = async () => {
    setLoading(true);
    setError(null);
  
    try {
      const response = await axios.post(`${BACKEND_API_URL}/checkout`, {
        subscription_type: selectedPlan,
        email: email // Ensure email is provided
      });
  
      if (response.data.checkout_url) {
        window.location.href = response.data.checkout_url;
      } else {
        setError('Failed to create checkout session.');
      }
    } catch (error) {
      console.error('Error during checkout:', error);
      setError('Failed to create checkout session. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Title>Choose Your Subscription Plan</Title>
      <PlanSelector>
        <label>
          <input
            type="radio"
            value="1monthbasic"
            checked={selectedPlan === '1monthbasic'}
            onChange={() => setSelectedPlan('1monthbasic')}
          />
          1 Month Basic - $35.00
        </label>
        <label>
          <input
            type="radio"
            value="1monthpro"
            checked={selectedPlan === '1monthpro'}
            onChange={() => setSelectedPlan('1monthpro')}
          />
          1 Month Pro - $97.00
        </label>
      </PlanSelector>
      <EmailInput
        type="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <CheckoutButton onClick={handleCheckout} disabled={loading}>
        {loading ? 'Processing...' : 'Proceed to Checkout'}
      </CheckoutButton>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const PlanSelector = styled.div`
  margin-bottom: 1rem;
  label {
    display: block;
    margin-bottom: 0.5rem;
  }
`;

const EmailInput = styled.input`
  margin-bottom: 1rem;
  padding: 0.5rem;
  width: 100%;
  max-width: 300px;
`;

const CheckoutButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: purple;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 1rem;
`;

export default Poof;
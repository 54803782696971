import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { FaSpinner, FaCheckCircle, FaUpload } from 'react-icons/fa';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://cvanalystiobackend.onrender.com';

const FreeHiringResume = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [step, setStep] = useState('upload');
  const [linkedinUrl, setLinkedinUrl] = useState('');
  const [resume, setResume] = useState(null);
  const [resumeName, setResumeName] = useState('');
  const [email, setEmail] = useState('');
  const [packageData, setPackageData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [processingStep, setProcessingStep] = useState(0);
  const [activeTab, setActiveTab] = useState('resume');
  const [hiringManagerInfo, setHiringManagerInfo] = useState(null);
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [hasAccessToContent, setHasAccessToContent] = useState(false);

  const processingSteps = [
    "Analyzing your resume and job description...",
    "Enhancing your resume with targeted keywords...",
    "Crafting your personalized cover letter...",
    "Preparing your outreach email...",
    "Generating your complete application package!"
  ];

  const extractJobIdFromUrl = (url) => {
    // Handle search result format with currentJobId
    const searchMatch = url.match(/currentJobId=(\d+)/);
    if (searchMatch) return searchMatch[1];
    
    // Handle direct job view format - match only the last number in the URL
    const viewMatch = url.match(/(?:jobs\/view\/.*-)(\d+)\/?$/);
    if (viewMatch) return viewMatch[1];
    
    // If the input is just a number, return it directly
    if (/^\d+$/.test(url)) return url;
    
    return null;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
        if (!file.name.toLowerCase().endsWith('.docx')) {
            setError("Please upload a DOCX file only. PDFs are not supported.");
            setResume(null);
            setResumeName('');
            return;
        }
        if (file.size > 5 * 1024 * 1024) {
            setError("File size too large. Please upload a file under 5MB.");
            return;
        }
        setResume(file);
        setResumeName(file.name);
        setError(null);
    }
  };

  // Add useEffect to handle pre-filled job URL and hiring manager info
  useEffect(() => {
    // Reset access state to ensure email wall works
    setHasAccessToContent(false);
    
    const params = new URLSearchParams(location.search);
    const jobUrl = params.get('jobUrl');
    console.log('URL from params:', jobUrl);
    if (jobUrl) {
      setLinkedinUrl(jobUrl);
    }
    
    // Get hiring manager info from localStorage
    const storedJob = localStorage.getItem('selectedJob');
    if (storedJob) {
      const jobData = JSON.parse(storedJob);
      setHiringManagerInfo(jobData.hiring_manager);
      console.log('Hiring manager info:', jobData.hiring_manager);
    }
    
    // Check if user already has an email stored - just pre-fill the email field
    // but don't skip the email capture step
    const storedEmail = localStorage.getItem('userEmail');
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, [location]);

  // Add a function to check if the user has already submitted their email
  const handlePackageGenerated = (data) => {
    console.log('handlePackageGenerated called with data:', data);
    console.log('Data contains resume:', Boolean(data?.resume));
    console.log('Data contains cover_letter:', Boolean(data?.cover_letter));
    console.log('Data contains outreach_email:', Boolean(data?.outreach_email));
    
    setPackageData(data);
    setShowEmailForm(true);
    setLoading(false);
    // Always show email capture first
    setStep('emailCapture');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!resume || !linkedinUrl) {
      setError("Please provide your resume and LinkedIn job URL");
      return;
    }

    try {
      setStep('processing');
      setLoading(true);
      setError(null);

      const jobId = extractJobIdFromUrl(linkedinUrl);
      console.log('LinkedIn URL:', linkedinUrl);
      console.log('Extracted Job ID:', jobId);
      
      if (!jobId) {
        setError('Invalid LinkedIn job URL');
        setLoading(false);
        setStep('upload');
        return;
      }

      // Create form data with resume and job ID
      const formData = new FormData();
      formData.append('resume', resume);
      formData.append('job_id', jobId);
      
      // Add hiring manager info if available
      if (hiringManagerInfo) {
        formData.append('hiring_manager_name', hiringManagerInfo.name || '');
        formData.append('hiring_manager_title', hiringManagerInfo.title || '');
        formData.append('hiring_manager_linkedin', hiringManagerInfo.linkedin_url || '');
        formData.append('hiring_manager_email', hiringManagerInfo.email || '');
        console.log('Hiring manager info added to request:', hiringManagerInfo);
      }

      // Simulate processing steps
      const processingInterval = setInterval(() => {
        setProcessingStep(prev => {
          if (prev < processingSteps.length - 1) {
            return prev + 1;
          }
          clearInterval(processingInterval);
          return prev;
        });
      }, 2000);

      console.log('Sending request to free_hiring_complete_package...');
      
      const response = await axios.post(
        `${BACKEND_API_URL}/free_hiring_complete_package`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          timeout: 120000,
        }
      );

      clearInterval(processingInterval);
      
      console.log('Response from free_hiring_complete_package:', response.data);
      console.log('Response contains resume:', Boolean(response.data?.resume));
      console.log('Response contains cover_letter:', Boolean(response.data?.cover_letter));
      console.log('Response contains outreach_email:', Boolean(response.data?.outreach_email));
      
      // Use the new function to handle the response
      handlePackageGenerated(response.data);
      
    } catch (error) {
      console.error('Error:', error);
      setError(error.response?.data?.detail || 'An error occurred while processing your request.');
      setStep('upload');
    } finally {
      setLoading(false);
    }
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setError("Please enter your email");
      return;
    }
    
    try {
      setLoading(true);
      setError(null);
      
      // Create form data with all necessary fields
      const formData = new FormData();
      formData.append('email', email);
      
      // Debug logging
      console.log("Package data:", packageData);
      
      // Include resume text and file
      if (packageData?.resume) {
        formData.append('resume_text', packageData.resume.text || '');
        formData.append('resume_file', packageData.resume.file || '');
        console.log("Resume text length:", packageData.resume.text?.length || 0);
        console.log("Resume file length:", packageData.resume.file?.length || 0);
      } else {
        setError("Resume data is missing. Please try again.");
        setLoading(false);
        return;
      }
      
      // Include cover letter if available
      if (packageData?.cover_letter) {
        formData.append('cover_letter_text', packageData.cover_letter.text || '');
        formData.append('cover_letter_file', packageData.cover_letter.file || '');
        console.log("Cover letter text length:", packageData.cover_letter.text?.length || 0);
        console.log("Cover letter file length:", packageData.cover_letter.file?.length || 0);
      } else {
        console.log("No cover letter data available");
      }
      
      // Include outreach email if available
      if (packageData?.outreach_email) {
        formData.append('outreach_email_text', packageData.outreach_email.text || '');
        console.log("Outreach email text length:", packageData.outreach_email.text?.length || 0);
      } else {
        console.log("No outreach email data available");
      }
      
      // Include hiring manager email if available
      if (packageData?.hiring_manager_email) {
        formData.append('hiring_manager_email', packageData.hiring_manager_email || '');
        console.log("Hiring manager email included:", packageData.hiring_manager_email);
      } else {
        console.log("No hiring manager email available");
      }
      
      console.log("Sending data to backend...");
      
      const response = await axios.post(
        `${BACKEND_API_URL}/save_free_trial`, 
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          timeout: 60000,
          timeoutErrorMessage: 'Request timed out - please try again'
        }
      );
      
      console.log("Response from backend:", response.data);
      
      // Store email in localStorage for future reference
      localStorage.setItem('userEmail', email);
      
      // Show success message
      setEmailSubmitted(true);
      
      // Don't set hasAccessToContent to true - we want users to only get content via email
      // setHasAccessToContent(true);
      
      // Don't move to preview step - keep showing the success message
      // setTimeout(() => {
      //   setStep('preview');
      // }, 2000);

    } catch (err) {
      let errorMessage;
      
      if (err.response?.status === 400 && err.response?.data?.detail?.includes('Free trial already used')) {
        errorMessage = 'This email has already been used for a free trial. Please use a different email or upgrade to Pro for unlimited access.';
      } else if (err.response?.status === 500 && (
        err.response?.data?.detail?.includes('duplicate key') || 
        err.response?.data?.detail?.includes('already exists')
      )) {
        errorMessage = 'This email has already been used for a free trial. Please use a different email or upgrade to Pro for unlimited access.';
      } else {
        errorMessage = err.response?.data?.detail || err.message || 'An error occurred. Please try again.';
      }
      
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const downloadResume = () => {
    if (packageData?.resume?.file) {
      const blob = new Blob(
        [Buffer.from(packageData.resume.file, 'base64')], 
        { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }
      );
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'enhanced_resume.docx';
      a.click();
    }
  };

  const downloadCoverLetter = () => {
    if (packageData?.cover_letter?.file) {
      const blob = new Blob(
        [Buffer.from(packageData.cover_letter.file, 'base64')], 
        { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }
      );
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'cover_letter.docx';
      a.click();
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'resume':
        return (
          <ContentSection>
            <h3>Enhanced Resume</h3>
            <PreviewText>{packageData?.resume?.text}</PreviewText>
            <ButtonGroup>
              <DownloadButton onClick={downloadResume}>
                Download Resume (DOCX)
              </DownloadButton>
              <CopyButton onClick={() => navigator.clipboard.writeText(packageData?.resume?.text)}>
                Copy Resume
              </CopyButton>
            </ButtonGroup>
          </ContentSection>
        );
      case 'coverLetter':
        return (
          <ContentSection>
            <h3>Cover Letter</h3>
            <PreviewText>{packageData?.cover_letter?.text}</PreviewText>
            <ButtonGroup>
              <DownloadButton onClick={downloadCoverLetter}>
                Download Cover Letter (DOCX)
              </DownloadButton>
              <CopyButton onClick={() => navigator.clipboard.writeText(packageData?.cover_letter?.text)}>
                Copy Cover Letter
              </CopyButton>
            </ButtonGroup>
          </ContentSection>
        );
      case 'email':
        return (
          <ContentSection>
            <h3>Outreach Email</h3>
            {packageData?.outreach_email ? (
              <>
                {packageData?.hiring_manager_name && (
                  <EmailInfo>
                    <strong>Hiring Manager:</strong> {packageData.hiring_manager_name}
                    {packageData.hiring_manager_title && (
                      <div><strong>Title:</strong> {packageData.hiring_manager_title}</div>
                    )}
                    {packageData?.hiring_manager_email && (
                      <div><strong>Email:</strong> {packageData.hiring_manager_email}</div>
                    )}
                  </EmailInfo>
                )}
                <PreviewText>{packageData?.outreach_email?.text}</PreviewText>
                <CopyButton onClick={() => navigator.clipboard.writeText(packageData?.outreach_email?.text)}>
                  Copy Email Text
                </CopyButton>
              </>
            ) : hiringManagerInfo ? (
              <NoEmailMessage>
                We were unable to generate an outreach email. Please try again or create your own email using the hiring manager information:
                <EmailInfo style={{ marginTop: '1rem' }}>
                  <strong>Hiring Manager:</strong> {hiringManagerInfo.name}
                  {hiringManagerInfo.title && (
                    <div><strong>Title:</strong> {hiringManagerInfo.title}</div>
                  )}
                  {hiringManagerInfo.email && (
                    <div><strong>Email:</strong> {hiringManagerInfo.email}</div>
                  )}
                </EmailInfo>
              </NoEmailMessage>
            ) : (
              <NoEmailMessage>
                Hiring manager information not available for this position. To generate an outreach email, please select a job from the hiring manager search page.
              </NoEmailMessage>
            )}
          </ContentSection>
        );
      default:
        return null;
    }
  };

  return (
    <Container>
      <Title>Complete Job Application Package</Title>
      
      {step === 'upload' && (
        <UploadSection>
          <form onSubmit={handleSubmit}>
            <FileUploadContainer>
              <FileInput
                type="file"
                onChange={handleFileChange}
                accept=".docx"
                id="resume-upload"
              />
              <FileLabel htmlFor="resume-upload">
                {resumeName || 'Choose DOCX file'}
              </FileLabel>
            </FileUploadContainer>

            <InputGroup>
              <Label>LinkedIn Job URL or Job ID</Label>
              <Input
                type="text"
                value={linkedinUrl}
                onChange={(e) => setLinkedinUrl(e.target.value)}
                placeholder="Paste LinkedIn job URL or enter Job ID"
              />
            </InputGroup>

            <SubmitButton type="submit" disabled={loading}>
              Generate Complete Package
            </SubmitButton>
          </form>
        </UploadSection>
      )}

      {step === 'processing' && (
        <ProcessingSection>
          <ProcessingTitle>Optimizing Your Application Package</ProcessingTitle>
          {processingSteps.map((step, index) => (
            <ProcessingStep key={index} active={index === processingStep}>
              {index < processingStep ? <FaCheckCircle color="#4CAF50" /> : <FaSpinner className={index === processingStep ? 'spinning' : ''} />}
              <span>{step}</span>
            </ProcessingStep>
          ))}
        </ProcessingSection>
      )}

      {step === 'emailCapture' && (
        <EmailCaptureSection>
          <BlurredPreview>
            <BlurredTitle>Your Complete Application Package is Ready!</BlurredTitle>
            <BlurredContent>
              {packageData?.resume?.preview && (
                <BlurredItem>
                  <h4>Enhanced Resume</h4>
                  <p>{packageData.resume.preview.substring(0, 150)}...</p>
                </BlurredItem>
              )}
              {packageData?.cover_letter?.preview && (
                <BlurredItem>
                  <h4>Cover Letter</h4>
                  <p>{packageData.cover_letter.preview.substring(0, 150)}...</p>
                </BlurredItem>
              )}
              {packageData?.outreach_email?.preview && (
                <BlurredItem>
                  <h4>Outreach Email</h4>
                  <p>{packageData.outreach_email.preview.substring(0, 150)}...</p>
                </BlurredItem>
              )}
            </BlurredContent>
            <LockIcon>🔒</LockIcon>
          </BlurredPreview>
          
          {emailSubmitted ? (
            <SuccessMessage>
              <FaCheckCircle size={40} color="#4CAF50" />
              <h3>Thank you!</h3>
              <p>Your complete job application package has been sent to <strong>{email}</strong>.</p>
              <p>Please check your inbox (and spam folder) for an email from <strong>CV Analyst</strong> with your complete job application package, including:</p>
              <ul style={{ textAlign: 'left', margin: '15px auto', maxWidth: '400px' }}>
                <li>✓ Your enhanced resume</li>
                {packageData?.cover_letter && <li>✓ Your tailored cover letter</li>}
                {packageData?.outreach_email && <li>✓ Your personalized outreach email</li>}
              </ul>
              <p><strong>Note:</strong> Your package is only available via email and will not be displayed on this page.</p>
            </SuccessMessage>
          ) : showEmailForm && (
            <EmailForm onSubmit={handleEmailSubmit}>
              <EmailTitle>Enter your email to access your complete package</EmailTitle>
              <EmailSubtitle>
                Your application package includes:
                <BenefitsList>
                  <BenefitItem>✓ ATS-optimized resume tailored to the job</BenefitItem>
                  <BenefitItem>✓ Customized cover letter highlighting your qualifications</BenefitItem>
                  <BenefitItem>✓ Professional outreach email to the hiring manager</BenefitItem>
                </BenefitsList>
                <EmailNote>We'll also send your complete package to your email for easy access!</EmailNote>
              </EmailSubtitle>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
              />
              <SubmitButton type="submit" disabled={loading}>
                {loading ? <FaSpinner className="spinning" /> : 'Access My Package'}
              </SubmitButton>
              <SmallText>One free attempt per user. No spam, just your application materials.</SmallText>
            </EmailForm>
          )}
          
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </EmailCaptureSection>
      )}

      {step === 'preview' && packageData && hasAccessToContent ? (
        <PreviewSection>
          <TabsContainer>
            <Tab
              active={activeTab === 'resume'}
              onClick={() => setActiveTab('resume')}
            >
              Resume
            </Tab>
            <Tab
              active={activeTab === 'coverLetter'}
              onClick={() => setActiveTab('coverLetter')}
            >
              Cover Letter
            </Tab>
            {(packageData?.outreach_email || hiringManagerInfo) && (
              <Tab
                active={activeTab === 'email'}
                onClick={() => setActiveTab('email')}
              >
                Outreach Email
              </Tab>
            )}
          </TabsContainer>

          {renderContent()}
        </PreviewSection>
      ) : step === 'preview' && !hasAccessToContent ? (
        // If user tries to access preview without submitting email, redirect to email capture
        <Navigate to="/free-hiring-resume" replace />
      ) : null}

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};

// Styled Components
const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .spinning {
    animation: spin 1s linear infinite;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
  color: #1a1a1a;
`;

const UploadSection = styled.div``;

const FileUploadContainer = styled.div`
  position: relative;
  margin-bottom: 1rem;
`;

const FileInput = styled.input`
  display: none;
`;

const FileLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;
  border: 2px dashed #ddd;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
  background-color: ${props => props.isDragOver ? '#f0f0f0' : 'transparent'};

  &:hover {
    border-color: #4A25E1;
    background-color: #f8f8f8;
  }

  ${props => props.hasError && `
    border-color: #ff4444;
    color: #ff4444;
  `}
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Label = styled.label`
  font-size: 1rem;
  color: #666;
`;

const Input = styled.input`
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
`;

const SubmitButton = styled.button`
  padding: 1rem;
  background-color: #4A25E1;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: #3615D0;
  }
`;

const ProcessingSection = styled.div`
  text-align: center;
  padding: 2rem;
  max-width: 600px;
  margin: 0 auto;
`;

const ProcessingTitle = styled.h2`
  margin-bottom: 2rem;
  color: #333;
`;

const ProcessingStep = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem 0;
  padding: 1rem;
  background: ${props => props.active ? '#f0f7ff' : 'transparent'};
  border-radius: 8px;
  transition: all 0.3s;
  
  svg {
    font-size: 1.5rem;
    color: ${props => props.active ? '#4A25E1' : '#aaa'};
  }
  
  span {
    font-size: 1.1rem;
    color: ${props => props.active ? '#333' : '#888'};
  }
`;

const PreviewSection = styled.div`
  padding: 2rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const TabsContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #eaeaea;
`;

const Tab = styled.button`
  padding: 1rem 2rem;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1rem;
  color: ${props => props.active ? '#000' : '#666'};
  border-bottom: 2px solid ${props => props.active ? '#000' : 'transparent'};
  transition: all 0.2s;

  &:hover {
    color: #000;
  }
`;

const ContentSection = styled.div`
  padding: 2rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const PreviewText = styled.pre`
  white-space: pre-wrap;
  font-family: inherit;
  margin: 1rem 0;
  padding: 1rem;
  background: #f5f5f5;
  border-radius: 4px;
  font-size: 0.9rem;
  line-height: 1.5;
`;

const DownloadButton = styled.button`
  padding: 1rem;
  background-color: #4A25E1;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: #3615D0;
  }
`;

const EmailInfo = styled.div`
  margin-bottom: 1rem;
  padding: 1rem;
  background: #f0f7ff;
  border-radius: 4px;
`;

const NoEmailMessage = styled.div`
  padding: 2rem;
  text-align: center;
  color: #666;
  background: #f5f5f5;
  border-radius: 4px;
`;

const CopyButton = styled(DownloadButton)`
  background: #4a5568;
  
  &:hover {
    background: #2d3748;
  }
`;

const ErrorMessage = styled.p`
  color: #ff4444;
  margin-top: 1rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
`;

// Email capture styled components
const EmailCaptureSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const BlurredPreview = styled.div`
  width: 100%;
  padding: 2rem;
  background: #f5f5f5;
  border-radius: 8px;
  margin-bottom: 2rem;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(5px);
    border-radius: 8px;
  }
`;

const BlurredTitle = styled.h2`
  text-align: center;
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 1;
`;

const BlurredContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const BlurredItem = styled.div`
  h4 {
    margin-bottom: 0.5rem;
  }
  
  p {
    color: #666;
    font-size: 0.9rem;
    line-height: 1.4;
  }
`;

const EmailForm = styled.form`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 2;
`;

const EmailTitle = styled.h3`
  text-align: center;
  margin-bottom: 1rem;
`;

const SmallText = styled.p`
  font-size: 0.8rem;
  color: #666;
  text-align: center;
`;

const LockIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 5rem;
  z-index: 2;
  opacity: 0.8;
`;

const EmailSubtitle = styled.div`
  text-align: center;
  margin-bottom: 1.5rem;
  color: #555;
`;

const BenefitsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 1rem 0;
  text-align: left;
`;

const BenefitItem = styled.li`
  margin: 0.5rem 0;
  font-size: 1rem;
  color: #333;
`;

const EmailNote = styled.p`
  margin-top: 1rem;
  font-style: italic;
  color: #4A25E1;
  font-size: 0.9rem;
  text-align: center;
`;

const SuccessMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem;
  background: #f0f7ff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
  
  h3 {
    margin: 1rem 0;
    color: #333;
  }
  
  p {
    color: #666;
    max-width: 400px;
  }
`;

export default FreeHiringResume;
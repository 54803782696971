import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from './UserContext'; // Import useUser

function VerifyRegister() {
    const { token } = useParams();
    const navigate = useNavigate();
    const { setUser } = useUser(); // Destructure setUser from context

    useEffect(() => {
        // Send a request to the /verify-email/{token} endpoint
        fetch(`https://cvanalystiobackend.onrender.com/verify-email/${token}`)
            .then(response => response.json())
            .then(data => {
                if (data.message === 'Email verification successful') {
                    // If the request was successful, update the user state and navigate to the home page
                    setUser({ email: data.email }); // Replace 'email' with the actual property name in the response
                    localStorage.setItem('authToken', data.access_token); // Add this line
                    navigate('/profile');
                    window.location.reload();
                } else {
                    // If the request failed, show an error message
                    console.error('Email verification failed');
                }
            });
    }, [token, navigate, setUser]); // Add setUser to the dependency array

    return (
        <div>
            Verifying email.......
        </div>
    );
}

export default VerifyRegister;
import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://cvanalystiobackend.onrender.com';
console.log('Backend API URL:', BACKEND_API_URL);

const CoverLetterGenerator = ({ apiKeyApp }) => {
  const [uploadedResume, setUploadedResume] = useState(null);
  const [isCoverLetterGenerated, setIsCoverLetterGenerated] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [jobDescription, setJobDescription] = useState('');
  const [outputCode, setOutputCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [inputKey, setInputKey] = useState(Date.now());

  const handleResumeUpload = (event) => {
    const file = event.target.files?.[0];
    if (file && file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setUploadedResume(file);
    } else {
      setError('Please upload a DOCX file');
    }
  };

  const handleGenerateCoverLetter = async () => {
    if (!uploadedResume || !jobDescription.trim()) return;

    setLoading(true);
    setError(null);

    const accessToken = localStorage.getItem('authToken');
    if (!accessToken) {
      setError('Please login to use this feature');
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('resume', uploadedResume);
    formData.append('job_description', jobDescription.trim());

    try {
      const response = await axios.post(
        `${BACKEND_API_URL}/generate_cover_letter`,
        formData,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${accessToken}`,
          },
          responseType: 'blob',
        }
      );

      if (response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        setDownloadUrl(url);
        setIsCoverLetterGenerated(true);
      } else {
        console.error('No data received from the backend');
      }
    } catch (error) {
      console.error('Error generating cover letter:', error);
      if (error.response?.status === 401) {
        setError('Please login to use this feature');
      } else if (error.response?.status === 403) {
        setError('Your subscription is not active. Please check your subscription status.');
      } else if (error.response?.status === 500) {
        setError('Please login to use this feature');
      } else {
        setError('Failed to generate cover letter. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveResume = () => {
    setUploadedResume(null);
    setInputKey(Date.now());
  };

 


  return (
    <Container>
      <ContentWrapper>
        <Title>Cover Letter Generator</Title>
        
        <Card>
          <Section>
            <SectionTitle>Upload Resume</SectionTitle>
            <FileUploadContainer>
              <FileInput
                key={inputKey}
                type="file"
                accept=".docx"
                onChange={handleResumeUpload}
                id="resume-upload"
              />
              <FileLabel htmlFor="resume-upload">
                {uploadedResume ? uploadedResume.name : 'Choose .docx file'}
              </FileLabel>
              {uploadedResume && (
                <TextButton onClick={handleRemoveResume}>Remove</TextButton>
              )}
            </FileUploadContainer>
          </Section>

          <Section>
            <SectionTitle>Job Details</SectionTitle>
            <InputGroup>
              <Label>Job Description & Company Information</Label>
              <StyledTextarea
                placeholder="Paste the job description and any relevant company information here..."
                onChange={(e) => setJobDescription(e.target.value)}
                required
              />
            </InputGroup>
          </Section>

          <ActionButton onClick={handleGenerateCoverLetter} disabled={loading}>
            {loading ? 'Generating...' : 'Generate Cover Letter'}
          </ActionButton>

          {error && <ErrorText>{error}</ErrorText>}
        </Card>

        {isCoverLetterGenerated && (
          <Card>
            <SectionTitle>Generated Cover Letter</SectionTitle>
            <ActionButton 
              onClick={() => {
                if (downloadUrl) {
                  const link = document.createElement('a');
                  link.href = downloadUrl;
                  link.download = 'cover_letter.docx';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  setIsCoverLetterGenerated(false);
                }
              }}
            >
              Download Cover Letter
            </ActionButton>
            
            <PreviewSection>
              <Label>Preview</Label>
              <InfoText>
                This is a plain text preview. The downloaded file will include professional formatting.
              </InfoText>
              {outputCode ? (
                <PreviewContent>{outputCode}</PreviewContent>
              ) : (
                <WaitingText>Waiting for cover letter generation...</WaitingText>
              )}
            </PreviewSection>
          </Card>
        )}
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div`
  min-height: calc(100vh - 64px);
  background-color: #F5F5F5;
  padding: 2rem;
`;

const ContentWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 2rem;
  text-align: center;
`;

const Card = styled.div`
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  border-radius: 8px;
  padding: 2rem;
  margin-bottom: 2rem;
`;

const Section = styled.div`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 1rem;
`;

const InputGroup = styled.div`
  margin-bottom: 1rem;
`;

const Label = styled.label`
  display: block;
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.5rem;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  font-size: 0.9rem;
  min-height: 200px;
  resize: vertical;
  transition: border-color 0.2s;

  &:focus {
    outline: none;
    border-color: #000;
  }
`;

const FileUploadContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const FileInput = styled.input`
  display: none;
`;

const FileLabel = styled.label`
  padding: 0.75rem 1rem;
  background-color: #F5F5F5;
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.2s;

  &:hover {
    background-color: #EAEAEA;
  }
`;

const TextButton = styled.button`
  background: none;
  border: none;
  color: #666;
  font-size: 0.9rem;
  cursor: pointer;
  padding: 0;

  &:hover {
    color: #000;
  }
`;

const ActionButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  background-color: #000;
  color: #FFF;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #333;
  }

  &:disabled {
    background-color: #CCC;
    cursor: not-allowed;
  }
`;

const ErrorText = styled.p`
  color: #DC2626;
  font-size: 0.9rem;
  margin-top: 1rem;
`;

const PreviewSection = styled.div`
  margin-top: 1.5rem;
`;

const InfoText = styled.p`
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 1rem;
  font-style: italic;
`;

const PreviewContent = styled.div`
  padding: 1rem;
  background-color: #F5F5F5;
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  font-size: 0.9rem;
  white-space: pre-wrap;
`;

const WaitingText = styled.p`
  text-align: center;
  color: #666;
  font-size: 0.9rem;
  padding: 2rem;
`;

export default CoverLetterGenerator;
import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
// Import images
import example1 from '../images/example_character1.jpg';
import example2 from '../images/example_character2.jpg';
import example3 from '../images/example_character3.jpg';
import volvoLogo from '../images/volvo-logo.png';
import googleLogo from '../images/google-logo.png';
import amazonLogo from '../images/amazon-logo.png';
import beforeResume1 from '../images/before_resume1.png';
import beforeResume2 from '../images/before_resume2.png';
import afterResume1 from '../images/after_resume1.png';
import afterResume2 from '../images/after_resume2.png';
import afterResume3 from '../images/after_resume3.png';
import testimonial1 from '../images/home_testimonial1.png';
import testimonial2 from '../images/home_testimonial2.png';
import testimonial3 from '../images/home_testimonial3.png';
import testimonial4 from '../images/home_testimonial4.png';

const COLORS = {
  primary: {
    main: '#000000',
    light: '#1A1A1A',
    dark: '#000000'
  },
  text: {
    primary: '#000000',
    secondary: '#4A4A4A',
    light: '#717171',
    white: '#FFFFFF'
  },
  background: {
    primary: '#FFFFFF',
    secondary: '#F8F8F8',
    accent: '#F3F4F6'
  },
  border: '#E5E5E5'
};

const ActionGroup = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

const CTAButton = styled.button`
  background: ${props => props.primary ? COLORS.text.primary : 'transparent'};
  color: ${props => props.primary ? COLORS.text.white : COLORS.text.primary};
  padding: 1.2rem 3rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 4px;
  border: 2px solid ${COLORS.text.primary};
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  display: inline-block;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 768px) {
    width: calc(100% - 2rem);
    padding: 1rem 2rem;
    font-size: 1rem;
    margin: 0 1rem;
  }
`;

const Home = () => {
  const navigate = useNavigate();

  const handleFreeResume = () => {
    navigate('/free-resume-optimization');
  };

  const handleGetStarted = () => {
    navigate('/features');
  };

  return (
    <MainContainer>
      <div style={{ 
        textAlign: 'center', 
        padding: '80px 20px',
        maxWidth: '1200px',
        margin: '0 auto',
        background: '#fff'
      }}>
        <h1 style={{
          fontSize: '48px',
          fontWeight: '700',
          marginBottom: '24px',
          color: '#000'
        }}>CVAnalyst.io</h1>

        <h2 style={{
          fontSize: '36px',
          fontWeight: '600',
          marginBottom: '32px',
          color: '#1a1a1a',
          lineHeight: '1.3'
        }}>Your success story begins with the perfect application.</h2>

        <p style={{ 
          maxWidth: '800px', 
          margin: '0 auto 40px',
          fontSize: '20px',
          lineHeight: '1.6',
          color: '#4a4a4a'
        }}>
          Tailor your resume and cover letter in seconds with the power of AI. Upload your LinkedIn job posting, 
          and let CVAnalyst create job-specific, optimized application materials that get you noticed. 
          Say goodbye to guesswork and hello to more interviews.
        </p>

        <div style={{ 
          display: 'flex', 
          gap: '24px', 
          justifyContent: 'center', 
          margin: '48px 0' 
        }}>
          <Link to="/free-resume-optimization">
            <button style={{
              padding: '16px 32px',
              fontSize: '18px',
              fontWeight: '600',
              color: '#000',
              background: '#fff',
              border: '2px solid #000',
              borderRadius: '4px',
              cursor: 'pointer',
              transition: 'all 0.2s ease',
              textDecoration: 'none'
            }}>Try Free Resume Optimization</button>
          </Link>
          <Link to="/features">
            <button style={{
              padding: '16px 32px',
              fontSize: '18px',
              fontWeight: '600',
              color: '#fff',
              background: '#000',
              border: '2px solid #000',
              borderRadius: '4px',
              cursor: 'pointer',
              transition: 'all 0.2s ease',
              textDecoration: 'none'
            }}>Get Started Now</button>
          </Link>
        </div>

        <p style={{
          fontSize: '20px',
          color: '#4a4a4a',
          marginBottom: '24px',
          fontWeight: '500'
        }}>Trusted by job seekers and professionals worldwide.</p>

        <div style={{ 
          margin: '32px 0',
          display: 'flex',
          gap: '48px',
          justifyContent: 'center',
          fontSize: '18px',
          color: '#4a4a4a'
        }}>
          <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <span style={{ color: '#000', fontWeight: '600' }}>✓</span> 
            Instantly tailored
          </span>
          <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <span style={{ color: '#000', fontWeight: '600' }}>✓</span> 
            Effortlessly professional
          </span>
        </div>
      </div>

      <TransformationSection>
        <SectionTitle>See the CVAnalyst Difference</SectionTitle>
        <TransformationContainer>
          <BeforeAfterColumn>
            <ColumnTitle>Before CVAnalyst</ColumnTitle>
            <ResumeImage src={beforeResume1} alt="Resume before CVAnalyst - Part 1" />
            <ResumeImage src={beforeResume2} alt="Resume before CVAnalyst - Part 2" />
            <BeforeDescription>
              Basic formatting, unorganized content, and lacking professional polish
            </BeforeDescription>
          </BeforeAfterColumn>
          
          <BeforeAfterColumn>
            <ColumnTitle>After CVAnalyst</ColumnTitle>
            <ResumeImage src={afterResume1} alt="Resume after CVAnalyst - Part 1" />
            <ResumeImage src={afterResume2} alt="Resume after CVAnalyst - Part 2" />
            <ResumeImage src={afterResume3} alt="Resume after CVAnalyst - Part 3" />
            <AfterDescription>
              Professional layout, optimized content, and ATS-friendly formatting
            </AfterDescription>
          </BeforeAfterColumn>
        </TransformationContainer>
      </TransformationSection>

      


      <FeaturesSection>
        <SectionTitle>The Smarter Way to Apply</SectionTitle>
        <SectionSubtitle>Your dream job, just a click away.</SectionSubtitle>
        <FeaturesGrid>
          <FeatureCard>
            <FeatureTitle>AI-Powered Resume Tailoring</FeatureTitle>
            <FeatureDescription>
              Paste any LinkedIn job URL, and CVAnalyst instantly rewrites and optimizes your resume for the role.
            </FeatureDescription>
          </FeatureCard>

          <FeatureCard>
            <FeatureTitle>AI Cover Letter Generator</FeatureTitle>
            <FeatureDescription>
              Automatically generate a professional, tailored cover letter designed to stand out.
            </FeatureDescription>
          </FeatureCard>

          <FeatureCard>
            <FeatureTitle>Real-Time Scoring</FeatureTitle>
            <FeatureDescription>
              Receive a customized relevance score, showing how well your application matches the job description.
            </FeatureDescription>
          </FeatureCard>

          <FeatureCard>
            <FeatureTitle>No Manual Work</FeatureTitle>
            <FeatureDescription>
              Save time and focus on opportunities that matter.
            </FeatureDescription>
          </FeatureCard>
        </FeaturesGrid>
      </FeaturesSection>

      <HowItWorksSection>
        <SectionTitle>How It Works</SectionTitle>
        <StepsContainer>
          {[
            {
              title: "Paste a Job Link",
              description: "Copy any LinkedIn job URL and paste it into CVAnalyst."
            },
            {
              title: "Upload Your Resume",
              description: "Upload your existing resume."
            },
            {
              title: "Generate Tailored Results",
              description: "Watch as CVAnalyst instantly rewrites and tailors your resume and cover letter to match the job description perfectly."
            },
            {
              title: "Download and Apply",
              description: "Download your new documents, optimized for ATS and hiring managers."
            }
          ].map((step, index) => (
            <StepCard key={index}>
              <StepNumber>{index + 1}</StepNumber>
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </StepCard>
          ))}
        </StepsContainer>
      </HowItWorksSection>

      
    

      <SuccessSection>
        <SuccessTitle>Learn from successful resume examples.</SuccessTitle>
        <SuccessGrid>
          <SuccessCard>
            <CardImage src={example1} alt="Ivan Llopis Beltran" />
            <CardContent>
              <PersonName>Ivan Llopis Beltran</PersonName>
              <JobRole>Mechanical Engineering Intern at Volvo</JobRole>
    
              <HiredBy>
                Hired by <CompanyLogo src={volvoLogo} alt="Volvo" />
              </HiredBy>
            </CardContent>
          </SuccessCard>

          <SuccessCard>
            <CardImage src={example2} alt="Michelle D'Aquino" />
            <CardContent>
              <PersonName>Michelle D'Aquino</PersonName>
              <JobRole>Google Account Executive</JobRole>
             
              <HiredBy>
                Hired by <CompanyLogo src={googleLogo} alt="Google" />
              </HiredBy>
            </CardContent>
          </SuccessCard>

          <SuccessCard>
            <CardImage src={example3} alt="Carolina Herrera" />
            <CardContent>
              <PersonName>Carolina Herrera</PersonName>
              <JobRole>Amazon Systems Administrator</JobRole>
             
              <HiredBy>
                Hired by <CompanyLogo src={amazonLogo} alt="Amazon" />
              </HiredBy>
            </CardContent>
          </SuccessCard>

          <NavigationArrow>→</NavigationArrow>
        </SuccessGrid>
      </SuccessSection>

   

      <TestimonialsSection>
        <SectionTitle>What Users Have to Say</SectionTitle>
        <TestimonialsGrid>
          <TestimonialCard>
            <TestimonialImage src={testimonial1} alt="User Testimonial" />
          </TestimonialCard>

          <TestimonialCard>
            <TestimonialImage src={testimonial2} alt="User Testimonial" />
          </TestimonialCard>

          <TestimonialCard>
            <TestimonialImage src={testimonial3} alt="User Testimonial" />
          </TestimonialCard>

          <TestimonialCard>
            <TestimonialImage src={testimonial4} alt="User Testimonial" />
          </TestimonialCard>
        </TestimonialsGrid>
      </TestimonialsSection>


      <CTASection>
        <SectionTitle>Success Starts Here</SectionTitle>
        <CTADescription>
          Join thousands of job seekers who've used CVAnalyst to land their dream jobs.
        </CTADescription>
        <CTAButton 
          as="a"
          href="https://analyticpinnacle.thrivecart.com/cv-analyst/"
          target="_blank"
          rel="noopener noreferrer"
          primary
        >
          Get Started Now
        </CTAButton>
      </CTASection>
    </MainContainer>

  );
};

// Styled Components
const MainContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
  background-color: #fff;
`;

const HeroSection = styled.section`
  position: relative;
  padding: 4rem 2rem;
  background: ${COLORS.background.primary};
  overflow: hidden;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 2rem 0.5rem;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: radial-gradient(circle at 1px 1px, #E5E5E5 1px, transparent 0);
    background-size: 40px 40px;
    opacity: 0.1;
  }
`;

const HeroTitle = styled.h1`
  font-size: clamp(2.5rem, 5vw, 3.5rem);
  font-weight: 800;
  color: ${COLORS.text.primary};
  letter-spacing: -0.02em;
  line-height: 1.1;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 2.5rem;
    margin-bottom: 0.75rem;
  }
`;

const HeroSubtitle = styled.h2`
  font-size: clamp(1.25rem, 2vw, 1.5rem);
  color: ${COLORS.text.light};
  margin-bottom: 1.5rem;
  font-weight: 400;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

const HeroDescription = styled.p`
  color: ${COLORS.text.secondary};
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1.25rem;
    padding: 0 1rem;
  }
`;

const TrustStatement = styled.p`
  font-size: 1.1rem;
  color: #718096;
`;

const SectionTitle = styled.h2`
  font-size: clamp(2rem, 4vw, 2.5rem);
  font-weight: 800;
  color: ${COLORS.text.primary};
  text-align: center;
  margin-bottom: 3rem;

  @media (max-width: 768px) {
    margin-bottom: 2rem;
    font-size: 1.75rem;
  }
`;

const SectionSubtitle = styled.p`
  font-size: 1.25rem;
  color: #4a5568;
  text-align: center;
  margin-bottom: 3rem;
`;

const FeaturesSection = styled.section`
  margin-bottom: 6rem;
  padding: 4rem 2rem;

  @media (max-width: 768px) {
    padding: 3rem 1rem;
  }
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

const FeatureCard = styled.div`
  background: ${COLORS.background.primary};
  padding: 2.5rem;
  border: 1px solid ${COLORS.border};
  border-radius: 4px;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 20px 40px rgba(0,0,0,0.05);
  }
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  color: ${COLORS.text.primary};
  margin-bottom: 1rem;
  font-weight: 500;
`;

const FeatureDescription = styled.p`
  color: ${COLORS.text.secondary};
  line-height: 1.5;
`;

const HowItWorksSection = styled.section`
  margin-bottom: 6rem;
`;

const StepsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  padding: 2rem 0;
`;

const StepCard = styled.div`
  text-align: center;
  padding: 2rem;
`;

const StepNumber = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #4299e1;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
  font-weight: bold;
`;

const StepTitle = styled.h3`
  font-size: 1.25rem;
  color: #2d3748;
  margin-bottom: 1rem;
`;

const StepDescription = styled.p`
  color: #4a5568;
  line-height: 1.6;
`;

const PricingSection = styled.section`
  padding: 6rem 2rem;
  background: ${COLORS.background.secondary};

  @media (max-width: 768px) {
    padding: 3rem 1rem;
  }
`;

const PricingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;

  @media (max-width: 768px) {
    gap: 1.5rem;
  }
`;

const PricingCard = styled.div`
  background: ${COLORS.background.primary};
  padding: 2.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  ${props => props.highlighted && `
    border: 2px solid ${COLORS.text.primary};
    transform: scale(1.05);
    box-shadow: 0 20px 40px rgba(0,0,0,0.1);
    
    @media (max-width: 768px) {
      transform: scale(1);
    }
  `}
`;

const PlanName = styled.h3`
  font-size: 1.5rem;
  color: #2d3748;
  margin-bottom: 1rem;
`;

const PlanPrice = styled.div`
  font-size: 2rem;
  color: #4299e1;
  font-weight: bold;
  margin-bottom: 2rem;
`;

const PlanFeatures = styled.ul`
  list-style: none;
  padding: 0;
  margin: 2rem 0;
  flex-grow: 1;
  
  li {
    margin-bottom: 1rem;
    color: ${COLORS.text.secondary};
    display: flex;
    align-items: center;
    gap: 0.5rem;
    
    &:before {
      content: "✓";
      color: ${COLORS.text.primary};
    }
  }
`;

const CTASection = styled.section`
  text-align: center;
  padding: 4rem 0;
  background: #f7fafc;
  border-radius: 10px;
`;

const CTADescription = styled.p`
  font-size: 1.25rem;
  color: #4a5568;
  margin-bottom: 2rem;
`;

const SuccessSection = styled.section`
  padding: 6rem 2rem;
  background: ${COLORS.background.primary};

  @media (max-width: 768px) {
    padding: 3rem 1rem;
  }
`;

const SuccessTitle = styled.h2`
  font-size: 3.5rem;
  font-weight: 600;
  color: #2D3748;
  margin-bottom: 1rem;
  font-family: serif;
`;

const SuccessSubtitle = styled.p`
  font-size: 1.25rem;
  color: #718096;
  margin-bottom: 3rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const SuccessGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;

  @media (max-width: 768px) {
    gap: 1.5rem;
  }
`;

const SuccessCard = styled.div`
  background: ${COLORS.background.primary};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 20px 40px rgba(0,0,0,0.1);
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 250px;
  object-fit: cover;

  @media (max-width: 768px) {
    height: 200px;
  }
`;

const CardContent = styled.div`
  padding: 1.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PersonName = styled.h3`
  font-size: 1.25rem;
  color: #2D3748;
  margin-bottom: 0.5rem;
  font-weight: 500;
`;

const JobRole = styled.h4`
  font-size: 1.1rem;
  color: #4A5568;
  margin-bottom: 1rem;
  font-weight: 600;
`;

const ViewResume = styled.a`
  color: #FF6B6B;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
`;

const Arrow = styled.span`
  font-size: 1.2rem;
`;

const HiredBy = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #718096;
  font-size: 0.9rem;
`;

const CompanyLogo = styled.img`
  height: 20px;
  object-fit: contain;
`;

const NavigationArrow = styled.div`
  position: absolute;
  right: -2rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  color: ${COLORS.text.primary};
  cursor: pointer;

  @media (max-width: 768px) {
    display: none;
  }
`;

const TransformationSection = styled.section`
  margin: 8rem 0;
  padding: 0 2rem;

  @media (max-width: 768px) {
    margin: 4rem 0;
    padding: 0 1rem;
  }
`;

const TransformationContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  max-width: 1200px;
  margin: 0 auto;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 3rem;
  }
`;

const BeforeAfterColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;

const ColumnTitle = styled.h3`
  font-size: 1.8rem;
  color: #2D3748;
  margin-bottom: 1rem;
  font-weight: 600;
  text-align: center;
`;

const ResumeImage = styled.img`
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.02);
  }
`;

const BeforeDescription = styled.p`
  color: #718096;
  font-size: 1.1rem;
  text-align: center;
  font-style: italic;
  margin-top: 1rem;
`;

const AfterDescription = styled(BeforeDescription)`
  color: #48BB78;
`;

const TestimonialsSection = styled.section`
  margin: 6rem 0;
  text-align: center;
  padding: 4rem 2rem;
  background: ${COLORS.background.secondary};

  @media (max-width: 768px) {
    padding: 2rem 0;
    margin: 3rem 0;
  }
`;

const TestimonialsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  max-width: 800px;
  margin: 0 auto;
  
  @media (max-width: 768px) {
    gap: 1.5rem;
    padding: 0;
  }
`;

const TestimonialCard = styled.div`
  background: ${COLORS.background.primary};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
  max-width: 100%;
  height: auto;

  @media (max-width: 768px) {
    border-radius: 0;
    box-shadow: none;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    
    @media (max-width: 768px) {
      transform: none;
      box-shadow: none;
    }
  }
`;

const TestimonialImage = styled.img`
  width: 100%;
  object-fit: contain;
  display: block;
  max-width: 800px;
  margin: 0 auto;

  @media (max-width: 768px) {
    max-width: 100%;
    margin: 0;
  }
`;

const AITailorSection = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 600px;
  margin: 4rem 0;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const TailorLeftPanel = styled.div`
  background: #F7FAFC;
  padding: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TailorForm = styled.form`
  width: 100%;
  max-width: 480px;
`;

const FormTitle = styled.h3`
  font-size: 1.25rem;
  color: #2D3748;
  margin-bottom: 1rem;
`;

const URLInput = styled.input`
  width: 100%;
  padding: 1rem;
  border: 2px solid #E2E8F0;
  border-radius: 8px;
  font-size: 1rem;
  margin-bottom: 2rem;
  
  &:focus {
    border-color: #4299E1;
    outline: none;
  }
`;

const FeaturesList = styled.div`
  margin-bottom: 2rem;
`;

const FeaturesTitle = styled.h4`
  font-size: 1.1rem;
  color: #2D3748;
  margin-bottom: 1rem;
`;

const FeatureItem = styled.div`
  color: #4A5568;
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const TailorButton = styled.button`
  width: 100%;
  padding: 1rem;
  background: #4299E1;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #3182CE;
    transform: translateY(-2px);
  }
`;

const TailorRightPanel = styled.div`
  padding: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
`;

const RightPanelContent = styled.div`
  max-width: 480px;
`;

const PanelLabel = styled.div`
  color: #4299E1;
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;

const PanelTitle = styled.h2`
  font-size: 2rem;
  color: #2D3748;
  margin-bottom: 1.5rem;
  line-height: 1.2;
`;

const PanelDescription = styled.p`
  color: #4A5568;
  line-height: 1.6;
  margin-bottom: 2rem;
`;

const LearnMoreLink = styled.a`
  color: #4299E1;
  text-decoration: none;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 2rem;

  &:hover {
    text-decoration: underline;
  }
`;

const CheckList = styled.div`
  margin-bottom: 2rem;
`;

const CheckItem = styled.div`
  color: #4A5568;
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const TailorCTAButton = styled(TailorButton)`
  background: #4299E1;
  padding: 1.25rem;
`;

const HighlightsList = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
  margin: 1.5rem 0;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
    margin: 1rem 0;
  }
`;

const HighlightItem = styled.div`
  color: #4A5568;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export default Home;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://cvanalystiobackend.onrender.com';


const SearchJobs = () => {
  const [keywords, setKeywords] = useState('');
  const navigate = useNavigate();
  const [locationId, setLocationId] = useState('');
  const [datePosted, setDatePosted] = useState('anyTime');
  const [sort, setSort] = useState('mostRelevant');
  const [onsiteRemote, setOnsiteRemote] = useState('');
  const [experienceLevel, setExperienceLevel] = useState('');
  const [jobType, setJobType] = useState('');
  const [salary, setSalary] = useState('');
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalJobs, setTotalJobs] = useState(0);
  const jobsPerPage = 25;

  const searchJobs = async (page = 1) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(`${BACKEND_API_URL}/search-jobs`, {
        params: {
          keywords,
          locationId,
          datePosted,
          sort,
          ...(onsiteRemote && { onsiteRemote }),
          ...(experienceLevel && { experienceLevel }),
          ...(jobType && { jobType }),
          ...(salary && { salary }),
          start: (page - 1) * jobsPerPage,
        },
      });

      console.log('API Response:', response.data);
      
      if (response.data.success) {
        setJobs(response.data.data);
        setTotalJobs(response.data.total);
        setCurrentPage(page);
      } else {
        setError(response.data.message || 'Failed to fetch jobs');
      }
    } catch (err) {
      console.error('Error fetching jobs:', err);
      setError('Failed to fetch jobs. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const totalPages = Math.ceil(totalJobs / jobsPerPage);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      searchJobs(page);
      // Scroll to top of results when page changes
      document.getElementById('resultsTop')?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const renderPagination = (isTop = false) => {
    const pages = [];
    const maxVisiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    pages.push(
      <PageButton
        key="first"
        onClick={() => handlePageChange(1)}
        disabled={currentPage === 1}
        isTop={isTop}
      >
        First
      </PageButton>
    );

    pages.push(
      <PageButton
        key="prev"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        isTop={isTop}
      >
        &lt;
      </PageButton>
    );

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <PageButton
          key={i}
          onClick={() => handlePageChange(i)}
          active={currentPage === i}
          isTop={isTop}
        >
          {i}
        </PageButton>
      );
    }

    pages.push(
      <PageButton
        key="next"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        isTop={isTop}
      >
        &gt;
      </PageButton>
    );

    pages.push(
      <PageButton
        key="last"
        onClick={() => handlePageChange(totalPages)}
        disabled={currentPage === totalPages}
        isTop={isTop}
      >
        Last
      </PageButton>
    );

    return (
      <PaginationContainer isTop={isTop}>
        {pages}
        <PageInfo isTop={isTop}>
          Page {currentPage} of {totalPages} ({totalJobs.toLocaleString()} total jobs)
        </PageInfo>
      </PaginationContainer>
    );
  };

  return (
    <Container>
      <ContentWrapper>
        <Title>Search Jobs</Title>
        
        <SearchCard>
          <SearchForm>
            <InputGroup>
              <StyledInput
                type="text"
                placeholder="Keywords (e.g. Software Engineer)"
                value={keywords}
                onChange={(e) => setKeywords(e.target.value)}
              />
              
            </InputGroup>
  
            <FilterGroup>
              <StyledSelect value={datePosted} onChange={(e) => setDatePosted(e.target.value)}>
                <option value="anyTime">Any Time</option>
                <option value="past24h">Past 24 hours</option>
                <option value="pastWeek">Past Week</option>
                <option value="pastMonth">Past Month</option>
              </StyledSelect>
  
              <StyledSelect value={sort} onChange={(e) => setSort(e.target.value)}>
                <option value="mostRelevant">Most Relevant</option>
                <option value="recentlyPosted">Recently Posted</option>
              </StyledSelect>
  
              <StyledSelect value={onsiteRemote} onChange={(e) => setOnsiteRemote(e.target.value)}>
                <option value="">Work Type (Any)</option>
                <option value="remote">Remote</option>
                <option value="onsite">On-site</option>
                <option value="hybrid">Hybrid</option>
              </StyledSelect>
            </FilterGroup>
  
            <ActionButton onClick={(e) => {
              e.preventDefault();
              searchJobs(1);
            }} disabled={loading}>
              {loading ? 'Searching...' : 'Search Jobs'}
            </ActionButton>
          </SearchForm>
        </SearchCard>
  
        {loading ? (
          <LoadingState>Searching for jobs...</LoadingState>
        ) : (
          <>
            {error && <ErrorText>{error}</ErrorText>}
            
            {totalJobs > 0 && (
              <ResultsHeader id="resultsTop">
                <ResultsCount>Found {totalJobs.toLocaleString()} matching jobs</ResultsCount>
                {renderPagination(true)}
                <Divider />
              </ResultsHeader>
            )}
  
            <JobsList>
              {Array.isArray(jobs) && jobs.length > 0 ? (
                jobs.map((job) => (
                  <JobCard key={job.id || `${job.title}-${job.company?.name}`}>
                    <JobHeader>
                      <JobTitleLink href={job.url} target="_blank" rel="noopener noreferrer">
                        {job.title}
                      </JobTitleLink>
                      <CompanyName>{job.company?.name || 'N/A'}</CompanyName>
                    </JobHeader>
  
                    <JobDetails>
                      {job.company?.logo && (
                        <CompanyLogo 
                          src={job.company.logo} 
                          alt={`${job.company.name} logo`}
                          onError={(e) => e.target.style.display = 'none'}
                        />
                      )}
                      <JobInfo>
                        <InfoItem><Label>Location:</Label> {job.location || 'N/A'}</InfoItem>
                        <InfoItem><Label>Type:</Label> {job.type || 'N/A'}</InfoItem>
                        <InfoItem><Label>Posted:</Label> {job.postDate || 'N/A'}</InfoItem>
                        {job.benefits && (
                          <InfoItem><Label>Benefits:</Label> {job.benefits}</InfoItem>
                        )}
                      </JobInfo>
                    </JobDetails>
  
                    <ActionGroup>
                      <ActionLink href={job.url} target="_blank" rel="noopener noreferrer">
                        View on LinkedIn
                      </ActionLink>
                      <ActionLink href={`/linkedin-job/${job.id}`} target="_blank" secondary>
                        View on CvAnalyst
                      </ActionLink>
                    </ActionGroup>
                  </JobCard>
                ))
              ) : (
                <EmptyState>
                  {keywords ? 'No jobs found matching your criteria' : 'Enter keywords to search for jobs'}
                </EmptyState>
              )}
            </JobsList>
  
            {totalJobs > 0 && renderPagination(false)}
          </>
        )}
      </ContentWrapper>
    </Container>
  );
};



// Styled Components
const Container = styled.div`
  min-height: calc(100vh - 64px);
  background-color: #F5F5F5;
  padding: 2rem;
`;

const ContentWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 2rem;
  text-align: center;
`;

const SearchCard = styled.div`
  background: #FFF;
  border: 1px solid #EAEAEA;
  border-radius: 8px;
  padding: 2rem;
  margin-bottom: 2rem;
`;






const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const Select = styled.select`
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const Button = styled.button`
  padding: 10px;
  font-size: 1rem;
  background-color: #4A25E1;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 10px;
`;

const JobList = styled.ul`
  list-style: none;
  padding: 0;
`;

const JobItem = styled.li`
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 15px;
  text-align: left;
  background: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const JobTitle = styled.a`
  font-size: 1.4rem;
  color: #0A66C2;
  text-decoration: none;
  font-weight: 600;
  display: block;
  margin-bottom: 15px;
  
  &:hover {
    text-decoration: underline;
  }
`;

const CompanyInfo = styled.div`
  display: flex;
  gap: 15px;
  align-items: flex-start;
`;

const CompanyLogo = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 4px;
  object-fit: contain;
`;

const CompanyDetails = styled.div`
  flex: 1;
  
  p {
    margin: 5px 0;
    color: #666;
    font-size: 0.95rem;
  }
  
  strong {
    color: #333;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: ${props => props.isTop ? '10px 0' : '20px 0'};
  flex-wrap: wrap;
  padding: ${props => props.isTop ? '10px' : '20px'} 0;
  background-color: ${props => props.isTop ? '#f9f9f9' : 'transparent'};
  border-radius: 8px;
`;

const PageButton = styled.button`
  padding: ${props => props.isTop ? '6px 10px' : '8px 12px'};
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: ${props => props.active ? '#4A25E1' : 'white'};
  color: ${props => props.active ? 'white' : '#333'};
  cursor: pointer;
  font-size: ${props => props.isTop ? '0.9rem' : '1rem'};
  
  &:disabled {
    background-color: #f5f5f5;
    color: #999;
    cursor: not-allowed;
  }
  
  &:hover:not(:disabled) {
    background-color: ${props => props.active ? '#4A25E1' : '#f5f5f5'};
  }
`;

const PageInfo = styled.div`
  margin-left: 10px;
  color: #666;
  font-size: ${props => props.isTop ? '0.85rem' : '0.9rem'};
`;

const ResultsSummary = styled.div`
  font-size: 1.1rem;
  color: #333;
  margin: 20px 0 10px 0;
  font-weight: 500;
`;

const ResultsDivider = styled.hr`
  border: none;
  border-top: 1px solid #eee;
  margin: 20px 0;
  background: linear-gradient(to right, transparent, #ddd, transparent);
  height: 1px;
`;


const LoadingMessage = styled.div`
  text-align: center;
  padding: 20px;
  color: #666;
  font-size: 1.1rem;
`;

const NoJobsMessage = styled.div`
  text-align: center;
  padding: 40px;
  color: #666;
  font-size: 1.1rem;
  background: #f9f9f9;
  border-radius: 8px;
  border: 1px solid #eee;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 15px;
`;

const ViewButton = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;

  &:first-child {
    background-color: #0A66C2;
    color: white;
    
    &:hover {
      background-color: #004182;
    }
  }

  &:last-child {
    background-color: #4A25E1;
    color: white;
    
    &:hover {
      background-color: #3415B0;
    }
  }
`;

// ... after existing styled components ...

const SearchForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const InputGroup = styled.div`
  display: flex;
  gap: 1rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledInput = styled.input`
  flex: 1;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  transition: border-color 0.2s;

  &:focus {
    outline: none;
    border-color: #000;
  }
`;

const FilterGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
`;

const StyledSelect = styled.select`
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  transition: border-color 0.2s;

  &:focus {
    outline: none;
    border-color: #000;
  }
`;

const ActionButton = styled.button`
  padding: 0.75rem;
  font-size: 1rem;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #333;
  }

  &:disabled {
    background-color: #CCC;
    cursor: not-allowed;
  }
`;

const LoadingState = styled.div`
  text-align: center;
  padding: 3rem;
  color: #666;
`;

const ErrorText = styled.div`
  color: #DC2626;
  padding: 1rem;
  text-align: center;
`;

const ResultsHeader = styled.div`
  margin: 2rem 0;
`;

const ResultsCount = styled.h2`
  font-size: 1.2rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 1rem;
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid #EAEAEA;
  margin: 1rem 0;
`;

const JobsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const JobCard = styled.div`
  background: white;
  border: 1px solid #EAEAEA;
  border-radius: 8px;
  padding: 1.5rem;
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  }
`;

const JobHeader = styled.div`
  margin-bottom: 1rem;
`;

const JobTitleLink = styled.a`
  font-size: 1.2rem;
  font-weight: 500;
  color: #000;
  text-decoration: none;
  display: block;
  margin-bottom: 0.5rem;

  &:hover {
    text-decoration: underline;
  }
`;

const CompanyName = styled.div`
  color: #666;
  font-size: 1rem;
`;

const JobDetails = styled.div`
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
`;

const JobInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const InfoItem = styled.div`
  display: flex;
  gap: 0.5rem;
  font-size: 0.9rem;
  color: #666;
`;

const Label = styled.span`
  font-weight: 500;
  color: #333;
`;

const ActionGroup = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

const ActionLink = styled.a`
  padding: 0.75rem 1rem;
  font-size: 0.9rem;
  text-decoration: none;
  border-radius: 4px;
  text-align: center;
  transition: background-color 0.2s;
  flex: 1;
  
  ${props => props.secondary ? `
    background-color: #000;
    color: white;
    
    &:hover {
      background-color: #333;
    }
  ` : `
    background-color: #F5F5F5;
    color: #000;
    
    &:hover {
      background-color: #EAEAEA;
    }
  `}
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 3rem;
  color: #666;
  background: #F5F5F5;
  border-radius: 8px;
`;

export default SearchJobs;
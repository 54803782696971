import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const PreInterviewAssistant = ({ apiKeyApp }) => {
  const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://cvanalystiobackend.onrender.com';
  const [companyName, setCompanyName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [industry, setIndustry] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [companyLinkedIn, setCompanyLinkedIn] = useState('');
  const [researchResults, setResearchResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleGenerateResearch = async () => {
    if (!companyName.trim()) {
      setError('Please provide the company name');
      return;
    }
    
    setLoading(true);
    setError(null);

    try {
      // Get the auth token from localStorage
      const accessToken = localStorage.getItem('authToken');
      if (!accessToken) {
        setError('Please login to use this feature');
        return;
      }

      const response = await axios.post(
        `${BACKEND_API_URL}/generate_company_research`, 
        {
          company_name: cleanText(companyName),
          job_title: cleanText(jobTitle),
          industry: cleanText(industry),
          company_website: cleanText(companyWebsite),
          company_linkedin: cleanText(companyLinkedIn)
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          }
        }
      );

      if (response.data) {
        setResearchResults(response.data.research);
      }
    } catch (error) {
      console.error('Error generating research:', error);
      if (error.response?.status === 401) {
        setError('Please login to use this feature');
      } else if (error.response?.status === 403) {
        setError('Your subscription is not active. Please check your subscription status.');
      } else {
        setError('Failed to generate company research. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const cleanText = (text) => {
    if (!text) return '';
    return text
      .replace(/[\u2018\u2019]/g, "'")
      .replace(/[\u201C\u201D]/g, '"')
      .replace(/[\u2013\u2014]/g, '-')
      .replace(/[\u2026]/g, '...')
      .replace(/[^\x00-\x7F]/g, '')
      .replace(/\s+/g, ' ')
      .trim();
  };

  return (
    <Container>
      <ContentWrapper>
        <Title>Pre-Interview Research Assistant</Title>
        <Subtitle>Get comprehensive insights about your target company to prepare for your interview</Subtitle>
        
        <Card>
          <Section>
            <SectionTitle>Company Details</SectionTitle>
            <InputGroup>
              <InputLabel>Company Name*</InputLabel>
              <StyledInput
                type="text"
                placeholder="e.g., Microsoft"
                onChange={(e) => setCompanyName(e.target.value)}
                required
              />
            </InputGroup>

            <InputGroup>
              <InputLabel>Job Title*</InputLabel>
              <StyledInput
                type="text"
                placeholder="e.g., Senior Software Engineer"
                onChange={(e) => setJobTitle(e.target.value)}
                required
              />
            </InputGroup>

            <InputGroup>
              <InputLabel>Industry</InputLabel>
              <StyledInput
                type="text"
                placeholder="e.g., Technology"
                onChange={(e) => setIndustry(e.target.value)}
              />
            </InputGroup>

            <InputGroup>
              <InputLabel>Company Website (Optional)</InputLabel>
              <StyledInput
                type="url"
                placeholder="e.g., https://www.company.com"
                onChange={(e) => setCompanyWebsite(e.target.value)}
              />
            </InputGroup>

            <InputGroup>
              <InputLabel>Company LinkedIn (Optional)</InputLabel>
              <StyledInput
                type="url"
                placeholder="e.g., https://www.linkedin.com/company/..."
                onChange={(e) => setCompanyLinkedIn(e.target.value)}
              />
            </InputGroup>
          </Section>

          <ActionButton onClick={handleGenerateResearch} disabled={loading}>
            {loading ? 'Researching...' : 'Generate Company Insights'}
          </ActionButton>
          
          {error && <ErrorText>{error}</ErrorText>}
        </Card>

        {researchResults && (
          <Card>
            <SectionTitle>Company Research Insights</SectionTitle>
            <ResearchContainer>
              {Object.entries(researchResults).map(([category, content]) => (
                <ResearchSection key={category}>
                  <ResearchTitle>{category}</ResearchTitle>
                  <ResearchContent>
                    {Array.isArray(content) ? (
                      <ul>
                        {content.map((item, index) => (
                          <ResearchListItem key={index}>{item}</ResearchListItem>
                        ))}
                      </ul>
                    ) : (
                      <ResearchText>{content}</ResearchText>
                    )}
                  </ResearchContent>
                </ResearchSection>
              ))}
            </ResearchContainer>
          </Card>
        )}
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const ContentWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 2rem;
  text-align: center;
`;

const Card = styled.div`
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  border-radius: 8px;
  padding: 2rem;
  margin-bottom: 2rem;
`;

const Section = styled.div`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 1rem;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  font-size: 0.9rem;
  min-height: 100px;
  resize: vertical;
  transition: border-color 0.2s;

  &:focus {
    outline: none;
    border-color: #000;
  }
`;

const ActionButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  background-color: #000;
  color: #FFF;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #333;
  }

  &:disabled {
    background-color: #CCC;
    cursor: not-allowed;
  }
`;

const ErrorText = styled.p`
  color: #DC2626;
  font-size: 0.9rem;
  margin-top: 1rem;
`;


const InputGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const InputLabel = styled.label`
  display: block;
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 0.5rem;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  font-size: 0.9rem;
  transition: border-color 0.2s;

  &:focus {
    outline: none;
    border-color: #000;
  }
`;

const ResearchContainer = styled.div`
  margin-top: 1rem;
`;

const ResearchSection = styled.div`
  margin-bottom: 2rem;
  padding: 1.5rem;
  background-color: #F5F5F5;
  border-radius: 4px;
`;

const ResearchTitle = styled.h3`
  font-size: 1.1rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #EAEAEA;
`;

const ResearchContent = styled.div`
  color: #333;
`;

const ResearchText = styled.p`
  font-size: 0.9rem;
  line-height: 1.6;
`;

const ResearchListItem = styled.li`
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  line-height: 1.6;
`;


const Subtitle = styled.p`
  text-align: center;
  color: #666;
  margin-bottom: 2rem;
  font-size: 1.1rem;
`;

export default PreInterviewAssistant;
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const ResumeEnhancer = ({ apiKeyApp }) => {
  const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://cvanalystiobackend.onrender.com';
  const [uploadedResume, setUploadedResume] = useState(null);
  const [isResumeEnhanced, setIsResumeEnhanced] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [keyAchievements, setKeyAchievements] = useState('');
  const [softSkills, setSoftSkills] = useState('');
  const [technicalSkills, setTechnicalSkills] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [outputCode, setOutputCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [inputKey, setInputKey] = useState(Date.now());

  const handleResumeUpload = (event) => {
    const file = event.target.files?.[0];
    if (file && file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setUploadedResume(file);
      console.log('Upload successful');
    } else {
      setError('Please upload a DOCX file');
      console.log('Upload failed: The file is not a DOCX file');
    }
  };

  const handleGenerateResume = async () => {
    if (!uploadedResume) {
      console.error('No resume uploaded');
      return;
    }
    if (!jobDescription.trim()) {
      console.error('Job description is empty');
      return;
    }
    setLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append('resume', uploadedResume);
    formData.append('job_description', cleanText(jobDescription));
    formData.append('key_achievements', cleanText(keyAchievements));
    formData.append('soft_skills', cleanText(softSkills));
    formData.append('technical_skills', cleanText(technicalSkills));

    const accessToken = localStorage.getItem('authToken');
    try {
      const response = await axios.post(`${BACKEND_API_URL}/enhance_resume`, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (response.data) {
        const binaryData = atob(response.data.file);
        const byteNumbers = new Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
          byteNumbers[i] = binaryData.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        const url = window.URL.createObjectURL(blob);
        setDownloadUrl(url);
        setOutputCode(response.data.text);
        setIsResumeEnhanced(true);
      } else {
        console.error('No data received from the backend');
      }
    } catch (error) {
      console.error('Error enhancing resume:', error);
      setError('Failed to enhance resume. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveResume = () => {
    setUploadedResume(null);
    setInputKey(Date.now());
  };

  const cleanText = (text) => {
    if (!text) return '';
    return text
      .replace(/[\u2018\u2019]/g, "'") // Smart quotes to regular quotes
      .replace(/[\u201C\u201D]/g, '"') // Smart double quotes to regular quotes
      .replace(/[\u2013\u2014]/g, '-') // Em/En dashes to hyphens
      .replace(/[\u2026]/g, '...') // Ellipsis to dots
      .replace(/[^\x00-\x7F]/g, '') // Remove non-ASCII characters
      .replace(/\s+/g, ' ') // Replace multiple spaces with single space
      .trim();
  };
  


  return (
    <Container>
      <ContentWrapper>
        <Title>Resume Enhancer</Title>
        <Card>
          <Section>
            <SectionTitle>Upload Resume</SectionTitle>
            <FileUploadContainer>
              <FileInput
                key={inputKey}
                type="file"
                accept=".docx"
                onChange={handleResumeUpload}
                id="resume-upload"
              />
              <FileLabel htmlFor="resume-upload">
                {uploadedResume ? uploadedResume.name : 'Choose .docx file'}
              </FileLabel>
              {uploadedResume && (
                <TextButton onClick={handleRemoveResume}>Remove</TextButton>
              )}
            </FileUploadContainer>
          </Section>

          <Section>
            <SectionTitle>Optional Enhancements</SectionTitle>
            <InputGroup>
              <Label>Key Achievements</Label>
              <StyledTextarea
                placeholder="Enter any key achievements you'd like to highlight..."
                onChange={(e) => setKeyAchievements(e.target.value)}
              />
            </InputGroup>
            <InputGroup>
              <Label>Technical Skills</Label>
              <StyledInput
                placeholder="What technical skills should be prioritized?"
                onChange={(e) => setTechnicalSkills(e.target.value)}
              />
            </InputGroup>
            <InputGroup>
              <Label>Soft Skills</Label>
              <StyledInput
                placeholder="What soft skills should be prioritized?"
                onChange={(e) => setSoftSkills(e.target.value)}
              />
            </InputGroup>
          </Section>

          <Section>
            <SectionTitle>Job Description</SectionTitle>
            <StyledTextarea
              placeholder="Paste the job description here..."
              onChange={(e) => setJobDescription(e.target.value)}
              required
            />
          </Section>

          <ActionButton onClick={handleGenerateResume} disabled={loading}>
            {loading ? 'Generating...' : 'Generate Resume'}
          </ActionButton>
          
          {error && <ErrorText>{error}</ErrorText>}
        </Card>

        {isResumeEnhanced && (
          <Card>
            <SectionTitle>Generated Resume</SectionTitle>
            <ActionButton onClick={() => {
              if (downloadUrl) {
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = 'enhanced_resume.docx';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setIsResumeEnhanced(false);
              }
            }}>
              Download Resume
            </ActionButton>
            <PreviewSection>
              <Label>Preview</Label>
              <PreviewContent>{outputCode}</PreviewContent>
            </PreviewSection>
          </Card>
        )}
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div`
  min-height: calc(100vh - 64px);
  background-color: #F5F5F5;
  padding: 2rem;
`;

const ContentWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 2rem;
  text-align: center;
`;

const Card = styled.div`
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  border-radius: 8px;
  padding: 2rem;
  margin-bottom: 2rem;
`;

const Section = styled.div`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 1rem;
`;

const InputGroup = styled.div`
  margin-bottom: 1rem;
`;

const Label = styled.label`
  display: block;
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.5rem;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  font-size: 0.9rem;
  transition: border-color 0.2s;

  &:focus {
    outline: none;
    border-color: #000;
  }
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  font-size: 0.9rem;
  min-height: 100px;
  resize: vertical;
  transition: border-color 0.2s;

  &:focus {
    outline: none;
    border-color: #000;
  }
`;

const FileUploadContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const FileInput = styled.input`
  display: none;
`;

const FileLabel = styled.label`
  padding: 0.75rem 1rem;
  background-color: #F5F5F5;
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.2s;

  &:hover {
    background-color: #EAEAEA;
  }
`;

const TextButton = styled.button`
  background: none;
  border: none;
  color: #666;
  font-size: 0.9rem;
  cursor: pointer;
  padding: 0;

  &:hover {
    color: #000;
  }
`;

const ActionButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  background-color: #000;
  color: #FFF;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #333;
  }

  &:disabled {
    background-color: #CCC;
    cursor: not-allowed;
  }
`;

const ErrorText = styled.p`
  color: #DC2626;
  font-size: 0.9rem;
  margin-top: 1rem;
`;

const PreviewSection = styled.div`
  margin-top: 1.5rem;
`;

const PreviewContent = styled.div`
  padding: 1rem;
  background-color: #F5F5F5;
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  font-size: 0.9rem;
  white-space: pre-wrap;
`;

export default ResumeEnhancer;
import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { FaSpinner, FaCheckCircle, FaUpload } from 'react-icons/fa';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://cvanalystiobackend.onrender.com';

const FreeResume = () => {
  const [step, setStep] = useState('upload');
  const [linkedinUrl, setLinkedinUrl] = useState('');
  const [resume, setResume] = useState(null);
  const [resumeName, setResumeName] = useState('');
  const [email, setEmail] = useState('');
  const [enhancedResume, setEnhancedResume] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [processingStep, setProcessingStep] = useState(0);
  const [preview, setPreview] = useState(null);
  const [success, setSuccess] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const [showEmailForm, setShowEmailForm] = useState(false);

  const processingSteps = [
    "Scanning your resume...",
    "Identifying missing keywords...",
    "Enhancing formatting for recruiters...",
    "Generating your brand-new resume!"
  ];

  const extractJobIdFromUrl = (url) => {
    // Handle both URL formats
    const searchMatch = url.match(/currentJobId=(\d+)/);
    const directMatch = url.match(/jobs\/view\/(\d+)/);
    const cleanDirectMatch = url.match(/(\d+)\/$/); 
    
    if (searchMatch) return searchMatch[1];
    if (directMatch) return directMatch[1];
    if (cleanDirectMatch) return cleanDirectMatch[1];
    
    // If the input is just a number, return it directly
    if (/^\d+$/.test(url)) return url;
    
    return null;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setResume(file);
      setResumeName(file.name);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!resume || !linkedinUrl) {
      setError("Please provide your resume and LinkedIn job URL");
      return;
    }

    try {
      setStep('processing');
      setLoading(true);
      setError(null);

      const jobId = extractJobIdFromUrl(linkedinUrl);
      if (!jobId) {
        setError('Invalid LinkedIn job URL');
        setLoading(false);
        setStep('upload');
        return;
      }

      // Create form data with resume and job ID
      const formData = new FormData();
      formData.append('resume', resume);
      formData.append('job_id', jobId);
      
      const response = await axios.post(
        `${BACKEND_API_URL}/free_resume_enhancement`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          timeout: 300000, // 5 minute timeout
          timeoutErrorMessage: 'Request timed out - please try again',
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProcessingStep(Math.min(Math.floor(percentCompleted / 25), 3));
          }
        }
      );

      if (response.data.preview) {
        setPreviewData(response.data);
        setShowEmailForm(true);
        setStep('emailCapture');
      } else {
        throw new Error('No preview received from server');
      }
    } catch (err) {
      const errorMessage = err.response?.data?.detail || 
                         err.message === 'Network Error' ? 'Network error - please check your connection and try again' :
                         err.message === 'Request timed out - please try again' ? err.message :
                         (typeof err.message === 'string' ? err.message : 'Something went wrong');
      setError(errorMessage);
      setStep('upload');
    } finally {
      setLoading(false);
    }
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setError("Please enter your email");
      return;
    }
    
    try {
      setLoading(true);
      setError(null);
      
      const formData = new FormData();
      formData.append('email', email);
      formData.append('resume_text', previewData.full_text);
      formData.append('resume_file', previewData.file);
      
      const response = await axios.post(
        `${BACKEND_API_URL}/save_free_trial`, 
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          timeout: 60000, // 1 minute timeout for email submission
          timeoutErrorMessage: 'Request timed out - please try again'
        }
      );
      
      setStep('success');
      setSuccess(true);

      // Download the file
      const blob = new Blob([Buffer.from(previewData.file, 'base64')], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'enhanced_resume.docx';
      a.click();
    } catch (err) {
      let errorMessage;
      
      if (err.response?.status === 400 && err.response?.data?.detail?.includes('Free trial already used')) {
        errorMessage = 'This email has already been used for a free trial. Please use a different email or upgrade to Pro for unlimited access.';
      } else if (err.response?.status === 500 && (
        err.response?.data?.detail?.includes('duplicate key') || 
        err.response?.data?.detail?.includes('already exists')
      )) {
        errorMessage = 'This email has already been used for a free trial. Please use a different email or upgrade to Pro for unlimited access.';
      } else if (err.message === 'Network Error') {
        errorMessage = 'Network error - please check your connection and try again';
      } else if (err.message === 'Request timed out - please try again') {
        errorMessage = err.message;
      } else {
        errorMessage = 'Something went wrong. Please try again later.';
      }
      
      setError(errorMessage);
      
      // If it's a duplicate email error, show the upgrade button
      if (errorMessage.includes('already been used')) {
        setStep('upgrade');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      {step === 'upload' && (
        <UploadSection>
          <Title>Instantly Generate a Job-Winning Resume</Title>
          <Subtitle>Upload your resume and paste the job URL. Get a fully optimized resume in minutes.</Subtitle>
          
          <Form onSubmit={handleSubmit}>
            <FileUploadContainer>
              <FileInput
                type="file"
                onChange={handleFileChange}
                accept=".doc,.docx,.pdf"
                id="resume-upload"
              />
              <FileUploadLabel htmlFor="resume-upload">
                <FaUpload />
                {resumeName || "Upload Resume"}
              </FileUploadLabel>
            </FileUploadContainer>

            <Input
              type="text"
              value={linkedinUrl}
              onChange={(e) => setLinkedinUrl(e.target.value)}
              placeholder="Paste LinkedIn Job URL"
              required
            />

            <SubmitButton type="submit" disabled={loading || !resume || !linkedinUrl}>
              Get My AI Resume Now
            </SubmitButton>
          </Form>
          
          {error && <ErrorMessage>{typeof error === 'string' ? error : 'An error occurred'}</ErrorMessage>}
        </UploadSection>
      )}

      {step === 'processing' && (
        <ProcessingSection>
          <ProcessingTitle>Optimizing Your Resume</ProcessingTitle>
          {processingSteps.map((step, index) => (
            <ProcessingStep key={index} active={index === processingStep}>
              {index < processingStep ? <FaCheckCircle color="#4CAF50" /> : <FaSpinner className={index === processingStep ? 'spinning' : ''} />}
              <span>{step}</span>
            </ProcessingStep>
          ))}
        </ProcessingSection>
      )}

      {step === 'emailCapture' && (
        <EmailCaptureSection>
          <BlurredPreview>
            {previewData?.preview_text}
            Your New Resume is Ready!
          </BlurredPreview>
          
          {previewData && showEmailForm && (
            <EmailForm onSubmit={handleEmailSubmit}>
              <EmailTitle>Enter your email to download the enhanced resume</EmailTitle>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
              />
              <SubmitButton type="submit" disabled={loading}>
                {loading ? 'Processing...' : 'Download Resume'}
              </SubmitButton>
              <SmallText>One free attempt per user. No spam, just your new resume.</SmallText>
            </EmailForm>
          )}
          
          {error && <ErrorMessage>{typeof error === 'string' ? error : 'An error occurred'}</ErrorMessage>}
        </EmailCaptureSection>
      )}

      {step === 'success' && (
        <SuccessSection>
          <SuccessTitle>🎉 Check Your Email!</SuccessTitle>
          <SuccessMessage>
            We've sent your AI-optimized resume to {email}. 
            Don't forget to check your spam folder if you don't see it in your inbox.
          </SuccessMessage>
          <UpgradeMessage>
            Want unlimited resume optimizations and cover letters?
          </UpgradeMessage>
          <UpgradeButton
            as="a"
            href="https://analyticpinnacle.thrivecart.com/cv-analyst/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Upgrade to Pro - 20% Off
          </UpgradeButton>
        </SuccessSection>
      )}

      {step === 'upgrade' && (
        <SuccessSection>
          <SuccessTitle style={{ color: '#4A25E1' }}>Ready to Unlock Full Access?</SuccessTitle>
          <SuccessMessage>
            You've already used your free trial. Upgrade to Pro for unlimited resume optimizations and cover letters!
          </SuccessMessage>
          <UpgradeButton
            as="a"
            href="https://analyticpinnacle.thrivecart.com/cv-analyst/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Upgrade to Pro - 20% Off
          </UpgradeButton>
        </SuccessSection>
      )}
    </Container>
  );
};

// Styled Components
const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
  color: #1a1a1a;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  text-align: center;
  color: #666;
  margin-bottom: 2rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Input = styled.input`
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
`;

const FileUploadContainer = styled.div`
  position: relative;
`;

const FileInput = styled.input`
  display: none;
`;

const FileUploadLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  border: 2px dashed #ddd;
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s;

  &:hover {
    border-color: #4A25E1;
  }
`;

const SubmitButton = styled.button`
  padding: 1rem;
  background-color: #4A25E1;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: #3615D0;
  }
`;

const ProcessingSection = styled.div`
  text-align: center;
  padding: 2rem;
`;

const ProcessingTitle = styled.h2`
  margin-bottom: 2rem;
`;

const ProcessingStep = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem 0;
  opacity: ${props => props.active ? 1 : 0.5};

  .spinning {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

const EmailCaptureSection = styled.div`
  text-align: center;
`;

const BlurredPreview = styled.div`
  background: rgba(74, 37, 225, 0.1);
  padding: 3rem;
  margin: 2rem 0;
  border-radius: 8px;
  font-size: 1.5rem;
  color: #4A25E1;
`;

const EmailForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 400px;
  margin: 0 auto;
`;

const EmailTitle = styled.h3`
  margin-bottom: 1rem;
`;

const SmallText = styled.p`
  font-size: 0.8rem;
  color: #666;
`;

const SuccessSection = styled.div`
  text-align: center;
  padding: 2rem;
`;

const SuccessTitle = styled.h2`
  color: #4CAF50;
  margin-bottom: 1rem;
`;

const SuccessMessage = styled.p`
  margin-bottom: 2rem;
`;

const UpgradeMessage = styled.p`
  font-size: 1.2rem;
  margin-bottom: 1rem;
`;

const UpgradeButton = styled(SubmitButton)`
  display: inline-block;
  text-decoration: none;
  background-color: #4CAF50;

  &:hover {
    background-color: #45a049;
  }
`;

const ErrorMessage = styled.p`
  color: #ff4444;
  margin-top: 1rem;
`;

const UploadSection = styled.div``;

export default FreeResume;
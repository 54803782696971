import React from 'react';
import styled from 'styled-components';

const Home = () => {
  return (
    <MainContainer>
      <HeroSection>
        <HeroTitle>CVAnalyst</HeroTitle>
        <HeroSubtitle>AI-Powered Job Application Assistant</HeroSubtitle>
      </HeroSection>

      <FeaturesSection>
        <FeatureCard>
          <FeatureTitle>Job Search</FeatureTitle>
          <FeatureDescription>
            Search and track job listings directly from LinkedIn. Get instant access to the latest opportunities.
          </FeatureDescription>
        </FeatureCard>

        <FeatureCard>
          <FeatureTitle>Resume Enhancement</FeatureTitle>
          <FeatureDescription>
            AI-powered resume optimization tailored to each job description you apply for.
          </FeatureDescription>
        </FeatureCard>

        <FeatureCard>
          <FeatureTitle>Cover Letters</FeatureTitle>
          <FeatureDescription>
            Generate customized cover letters instantly, perfectly matched to your target roles.
          </FeatureDescription>
        </FeatureCard>
      </FeaturesSection>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  background-color: #fff;
  min-height: calc(100vh - 64px);
`;

const HeroSection = styled.div`
  text-align: center;
  margin-bottom: 4rem;
`;

const HeroTitle = styled.h1`
  font-size: 3.5rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 1rem;
`;

const HeroSubtitle = styled.p`
  font-size: 1.5rem;
  color: #666;
`;

const FeaturesSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 0 1rem;
`;

const FeatureCard = styled.div`
  background: #fff;
  padding: 2rem;
  border: 1px solid #EAEAEA;
  border-radius: 8px;
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  }
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  color: #000;
  margin-bottom: 1rem;
  font-weight: 500;
`;

const FeatureDescription = styled.p`
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
`;

export default Home;
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { FaSpinner, FaCheck, FaUpload, FaArrowLeft, FaDownload, FaEnvelope, FaFileAlt } from 'react-icons/fa';

const BACKEND_API_URL = 'http://localhost:8000';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Inter', sans-serif;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: #0066cc;
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
  
  &:hover {
    text-decoration: underline;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin: 0;
`;

const Form = styled.form`
  background-color: white;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  
  &:focus {
    outline: none;
    border-color: #0066cc;
  }
`;

const FileInput = styled.input`
  display: none;
`;

const FileInputLabel = styled.label`
  display: inline-flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #f0f0f0;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  
  &:hover {
    background-color: #e0e0e0;
  }
`;

const FileName = styled.span`
  margin-left: 10px;
  font-size: 14px;
  color: #666;
`;

const Button = styled.button`
  background-color: #0066cc;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  
  &:hover {
    background-color: #0055aa;
  }
  
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  color: #d32f2f;
  margin-top: 10px;
  font-size: 14px;
`;

const LoadingContainer = styled.div`
  text-align: center;
  padding: 40px 20px;
`;

const LoadingText = styled.div`
  margin-top: 20px;
  font-size: 18px;
  color: #333;
`;

const SuccessMessage = styled.div`
  background-color: #e8f5e9;
  border: 1px solid #c8e6c9;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  text-align: center;
`;

const ResultsContainer = styled.div`
  margin-top: 20px;
`;

const ResultCard = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
`;

const CardTitle = styled.h3`
  margin: 0;
  color: #333;
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 10px;
    color: #0066cc;
  }
`;

const DownloadButton = styled.button`
  background: #0066cc;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  
  &:hover {
    background: #0055aa;
  }
  
  svg {
    margin-right: 8px;
  }
`;

const EmailPreview = styled.div`
  background: #f9f9f9;
  border-radius: 4px;
  padding: 15px;
  margin-top: 10px;
  white-space: pre-wrap;
`;

const EmailSubject = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
`;

const CopyButton = styled.button`
  background: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 15px;
  margin-top: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  
  &:hover {
    background: #3d9140;
  }
  
  svg {
    margin-right: 8px;
  }
`;

const HiringResume = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [linkedinUrl, setLinkedinUrl] = useState('');
  const [resume, setResume] = useState(null);
  const [resumeName, setResumeName] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [loadingStep, setLoadingStep] = useState(0);
  const [packageData, setPackageData] = useState(null);
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [showResults, setShowResults] = useState(false);
  
  const loadingMessages = [
    "Analyzing job description...",
    "Tailoring your resume to match the job requirements...",
    "Crafting your personalized cover letter...",
    "Preparing your outreach email...",
    "Generating your complete application package!"
  ];

  const extractJobIdFromUrl = (url) => {
    // Handle search result format with currentJobId
    const searchMatch = url.match(/currentJobId=(\d+)/);
    if (searchMatch) return searchMatch[1];
    
    // Handle direct job view format - match only the last number in the URL
    const viewMatch = url.match(/(?:jobs\/view\/.*-)(\d+)\/?$/);
    if (viewMatch) return viewMatch[1];
    
    // If the input is just a number, return it directly
    if (/^\d+$/.test(url)) return url;
    
    return null;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
        if (!file.name.toLowerCase().endsWith('.docx')) {
            setError("Please upload a DOCX file only. PDFs are not supported.");
            setResume(null);
            setResumeName('');
            return;
        }
        if (file.size > 5 * 1024 * 1024) {
            setError("File size too large. Please upload a file under 5MB.");
            return;
        }
        setResume(file);
        setResumeName(file.name);
        setError(null);
    }
  };

  // Add useEffect to handle pre-filled job URL and hiring manager info
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const jobUrl = params.get('jobUrl');
    console.log('URL from params:', jobUrl);
    if (jobUrl) {
      setLinkedinUrl(jobUrl);
    }
    
    // Get user email from localStorage if available
    const storedEmail = localStorage.getItem('userEmail');
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!resume) {
      setError("Please upload your resume");
      return;
    }
    
    if (!linkedinUrl) {
      setError("Please enter the LinkedIn job URL");
      return;
    }
    
    if (!email) {
      setError("Please enter your email address");
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      const jobId = extractJobIdFromUrl(linkedinUrl);
      console.log('Extracted job ID:', jobId);
      
      if (!jobId) {
        throw new Error("Invalid LinkedIn job URL. Please enter a valid URL.");
      }
      
      // Get the selected job data from localStorage
      const selectedJobData = JSON.parse(localStorage.getItem('selectedJob') || '{}');
      
      // Create form data
      const formData = new FormData();
      formData.append('resume', resume);
      formData.append('job_id', jobId);
      formData.append('job_url', linkedinUrl);
      formData.append('email', email);
      
      // Add job details if available
      if (selectedJobData.title) formData.append('job_title', selectedJobData.title);
      if (selectedJobData.company) formData.append('company_name', selectedJobData.company);
      if (selectedJobData.description) formData.append('job_description', selectedJobData.description);
      
      // Add hiring manager details if available
      if (selectedJobData.hiring_manager) {
        const hm = selectedJobData.hiring_manager;
        if (hm.name) formData.append('hiring_manager_name', hm.name);
        if (hm.title) formData.append('hiring_manager_title', hm.title);
        if (hm.linkedin_url) formData.append('hiring_manager_linkedin', hm.linkedin_url);
        if (hm.email) formData.append('hiring_manager_email', hm.email);
      }
      
      // Start loading animation
      const loadingInterval = setInterval(() => {
        setLoadingStep(prev => (prev + 1) % loadingMessages.length);
      }, 3000);
      
      // Get auth token
      const accessToken = localStorage.getItem('authToken');
      if (!accessToken) {
        throw new Error('Please login to use this feature');
      }
      
      // Make API call
      const response = await axios.post(
        `${BACKEND_API_URL}/complete_job_package`, 
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${accessToken}`
          },
          timeout: 60000,
          timeoutErrorMessage: 'Request timed out - please try again',
          withCredentials: true
        }
      );
      
      clearInterval(loadingInterval);
      
      if (response.data && response.data.success) {
        setPackageData(response.data);
        setShowResults(true);
        
        // Store email in localStorage for future reference
        localStorage.setItem('userEmail', email);
        
        setLoading(false);
      } else {
        throw new Error(response.data?.message || 'Failed to generate package');
      }
    } catch (err) {
      setError(err.message || 'An error occurred. Please try again.');
      setLoading(false);
    }
  };
  
  const handleEmailSubmit = async () => {
    try {
      setLoading(true);
      
      // Create form data for email submission
      const formData = new FormData();
      formData.append('email', email);
      formData.append('job_url', linkedinUrl);
      
      // Include resume file if available
      if (resume) {
        formData.append('resume_file', resume);
      }
      
      // Include resume data if available
      if (packageData?.resume_file) {
        formData.append('enhanced_resume_file', packageData.resume_file);
      }
      
      // Include cover letter if available
      if (packageData?.cover_letter_file) {
        formData.append('cover_letter_file', packageData.cover_letter_file || '');
      }
      
      // Include outreach email if available
      if (packageData?.outreach_email) {
        formData.append('outreach_email_text', packageData.outreach_email || '');
        formData.append('email_subject', packageData.email_subject || '');
      }
      
      // Include hiring manager email if available
      if (packageData?.hiring_manager_email) {
        formData.append('hiring_manager_email', packageData.hiring_manager_email || '');
      }
      
      // Get auth token
      const accessToken = localStorage.getItem('authToken');
      
      // Make API call to send email
      const response = await axios.post(
        `${BACKEND_API_URL}/send_complete_job_package_email`, 
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${accessToken}`
          },
          timeout: 60000,
          timeoutErrorMessage: 'Request timed out - please try again',
          withCredentials: true
        }
      );
      
      // Show success message
      setEmailSubmitted(true);
      setLoading(false);
    } catch (err) {
      setError(err.message || 'Failed to send email. Please try again.');
      setLoading(false);
    }
  };
  
  const downloadDocument = (base64Data, fileName) => {
    try {
      // Convert base64 to blob
      const byteCharacters = atob(base64Data);
      const byteArrays = [];
      
      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      
      const blob = new Blob(byteArrays, { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
      const url = window.URL.createObjectURL(blob);
      
      // Create download link and click it
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (err) {
      setError(`Failed to download ${fileName}: ${err.message}`);
    }
  };
  
  const handleBack = () => {
    navigate('/hiring-manager');
  };
  
  if (loading) {
    return (
      <Container>
        <Header>
          <BackButton onClick={handleBack}>
            <FaArrowLeft style={{ marginRight: '8px' }} /> Back to Search
          </BackButton>
          <Title>Generating Your Job Application Package</Title>
        </Header>
        
        <LoadingContainer>
          <FaSpinner style={{ fontSize: '40px', color: '#0066cc', animation: 'spin 1s linear infinite' }} />
          <LoadingText>{loadingMessages[loadingStep]}</LoadingText>
        </LoadingContainer>
      </Container>
    );
  }
  
  if (emailSubmitted) {
    return (
      <Container>
        <Header>
          <BackButton onClick={handleBack}>
            <FaArrowLeft style={{ marginRight: '8px' }} /> Back to Search
          </BackButton>
          <Title>Job Application Package Sent!</Title>
        </Header>
        
        <SuccessMessage>
          <FaCheck style={{ fontSize: '40px', color: '#4caf50', marginBottom: '20px' }} />
          <h2>Success!</h2>
          <p>Your complete job application package has been sent to your email: <strong>{email}</strong></p>
          <p>The email includes your tailored resume, cover letter, and outreach email for this job.</p>
          <Button onClick={handleBack} style={{ marginTop: '20px', maxWidth: '300px', margin: '20px auto 0' }}>
            Return to Job Search
          </Button>
        </SuccessMessage>
      </Container>
    );
  }
  
  if (showResults && packageData) {
    return (
      <Container>
        <Header>
          <BackButton onClick={handleBack}>
            <FaArrowLeft style={{ marginRight: '8px' }} /> Back to Search
          </BackButton>
          <Title>Your Job Application Package</Title>
        </Header>
        
        <SuccessMessage style={{ marginBottom: '30px' }}>
          <FaCheck style={{ fontSize: '40px', color: '#4caf50', marginBottom: '20px' }} />
          <h2>Success!</h2>
          <p>Your complete job application package has been generated successfully.</p>
          <p>You can download each component below or send the entire package to your email.</p>
        </SuccessMessage>
        
        <ResultsContainer>
          {/* Resume Card */}
          <ResultCard>
            <CardHeader>
              <CardTitle><FaFileAlt /> Enhanced Resume</CardTitle>
              <DownloadButton 
                onClick={() => downloadDocument(packageData.resume_file, 'enhanced_resume.docx')}
              >
                <FaDownload /> Download Resume
              </DownloadButton>
            </CardHeader>
            <p>Your resume has been tailored to match the job requirements and highlight your relevant skills.</p>
          </ResultCard>
          
          {/* Cover Letter Card */}
          <ResultCard>
            <CardHeader>
              <CardTitle><FaFileAlt /> Cover Letter</CardTitle>
              <DownloadButton 
                onClick={() => downloadDocument(packageData.cover_letter_file, 'cover_letter.docx')}
              >
                <FaDownload /> Download Cover Letter
              </DownloadButton>
            </CardHeader>
            <p>A personalized cover letter has been created to complement your resume and address the specific job requirements.</p>
          </ResultCard>
          
          {/* Outreach Email Card */}
          <ResultCard>
            <CardHeader>
              <CardTitle><FaEnvelope /> Outreach Email</CardTitle>
            </CardHeader>
            <p>Use this email template when sending your application to the hiring manager:</p>
            <EmailPreview>
              <EmailSubject>Subject: {packageData.email_subject}</EmailSubject>
              {packageData.outreach_email}
            </EmailPreview>
            <CopyButton
              onClick={() => {
                navigator.clipboard.writeText(`Subject: ${packageData.email_subject}\n\n${packageData.outreach_email}`);
                alert('Email copied to clipboard!');
              }}
            >
              <FaCheck /> Copy to Clipboard
            </CopyButton>
          </ResultCard>
          
          {/* Email Package Button */}
          <Button 
            onClick={handleEmailSubmit} 
            style={{ marginTop: '20px', maxWidth: '300px', margin: '20px auto' }}
          >
            Email This Package To Me
          </Button>
        </ResultsContainer>
      </Container>
    );
  }
  
  return (
    <Container>
      <Header>
        <BackButton onClick={handleBack}>
          <FaArrowLeft style={{ marginRight: '8px' }} /> Back to Search
        </BackButton>
        <Title>Generate Your Job Application Package</Title>
      </Header>
      
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="resume">Upload Your Resume (DOCX only)</Label>
          <FileInputLabel>
            <FaUpload style={{ marginRight: '8px' }} />
            {resumeName ? 'Change Resume' : 'Select Resume'}
            <FileInput 
              type="file" 
              id="resume" 
              accept=".docx" 
              onChange={handleFileChange} 
            />
          </FileInputLabel>
          {resumeName && <FileName>{resumeName}</FileName>}
        </FormGroup>
        
        <FormGroup>
          <Label htmlFor="linkedinUrl">LinkedIn Job URL</Label>
          <Input 
            type="text" 
            id="linkedinUrl" 
            value={linkedinUrl} 
            onChange={(e) => setLinkedinUrl(e.target.value)}
            placeholder="https://www.linkedin.com/jobs/view/..." 
          />
        </FormGroup>
        
        <FormGroup>
          <Label htmlFor="email">Your Email Address</Label>
          <Input 
            type="email" 
            id="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)}
            placeholder="email@example.com" 
          />
        </FormGroup>
        
        {error && <ErrorMessage>{error}</ErrorMessage>}
        
        <Button type="submit" disabled={loading}>
          {loading ? <FaSpinner style={{ animation: 'spin 1s linear infinite' }} /> : 'Generate Complete Package'}
        </Button>
      </Form>
    </Container>
  );
};

export default HiringResume; 
import React from 'react';
import styled from 'styled-components';

const Billing = () => {
  const handleManageSubscription = () => {
    window.open('https://analyticpinnacle.thrivecart.com/updateinfo/', '_blank');
  };

  return (
    <Container>
      <Card>
        <Title>Manage Subscription</Title>
        <Description>
          Need to update your billing information or cancel your subscription? 
          Click below to access your customer portal.
        </Description>
        <ActionButton onClick={handleManageSubscription}>
          Manage Subscription
        </ActionButton>
      </Card>
    </Container>
  );
};

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  min-height: calc(100vh - 64px);
`;

const Card = styled.div`
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 2rem;
  color: #000;
  margin-bottom: 1rem;
  font-weight: 500;
`;

const Description = styled.p`
  color: #666;
  margin-bottom: 2rem;
  line-height: 1.5;
`;

const ActionButton = styled.button`
  width: 100%;
  max-width: 300px;
  padding: 1rem;
  background-color: #000;
  color: #FFF;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #333;
  }
`;

export default Billing;
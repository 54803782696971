import React, { useEffect, useState } from 'react';
import { useUser } from './UserContext';
import { useParams, useNavigate } from 'react-router-dom';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://cvanalystiobackend.onrender.com';

function VerifyLogin() {
    const { setUser } = useUser();
    const { token } = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState(null);

    useEffect(() => {
        const verifyLogin = async () => {
            try {
                console.log("Verifying login with token:", token);
                const response = await fetch(`${BACKEND_API_URL}/verify-login/${token}`);
                const result = await response.json();
                console.log("Verification response:", result);

                if (response.ok) {  // Removed result.success check since it's not in the backend response
                    console.log("Login verified successfully");
                    setUser({ token: result.access_token });
                    localStorage.setItem('authToken', result.access_token);
                    localStorage.setItem('sessionToken', result.session_token);
                    console.log("Navigating to resume-enhancer");  // Changed from dashboard
                    navigate('/resume-enhancer');  // Changed from dashboard since you don't have a dashboard route
                } else {
                    console.error("Verification failed:", result.detail || 'Unknown error');
                    setError(result.detail || 'Verification failed');
                    setTimeout(() => {
                        console.log("Redirecting to login due to verification failure");
                        navigate('/signin');
                    }, 3000);
                }
            } catch (error) {
                console.error('Verification error:', error);
                setError('An unexpected error occurred');
                setTimeout(() => {
                    console.log("Redirecting to login due to unexpected error");
                    navigate('/signin');
                }, 3000);
            }
        };

        verifyLogin();
    }, [token, setUser, navigate]);

    return (
        <div style={{ textAlign: 'center', marginTop: '2rem' }}>
            {error ? (
                <div style={{ color: 'red' }}>
                    Error: {error}. Redirecting to login...
                </div>
            ) : (
                <div>Verifying login...</div>
            )}
        </div>
    );
}

export default VerifyLogin;
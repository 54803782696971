import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaUpload, FaSpinner, FaExclamationTriangle } from 'react-icons/fa';
import { BsArrowRight } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

const COLORS = {
  primary: {
    main: '#000000',
    light: '#1A1A1A',
    dark: '#000000'
  },
  text: {
    primary: '#000000',
    secondary: '#4A4A4A',
    light: '#717171',
    white: '#FFFFFF'
  },
  background: {
    primary: '#FFFFFF',
    secondary: '#F8F8F8',
    accent: '#F3F4F6'
  },
  border: '#E5E5E5'
};

const questions = [
  {
    id: 1,
    question: "What's your #1 goal with your job search?",
    options: [
      "Switch careers entirely",
      "Escape my current job ASAP",
      "Find a higher-paying role",
      "Get my first job in the field",
      "Advance to a senior-level position"
    ]
  },
  {
    id: 2,
    question: "How soon do you want a new job?",
    options: [
      "Immediately – I'm actively applying",
      "Within the next 30 days",
      "In 3–6 months",
      "I'm just exploring for now"
    ]
  },
  {
    id: 3,
    question: "What's been the most frustrating part of your job search?",
    options: [
      "Applying but never hearing back",
      "Not sure if my resume is ATS-friendly",
      "Finding jobs that actually fit me",
      "Balancing job hunting with my current work"
    ]
  },
  {
    id: 4,
    question: "Did you know 75% of resumes get filtered out by ATS software?",
    options: ["Yes", "No"]
  },
  {
    id: 5,
    question: "How confident are you that your resume passes ATS scans?",
    options: [
      "Very confident",
      "Not sure – I've never checked",
      "Probably not – I need help"
    ]
  },
  {
    id: 6,
    question: "If you had an ATS-friendly resume, what outcome would you want most?",
    options: [
      "Land more interviews",
      "Secure my dream company",
      "Negotiate a higher salary",
      "Break into a new industry"
    ]
  }
];

const Register = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [answers, setAnswers] = useState({});
  const [showOptions, setShowOptions] = useState(false);
  const [showATSForm, setShowATSForm] = useState(false);
  const [currentTitle, setCurrentTitle] = useState('');
  const [experience, setExperience] = useState('');
  const [targetTitle, setTargetTitle] = useState('');
  const navigate = useNavigate();

  const handleAnswer = (questionId, answer) => {
    setAnswers({ ...answers, [questionId]: answer });
    if (questionId === 6) {
      setShowOptions(true);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleOptionSelect = (option) => {
    if (option === 'ats') {
      setShowATSForm(true);
      setShowOptions(false);
    } else if (option === 'free') {
      navigate('/free-resume-optimization');
    }
  };

  const handleATSSubmit = (e) => {
    e.preventDefault();
    
    // Store context in localStorage
    const atsContext = {
      currentTitle,
      experience,
      targetTitle,
      surveyAnswers: answers
    };
    localStorage.setItem('atsContext', JSON.stringify(atsContext));
    
    // Redirect to ATS scan
    navigate('/ats-scan');
  };

  return (
    <Container>
      {!showOptions && !showATSForm && currentStep < questions.length && (
        <>
          <Title>{questions[currentStep].question}</Title>
          <OptionsContainer>
            {questions[currentStep].options.map((option, index) => (
              <OptionButton
                key={index}
                onClick={() => handleAnswer(questions[currentStep].id, option)}
              >
                {option}
              </OptionButton>
            ))}
          </OptionsContainer>
        </>
      )}

      {showOptions && !showATSForm && (
        <OptionsSection>
          <Title>Choose Your Path</Title>
          <OptionCard onClick={() => handleOptionSelect('ats')}>
            <OptionTitle>ATS Resume Scanner</OptionTitle>
            <OptionDescription>
              Get a detailed analysis of your resume's ATS compatibility and receive personalized recommendations.
            </OptionDescription>
          </OptionCard>
          <OptionCard onClick={() => handleOptionSelect('free')}>
            <OptionTitle>Free Resume Enhancement</OptionTitle>
            <OptionDescription>
              Get a free AI-powered resume optimization based on a specific job posting.
            </OptionDescription>
          </OptionCard>
        </OptionsSection>
      )}

      {showATSForm && (
        <>
          <Title>Let's personalize your ATS analysis</Title>
          <Form onSubmit={handleATSSubmit}>
            <FormGroup>
              <Label>What is your current job title?</Label>
              <Input
                type="text"
                value={currentTitle}
                onChange={(e) => setCurrentTitle(e.target.value)}
                required
                placeholder="e.g. Software Engineer"
              />
            </FormGroup>

            <FormGroup>
              <Label>How many years of work experience do you have?</Label>
              <Input
                type="text"
                value={experience}
                onChange={(e) => setExperience(e.target.value)}
                required
                placeholder="e.g. 5 years"
              />
            </FormGroup>

            <FormGroup>
              <Label>What job title are you targeting?</Label>
              <Input
                type="text"
                value={targetTitle}
                onChange={(e) => setTargetTitle(e.target.value)}
                required
                placeholder="e.g. Senior Software Engineer"
              />
            </FormGroup>

            <SubmitButton type="submit">
              Continue to ATS Scan →
            </SubmitButton>
          </Form>
        </>
      )}
    </Container>
  );
};

// Animation keyframes
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled components
const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  animation: ${fadeIn} 0.5s ease-out;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #1a1a1a;
  text-align: center;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 600px;
  margin: 0 auto;
`;

const OptionButton = styled.button`
  padding: 1rem;
  background-color: ${COLORS.background.secondary};
  border: 1px solid ${COLORS.border};
  border-radius: 8px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: ${COLORS.primary.main};
    color: ${COLORS.text.white};
    transform: translateY(-2px);
  }
`;

const OptionsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 600px;
  margin: 0 auto;
`;

const OptionCard = styled.div`
  padding: 2rem;
  background-color: ${COLORS.background.secondary};
  border: 1px solid ${COLORS.border};
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
`;

const OptionTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: ${COLORS.text.primary};
`;

const OptionDescription = styled.p`
  font-size: 1.1rem;
  color: ${COLORS.text.secondary};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 500px;
  margin: 0 auto;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 0.5rem;
`;

const Input = styled.input`
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 1rem;
  background-color: ${COLORS.primary.main};
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: ${COLORS.primary.light};
    transform: translateY(-2px);
  }
`;

export default Register;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';

function DevLogin() {
    const navigate = useNavigate();
    const { setUser } = useUser();
    
    // Get the current hostname
    const currentHost = window.location.hostname;
    // If accessing from network IP, use the same IP for backend, otherwise use localhost
    const BACKEND_URL = currentHost === 'localhost' 
        ? 'http://localhost:8000' 
        : `http://${currentHost}:8000`;

    useEffect(() => {
        const devLogin = async () => {
            try {
                console.log('Attempting dev login with backend URL:', BACKEND_URL);
                const response = await fetch(`${BACKEND_URL}/dev-login/1`);
                const data = await response.json();

                if (response.ok) {
                    // Store tokens
                    localStorage.setItem('authToken', data.access_token);
                    localStorage.setItem('sessionToken', data.session_token);
                    
                    // Update user context
                    setUser({ token: data.access_token });
                    
                    // Navigate to dashboard
                    navigate('/');
                } else {
                    console.error('Dev login failed:', data);
                }
            } catch (error) {
                console.error('Dev login error:', error);
            }
        };

        devLogin();
    }, [navigate, setUser, BACKEND_URL]);

    return (
        <div style={{ 
            padding: '20px', 
            textAlign: 'center',
            marginTop: '50px'
        }}>
            <h2>Logging in for development...</h2>
            <p>Backend URL: {BACKEND_URL}</p>
        </div>
    );
}

export default DevLogin; 
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from "./components/Header";
import Layout from './components/Layout';
import SignIn from "./components/SignIn";
import VerifyLogin from "./components/VerifyLogin";
import Home from './components/Home';
import VerifyRegister from "./components/VerifyRegister";
import { UserProvider } from "./components/UserContext";
import ResumeEnhancer from './components/ResumeEnhancer';
import CoverLetterGenerator from './components/CoverLetterGenerator';
import SearchJobs from './components/SearchJobs';
import LinkedinJobID from './components/LinkedinJobID';
import Poof from './components/Poof';
import Profile from './components/Profile';
import Billing from './components/Billing'; 
import InterviewEnhancer from './components/InterviewEnhancer';
import QuestionGenerator from './components/QuestionGenerator';
import PreInterviewAssistant from './components/PreInterviewAssistant';
import Features from './components/Features';
import JobSearch from './components/JobSearch'; 



function App() {
  const [filters, setFilters] = useState({});

  const handleSearch = (filterData) => {
    setFilters(filterData);
  };

  return (
    <Router>
      <UserProvider>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/verify-login/:token" element={<VerifyLogin />} />
            <Route path="/verify-email/:token" element={<VerifyRegister />} />
            <Route path="/poof" element={<Poof />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/resume-enhancer" element={<ResumeEnhancer />} />
            <Route path="/cover-letter-generator" element={<CoverLetterGenerator />} />
            <Route path="/search-jobs" element={<SearchJobs />} />
            <Route path="/linkedin-job/:jobId" element={<LinkedinJobID />} />
            <Route path="/linkedin-job-id" element={<LinkedinJobID />} />
            <Route path="/billing" element={<Billing />} /> 
            <Route path="/interview-enhancer" element={<InterviewEnhancer/>} /> 
            <Route path="/question-generator" element={<QuestionGenerator/>} /> 
            <Route path="/pre-interview-assistant" element={<PreInterviewAssistant />} />
            <Route path="/features" element={<Features />} />
            <Route path="/job-search-blueprint" element={<JobSearch />} />  {/* Add this route */}
          </Routes>
        </Layout>
      </UserProvider>
    </Router>
  );
}

export default App;
import React from 'react';
import styled from 'styled-components';
import { FaDownload, FaBook } from 'react-icons/fa';
import axios from 'axios';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 3rem;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #000;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  color: #666;
  max-width: 800px;
  margin: 0 auto;
`;

const ContentSection = styled.div`
  margin-bottom: 3rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  color: #000;
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const FeatureItem = styled.li`
  padding: 1.5rem;
  border: 1px solid #eaeaea;
  border-radius: 8px;
`;

const FeatureTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #000;
`;

const FeatureDescription = styled.p`
  color: #666;
  line-height: 1.6;
`;

const DownloadButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: #000;
  color: #fff;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border-radius: 4px;
  cursor: pointer;
  margin: 2rem auto;
  transition: background-color 0.2s;

  &:hover {
    background: #333;
  }
`;

const JobSearch = () => {
  const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://cvanalystiobackend.onrender.com';
  
  const handleDownload = async () => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/download-blueprint`, 
        {
          responseType: 'blob'
          // Removed authorization header since it's not needed
        }
      );
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'The Job Search Blueprint.docx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading blueprint:', error);
    }
  };

  return (
    <Container>
      <Header>
        <Title>The Ultimate Job Search Blueprint</Title>
        <Subtitle>
          A comprehensive guide to modern job searching, powered by AI and industry best practices
        </Subtitle>
      </Header>

      <ContentSection>
        <SectionTitle>What's Inside</SectionTitle>
        <FeatureList>
          <FeatureItem>
            <FeatureTitle>Strategic Job Search</FeatureTitle>
            <FeatureDescription>
              Learn how to leverage LinkedIn and job boards effectively with our AI-powered tools
            </FeatureDescription>
          </FeatureItem>
          <FeatureItem>
            <FeatureTitle>Resume Optimization</FeatureTitle>
            <FeatureDescription>
              Discover how to tailor your resume for ATS systems and human recruiters
            </FeatureDescription>
          </FeatureItem>
          <FeatureItem>
            <FeatureTitle>Interview Preparation</FeatureTitle>
            <FeatureDescription>
              Master the STAR method and prepare compelling responses with AI assistance
            </FeatureDescription>
          </FeatureItem>
          <FeatureItem>
            <FeatureTitle>Company Research</FeatureTitle>
            <FeatureDescription>
              Learn how to research companies effectively and make informed decisions
            </FeatureDescription>
          </FeatureItem>
        </FeatureList>
      </ContentSection>

      <DownloadButton onClick={handleDownload}>
        <FaDownload /> Download Blueprint
      </DownloadButton>
    </Container>
  );
};

export default JobSearch;
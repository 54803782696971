import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const QuestionGenerator = ({ apiKeyApp }) => {
  const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://cvanalystiobackend.onrender.com';
  const [jobDescription, setJobDescription] = useState('');
  const [companyInfo, setCompanyInfo] = useState('');
  const [uploadedResume, setUploadedResume] = useState(null);
  const [generatedQuestions, setGeneratedQuestions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isPersonalizedMode, setIsPersonalizedMode] = useState(false);
  const [inputKey, setInputKey] = useState(Date.now());

  const handleResumeUpload = (event) => {
    const file = event.target.files?.[0];
    if (file && file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setUploadedResume(file);
      console.log('Upload successful');
    } else {
      setError('Please upload a DOCX file');
      console.log('Upload failed: The file is not a DOCX file');
    }
  };

  const handleGenerateQuestions = async () => {
    if (!jobDescription.trim()) {
      setError('Please provide the job description');
      return;
    }

    if (isPersonalizedMode && !uploadedResume) {
      setError('Please upload your resume for personalized questions');
      return;
    }
    
    setLoading(true);
    setError(null);

    try {
      let endpoint = isPersonalizedMode ? 
        `${BACKEND_API_URL}/generate_personalized_questions` : 
        `${BACKEND_API_URL}/generate_general_questions`;

      let requestData;
      if (isPersonalizedMode) {
        const formData = new FormData();
        formData.append('resume', uploadedResume);
        formData.append('job_description', cleanText(jobDescription));
        formData.append('company_info', cleanText(companyInfo));
        
        requestData = formData;
      } else {
        requestData = {
          job_description: cleanText(jobDescription),
          company_info: cleanText(companyInfo)
        };
      }

      const headers = isPersonalizedMode ? 
        { 'Content-Type': 'multipart/form-data' } : 
        { 'Content-Type': 'application/json' };

      const response = await axios.post(endpoint, requestData, { headers });

      if (response.data) {
        setGeneratedQuestions(response.data.questions);
      }
    } catch (error) {
      console.error('Error generating questions:', error);
      setError('Failed to generate interview questions. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const cleanText = (text) => {
    if (!text) return '';
    return text
      .replace(/[\u2018\u2019]/g, "'")
      .replace(/[\u201C\u201D]/g, '"')
      .replace(/[\u2013\u2014]/g, '-')
      .replace(/[\u2026]/g, '...')
      .replace(/[^\x00-\x7F]/g, '')
      .replace(/\s+/g, ' ')
      .trim();
  };

  return (
    <Container>
      <ContentWrapper>
        <Title>Questions to Ask Your Interviewer</Title>
        <Subtitle>Stand out by asking thoughtful, strategic questions that demonstrate your research and interest</Subtitle>
        
        <Card>
          <ModeToggle>
            <ModeButton 
              active={!isPersonalizedMode} 
              onClick={() => setIsPersonalizedMode(false)}
            >
              General Mode
            </ModeButton>
            <ModeButton 
              active={isPersonalizedMode} 
              onClick={() => setIsPersonalizedMode(true)}
            >
              Personalized Mode
            </ModeButton>
          </ModeToggle>

          {isPersonalizedMode && (
            <Section>
              <SectionTitle>Upload Your Resume</SectionTitle>
              <FileUploadContainer>
                <FileInput
                  key={inputKey}
                  type="file"
                  accept=".docx"
                  onChange={handleResumeUpload}
                  id="resume-upload"
                />
                <FileLabel htmlFor="resume-upload">
                  {uploadedResume ? uploadedResume.name : 'Choose .docx file'}
                </FileLabel>
                {uploadedResume && (
                  <TextButton onClick={() => {
                    setUploadedResume(null);
                    setInputKey(Date.now());
                  }}>
                    Remove
                  </TextButton>
                )}
              </FileUploadContainer>
            </Section>
          )}

          <Section>
            <SectionTitle>Job Description</SectionTitle>
            <StyledTextarea
              placeholder="Paste the job description here..."
              onChange={(e) => setJobDescription(e.target.value)}
              required
            />
          </Section>

          <Section>
            <SectionTitle>Company Information (Optional)</SectionTitle>
            <StyledTextarea
              placeholder="Add any company information to generate more specific questions..."
              onChange={(e) => setCompanyInfo(e.target.value)}
            />
          </Section>

          <ActionButton onClick={handleGenerateQuestions} disabled={loading}>
            {loading ? 'Generating...' : 'Generate Strategic Questions'}
          </ActionButton>
          
          {error && <ErrorText>{error}</ErrorText>}
        </Card>

        {generatedQuestions && (
          <Card>
            <SectionTitle>Questions to Ask Your Interviewer</SectionTitle>
            <QuestionsContainer>
              {Object.entries(generatedQuestions).map(([category, questions]) => (
                <CategorySection key={category}>
                  <CategoryTitle>{category}</CategoryTitle>
                  <QuestionList>
                    {questions.map((question, index) => (
                      <QuestionItem key={index}>
                        <QuestionText>{question.question}</QuestionText>
                        {question.explanation && (
                          <QuestionContext>Why ask this: {question.explanation}</QuestionContext>
                        )}
                      </QuestionItem>
                    ))}
                  </QuestionList>
                </CategorySection>
              ))}
            </QuestionsContainer>
          </Card>
        )}
      </ContentWrapper>
    </Container>
  );
};

// Previous styled components remain the same, adding new ones:

const Subtitle = styled.p`
  text-align: center;
  color: #666;
  margin-bottom: 2rem;
  font-size: 1.1rem;
`;

const ModeToggle = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  padding: 0.5rem;
  background: #F5F5F5;
  border-radius: 4px;
`;

const ModeButton = styled.button`
  flex: 1;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  background-color: ${props => props.active ? '#000' : 'transparent'};
  color: ${props => props.active ? '#FFF' : '#666'};
  transition: all 0.2s;

  &:hover {
    background-color: ${props => props.active ? '#000' : '#EAEAEA'};
  }
`;

const FileUploadContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const FileInput = styled.input`
  display: none;
`;

const FileLabel = styled.label`
  padding: 0.75rem 1rem;
  background-color: #F5F5F5;
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.2s;

  &:hover {
    background-color: #EAEAEA;
  }
`;

// Styled components (reusing most from InterviewEnhancer)
const Container = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const ContentWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 2rem;
  text-align: center;
`;

const Card = styled.div`
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  border-radius: 8px;
  padding: 2rem;
  margin-bottom: 2rem;
`;

const Section = styled.div`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 1rem;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  font-size: 0.9rem;
  min-height: 100px;
  resize: vertical;
  transition: border-color 0.2s;

  &:focus {
    outline: none;
    border-color: #000;
  }
`;

const ActionButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  background-color: #000;
  color: #FFF;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #333;
  }

  &:disabled {
    background-color: #CCC;
    cursor: not-allowed;
  }
`;

const ErrorText = styled.p`
  color: #DC2626;
  font-size: 0.9rem;
  margin-top: 1rem;
`;

// New styled components for questions display
const QuestionsContainer = styled.div`
  margin-top: 1rem;
`;

const CategorySection = styled.div`
  margin-bottom: 2rem;
`;

const CategoryTitle = styled.h3`
  font-size: 1.1rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #EAEAEA;
`;

const QuestionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const QuestionItem = styled.div`
  padding: 1rem;
  background-color: #F5F5F5;
  border-radius: 4px;
`;

const QuestionText = styled.p`
  font-size: 1rem;
  color: #000;
  margin-bottom: 0.5rem;
`;

const QuestionContext = styled.p`
  font-size: 0.9rem;
  color: #666;
  margin-top: 0.5rem;
  font-style: italic;
`;

const TextButton = styled.button`
  background: none;
  border: none;
  color: #666;
  font-size: 0.9rem;
  cursor: pointer;
  padding: 0;
  text-decoration: underline;

  &:hover {
    color: #000;
  }
`;


export default QuestionGenerator;
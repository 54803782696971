import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const InterviewEnhancer = ({ apiKeyApp }) => {
  const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://cvanalystiobackend.onrender.com';
  const [uploadedResume, setUploadedResume] = useState(null);
  const [jobDescription, setJobDescription] = useState('');
  const [behavioralQuestions, setBehavioralQuestions] = useState('');
  const [generatedResponses, setGeneratedResponses] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [inputKey, setInputKey] = useState(Date.now());

  const handleResumeUpload = (event) => {
    const file = event.target.files?.[0];
    if (file && file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setUploadedResume(file);
      console.log('Upload successful');
    } else {
      setError('Please upload a DOCX file');
      console.log('Upload failed: The file is not a DOCX file');
    }
  };

  const handleGenerateResponses = async () => {
    if (!uploadedResume) {
      setError('Please upload your resume');
      return;
    }
    if (!jobDescription.trim()) {
      setError('Please provide the job description');
      return;
    }
    
    setLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append('resume', uploadedResume);
    formData.append('job_description', cleanText(jobDescription));
    formData.append('behavioral_questions', cleanText(behavioralQuestions));

    const accessToken = localStorage.getItem('authToken');
    try {
      const response = await axios.post(`${BACKEND_API_URL}/generate_interview_responses`, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (response.data) {
        setGeneratedResponses(response.data.responses);
      }
    } catch (error) {
      console.error('Error generating responses:', error);
      setError('Failed to generate interview responses. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const cleanText = (text) => {
    if (!text) return '';
    return text
      .replace(/[\u2018\u2019]/g, "'") // Smart quotes to regular quotes
      .replace(/[\u201C\u201D]/g, '"') // Smart double quotes to regular quotes
      .replace(/[\u2013\u2014]/g, '-') // Em/En dashes to hyphens
      .replace(/[\u2026]/g, '...') // Ellipsis to dots
      .replace(/[^\x00-\x7F]/g, '') // Remove non-ASCII characters
      .replace(/\s+/g, ' ') // Replace multiple spaces with single space
      .trim();
  };


  return (
    <Container>
      <ContentWrapper>
        <Title>Interview Response Generator</Title>
        <Card>
          <Section>
            <SectionTitle>Upload Resume</SectionTitle>
            <FileUploadContainer>
              <FileInput
                key={inputKey}
                type="file"
                accept=".docx"
                onChange={handleResumeUpload}
                id="resume-upload"
              />
              <FileLabel htmlFor="resume-upload">
                {uploadedResume ? uploadedResume.name : 'Choose .docx file'}
              </FileLabel>
            </FileUploadContainer>
          </Section>

          <Section>
            <SectionTitle>Job Description</SectionTitle>
            <StyledTextarea
              placeholder="Paste the job description here..."
              onChange={(e) => setJobDescription(e.target.value)}
              required
            />
          </Section>

          <Section>
            <SectionTitle>Behavioral Questions (Optional)</SectionTitle>
            <StyledTextarea
              placeholder="Enter specific behavioral questions you'd like to prepare for..."
              onChange={(e) => setBehavioralQuestions(e.target.value)}
            />
          </Section>

          <ActionButton onClick={handleGenerateResponses} disabled={loading}>
            {loading ? 'Generating...' : 'Generate Interview Responses'}
          </ActionButton>
          
          {error && <ErrorText>{error}</ErrorText>}
        </Card>

        {generatedResponses && (
          <Card>
            <SectionTitle>Generated STAR Responses</SectionTitle>
            <ResponsesContainer>
              {Object.entries(generatedResponses).map(([question, response]) => (
                <ResponseSection key={question}>
                  <QuestionText>{question}</QuestionText>
                  <ResponseText>{response}</ResponseText>
                </ResponseSection>
              ))}
            </ResponsesContainer>
          </Card>
        )}
      </ContentWrapper>
    </Container>
  );
};


const Container = styled.div`
  min-height: calc(100vh - 64px);
  background-color: #F5F5F5;
  padding: 2rem;
`;

const ContentWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 2rem;
  text-align: center;
`;

const Card = styled.div`
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  border-radius: 8px;
  padding: 2rem;
  margin-bottom: 2rem;
`;

const Section = styled.div`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 1rem;
`;


const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  font-size: 0.9rem;
  min-height: 100px;
  resize: vertical;
  transition: border-color 0.2s;

  &:focus {
    outline: none;
    border-color: #000;
  }
`;

const FileUploadContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const FileInput = styled.input`
  display: none;
`;

const FileLabel = styled.label`
  padding: 0.75rem 1rem;
  background-color: #F5F5F5;
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.2s;

  &:hover {
    background-color: #EAEAEA;
  }
`;


const ActionButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  background-color: #000;
  color: #FFF;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #333;
  }

  &:disabled {
    background-color: #CCC;
    cursor: not-allowed;
  }
`;

const ErrorText = styled.p`
  color: #DC2626;
  font-size: 0.9rem;
  margin-top: 1rem;
`;



const ResponsesContainer = styled.div`
  margin-top: 1rem;
`;

const ResponseSection = styled.div`
  margin-bottom: 2rem;
  padding: 1rem;
  background-color: #F5F5F5;
  border-radius: 4px;
`;

const QuestionText = styled.h3`
  font-size: 1rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 0.5rem;
`;

const ResponseText = styled.div`
  font-size: 0.9rem;
  white-space: pre-wrap;
  color: #333;
`;

export default InterviewEnhancer;
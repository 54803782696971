import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import styled from 'styled-components';
import axios from 'axios';

const BACKEND_API_URL = 'https://cvanalystiobackend.onrender.com';

const COLORS = {
  primary: {
    main: '#000000',
    light: '#1A1A1A',
    dark: '#000000'
  },
  text: {
    primary: '#000000',
    secondary: '#4A4A4A',
    light: '#717171',
    white: '#FFFFFF'
  },
  background: {
    primary: '#FFFFFF',
    secondary: '#F8F8F8',
    accent: '#F3F4F6'
  },
  border: '#E5E5E5'
};

const HiringManager = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [searchesRemaining, setSearchesRemaining] = useState(25);
  const [emailUnlockCredits, setEmailUnlockCredits] = useState(0);
  const [searchParams, setSearchParams] = useState({
    title: '',
    location: '',
    timeframe: '7d'
  });
  const [page, setPage] = useState(1);
  const [resumeData, setResumeData] = useState(null);
  const [coverLetterData, setCoverLetterData] = useState(null);
  const [showResumeModal, setShowResumeModal] = useState(false);
  const [showCoverLetterModal, setShowCoverLetterModal] = useState(false);
  const [documentLoading, setDocumentLoading] = useState(false);

  useEffect(() => {
    if (!user) {
      navigate('/signin');
    } else {
      // Check email unlock credits and search limit
      checkEmailUnlockCredits();
      checkProSearchLimit();

      // Set up periodic checks every 5 minutes
      const intervalId = setInterval(() => {
        checkEmailUnlockCredits();
        checkProSearchLimit();
      }, 5 * 60 * 1000);

      // Cleanup interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [user, navigate]);

  const checkEmailUnlockCredits = async () => {
    try {
      const accessToken = localStorage.getItem('authToken');
      const response = await axios.get(
        `${BACKEND_API_URL}/check_email_unlock_credits`,
        { 
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      if (response.data && response.data.credits_remaining !== undefined) {
        setEmailUnlockCredits(response.data.credits_remaining);
      }
    } catch (error) {
      console.error('Error checking email unlock credits:', error);
      // Set a default value if the API call fails
      setEmailUnlockCredits(0);
    }
  };

  const checkProSearchLimit = async () => {
    try {
      const accessToken = localStorage.getItem('authToken');
      const response = await axios.post(
        `${BACKEND_API_URL}/check_pro_search_limit`,
        {},
        { 
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      if (response.data && response.data.remaining !== undefined) {
        setSearchesRemaining(response.data.remaining);
      }
    } catch (error) {
      console.error('Error checking pro search limit:', error);
      // Set a default value if the API call fails
      setSearchesRemaining(25);
    }
  };

  const handleSearchParamsChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSearchParams(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSearch = async (e, isLoadMore = false) => {
    if (e) e.preventDefault();
    
    if (!isLoadMore) {
      setOffset(0);
      setJobs([]);
      setHasMore(true);
    }
    
    setLoading(true);
    setError('');

    try {
      const accessToken = localStorage.getItem('authToken');
      
      // Only check search limit for new searches, not when loading more results
      if (!isLoadMore) {
        // Check if search is allowed for pro users
        const checkResponse = await axios.post(
          `${BACKEND_API_URL}/check_pro_search_limit`,
          {},
          { 
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        );
        
        if (!checkResponse.data.allowed) {
          setError('You have reached your daily search limit of 25 searches. Please try again tomorrow.');
          setLoading(false);
          return;
        }
        
        // Update remaining searches
        setSearchesRemaining(checkResponse.data.remaining);
      }
      
      const queryParams = new URLSearchParams({
        title: searchParams.title,
        location: searchParams.location,
        offset: isLoadMore ? offset : 0
      });

      const response = await axios.get(
        `${BACKEND_API_URL}/jobs/${searchParams.timeframe}?${queryParams}`,
        { 
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      // Handle direct array response
      const jobsData = response.data;
      if (Array.isArray(jobsData)) {
        const transformedJobs = jobsData.map(job => {
          // Format the date to MM/DD/YYYY
          let formattedDate = job.date_posted;
          if (formattedDate && formattedDate.startsWith('202')) {
            try {
              const dateObj = new Date(formattedDate);
              if (!isNaN(dateObj.getTime())) {
                // Format as MM/DD/YYYY
                formattedDate = `${(dateObj.getMonth() + 1).toString().padStart(2, '0')}/${dateObj.getDate().toString().padStart(2, '0')}/${dateObj.getFullYear()}`;
              }
            } catch (e) {
              console.error('Error formatting date:', e);
            }
          }
          
          return {
            id: job.id,
            title: job.title,
            company: job.organization,
            location: job.locations_derived ? job.locations_derived[0] : 'Remote',
            url: job.url,
            date_posted: formattedDate,
            hiring_manager: {
              name: job.li_hiring_manager_name || job.ai_hiring_manager_name || 'Unknown',
              title: job.li_hiring_manager_title || '',
              linkedin_url: job.li_hiring_manager_url || '',
              email: job.ai_hiring_manager_email_address,
              has_email: job.li_hiring_manager_url ? true : false
            }
          };
        });

        if (isLoadMore) {
          setJobs(prev => [...prev, ...transformedJobs]);
        } else {
          setJobs(transformedJobs);
        }

        // If we got less than 20 results, there are no more results to load
        setHasMore(transformedJobs.length === 20);
        setOffset(prev => isLoadMore ? prev + 20 : 20);
      } else {
        setError('Invalid response format from server');
      }
    } catch (err) {
      setError(err.response?.data?.detail || 'Failed to search jobs');
    } finally {
      setLoading(false);
    }
  };

  const handleLoadMore = () => {
    handleSearch(null, true);
  };

  const handleUnlockEmail = async (linkedinUrl) => {
    if (!user) {
      // Redirect to sign in page
      navigate('/signin');
      return;
    }

    setLoading(true);
    setError('');

    try {
      // Check if user has enough credits
      if (emailUnlockCredits <= 0) {
        // Redirect to payment page if no credits
        window.location.href = 'https://analyticpinnacle.thrivecart.com/cv-analyst/';
        setLoading(false);
        return;
      }

      const accessToken = localStorage.getItem('authToken');
      
      // Make API request to unlock email
      const response = await axios.post(
        `${BACKEND_API_URL}/unlock-email`,
        { linkedin_url: linkedinUrl },
        { 
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (response.data && response.data.success) {
        const data = response.data.data;
        
        // Update the job with the email
        const updatedJobs = jobs.map(job => {
          if (job.hiring_manager && job.hiring_manager.linkedin_url === linkedinUrl) {
            return {
              ...job,
              hiring_manager: {
                ...job.hiring_manager,
                email: data.email,
                email_unlocked: true
              }
            };
          }
          return job;
        });
        
        setJobs(updatedJobs);
        
        // Update credits
        setEmailUnlockCredits(data.credits_remaining);
        
        // Show success message
        alert(`Email unlocked successfully! You have ${data.credits_remaining} email unlock credits remaining this month.`);
      }
    } catch (err) {
      console.error('Error unlocking email:', err);
      setError(err.response?.data?.detail || 'Failed to unlock email');
      
      // If no credits, redirect to payment page
      if (err.response?.status === 403) {
        window.location.href = 'https://analyticpinnacle.thrivecart.com/cv-analyst/';
      }
    } finally {
      setLoading(false);
    }
  };

  const handleResumeEnhancement = async () => {
    if (!selectedJob) return;
    setLoading(true);
    setError('');

    try {
      const accessToken = localStorage.getItem('authToken');
      if (!accessToken) {
        setError('Please login to use this feature');
        return;
      }

      const response = await axios.post(
        `${BACKEND_API_URL}/enhance_resume_from_profile`,
        {
          job_title: selectedJob.title,
          job_description: selectedJob.description,
          company_name: selectedJob.company
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          withCredentials: true
        }
      );

      if (response.data && response.data.file) {
        downloadDocument(response.data.file, 'enhanced_resume.docx');
      } else {
        throw new Error('No file data received from server');
      }
    } catch (err) {
      handleDocumentError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleCoverLetterGeneration = async () => {
    if (!selectedJob) return;
    setLoading(true);
    setError('');

    try {
      const accessToken = localStorage.getItem('authToken');
      if (!accessToken) {
        setError('Please login to use this feature');
        return;
      }

      const response = await axios.post(
        `${BACKEND_API_URL}/generate_cover_letter_from_profile`,
        {
          job_title: selectedJob.title,
          job_description: selectedJob.description,
          company_name: selectedJob.company,
          hiring_manager_name: selectedJob.hiring_manager?.name || ''
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          withCredentials: true
        }
      );

      if (response.data && response.data.file) {
        downloadDocument(response.data.file, 'cover_letter.docx');
      } else {
        throw new Error('No file data received from server');
      }
    } catch (err) {
      handleDocumentError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleBothDocuments = async () => {
    if (!selectedJob) return;
    setLoading(true);
    setError('');

    try {
      const accessToken = localStorage.getItem('authToken');
      if (!accessToken) {
        setError('Please login to use this feature');
        return;
      }

      // Generate resume
      const resumeResponse = await axios.post(
        `${BACKEND_API_URL}/enhance_resume_from_profile`,
        {
          job_title: selectedJob.title,
          job_description: selectedJob.description,
          company_name: selectedJob.company
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          withCredentials: true
        }
      );

      // Generate cover letter
      const coverLetterResponse = await axios.post(
        `${BACKEND_API_URL}/generate_cover_letter_from_profile`,
        {
          job_title: selectedJob.title,
          job_description: selectedJob.description,
          company_name: selectedJob.company,
          hiring_manager_name: selectedJob.hiring_manager?.name || ''
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          withCredentials: true
        }
      );

      if (resumeResponse.data?.file && coverLetterResponse.data?.file) {
        downloadDocument(resumeResponse.data.file, 'enhanced_resume.docx');
        downloadDocument(coverLetterResponse.data.file, 'cover_letter.docx');
      } else {
        throw new Error('No file data received from server');
      }
    } catch (err) {
      handleDocumentError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleCompletePackage = async (job) => {
    // Store job info in localStorage
    localStorage.setItem('selectedJob', JSON.stringify({
      id: job.id,
      title: job.title,
      company: job.company,
      description: job.description,
      url: job.url,
      hiring_manager: job.hiring_manager
    }));

    // Redirect to HiringResume with job URL
    navigate(`/hiring-resume?jobUrl=${encodeURIComponent(job.url)}`);
  };

  const downloadDocument = (fileData, fileName) => {
    const binaryData = atob(fileData);
    const byteNumbers = new Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      byteNumbers[i] = binaryData.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { 
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' 
    });
    
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const handleDocumentError = (err) => {
    console.error('Error generating document:', err);
    if (err.response?.status === 401) {
      setError('Please login to use this feature');
    } else if (err.response?.status === 403) {
      setError('Your subscription is not active. Please check your subscription status.');
    } else if (err.response?.status === 500) {
      setError('Please login to use this feature');
    } else {
      setError(err.response?.data?.detail || 'Failed to generate document. Please ensure you have uploaded a resume in your profile.');
    }
  };

  return (
    <Container>
      <Header>
        <Title>Hiring Manager Finder</Title>
        <Description>
          Find hiring managers for any company and connect directly with decision-makers.
        </Description>
        <CreditsInfo>
          <CreditsItem>
            <CreditsLabel>Searches Remaining Today:</CreditsLabel>
            <CreditsValue>{searchesRemaining}</CreditsValue>
          </CreditsItem>
          <CreditsItem>
            <CreditsLabel>Email Unlocks Remaining This Month:</CreditsLabel>
            <CreditsValue>{emailUnlockCredits}</CreditsValue>
          </CreditsItem>
        </CreditsInfo>
      </Header>

      <SearchForm onSubmit={(e) => handleSearch(e)}>
        <FormGroup>
          <Label htmlFor="title">Job Title</Label>
          <Input
            type="text"
            id="title"
            name="title"
            value={searchParams.title}
            onChange={handleSearchParamsChange}
            placeholder="e.g. Software Engineer"
            required
          />
        </FormGroup>
        
        <FormGroup>
          <Label htmlFor="location">Location</Label>
          <Input
            type="text"
            id="location"
            name="location"
            value={searchParams.location}
            onChange={handleSearchParamsChange}
            placeholder="e.g. New York"
            required
          />
        </FormGroup>
        
        <FormGroup>
          <Label htmlFor="timeframe">Time Frame</Label>
          <Select
            id="timeframe"
            name="timeframe"
            value={searchParams.timeframe}
            onChange={handleSearchParamsChange}
          >
            <option value="day">Past 24 hours</option>
            <option value="week">Past Week</option>
            <option value="month">Past Month</option>
          </Select>
        </FormGroup>
        
        <SearchButton type="submit" disabled={loading}>
          {loading ? 'Searching...' : 'Find Hiring Managers'}
        </SearchButton>
      </SearchForm>
      
      <SearchStats>
        <div>Email Credits: <strong>{emailUnlockCredits}</strong></div>
        <div>Search Limit: <strong>{searchesRemaining}</strong></div>
      </SearchStats>
      
      {error && <ErrorMessage>{error}</ErrorMessage>}
      
      {loading && <LoadingMessage>Searching for hiring managers...</LoadingMessage>}
      
      <ResultsContainer>
        {jobs.map(job => (
          <JobCard key={job.id}>
            <JobTitle>
              <a href={job.url} target="_blank" rel="noopener noreferrer">
                {job.title}
              </a>
            </JobTitle>
            <JobLink href={job.url} target="_blank" rel="noopener noreferrer">
              View Job Posting
            </JobLink>
            <JobCompany>{job.company}</JobCompany>
            <JobLocation>{job.location}</JobLocation>
            <JobDate>Posted: {job.date_posted}</JobDate>
            
            <HiringManagerSection>
              <HiringManagerTitle>Hiring Manager</HiringManagerTitle>
              <HiringManagerInfo>
                <div>
                  <HiringManagerName>
                    {job.hiring_manager.name || 'Name not available'}
                  </HiringManagerName>
                  {job.hiring_manager.title && (
                    <HiringManagerPosition>
                      {job.hiring_manager.title}
                    </HiringManagerPosition>
                  )}
                </div>
                
                {job.hiring_manager.linkedin_url && (
                  <LinkedInButton href={job.hiring_manager.linkedin_url} target="_blank" rel="noopener noreferrer">
                    View LinkedIn Profile
                  </LinkedInButton>
                )}
              </HiringManagerInfo>
              
              {job.hiring_manager.linkedin_url && !job.hiring_manager.email_unlocked && (
                <UnlockButton 
                  onClick={() => handleUnlockEmail(job.hiring_manager.linkedin_url)}
                  disabled={loading || emailUnlockCredits <= 0}
                >
                  {loading ? 'Unlocking...' : `Unlock Email (${emailUnlockCredits} Credit${emailUnlockCredits !== 1 ? 's' : ''} Left)`}
                </UnlockButton>
              )}
              
              {job.hiring_manager.email_unlocked && (
                <div>
                  <HiringManagerTitle>Email</HiringManagerTitle>
                  <div style={{ 
                    padding: '0.75rem', 
                    background: '#F5F9FF', 
                    borderRadius: '4px',
                    marginBottom: '1rem',
                    wordBreak: 'break-all',
                    fontFamily: 'monospace',
                    fontSize: '0.9rem'
                  }}>
                    {job.hiring_manager.email}
                  </div>
                </div>
              )}
              
              <GenerateDocsButton onClick={() => handleCompletePackage(job)}>
                Generate Resume + Cover Letter + Outreach Email
              </GenerateDocsButton>
            </HiringManagerSection>
          </JobCard>
        ))}
      </ResultsContainer>

      {jobs.length > 0 && (
        <LoadMoreContainer>
          {hasMore ? (
            <LoadMoreButton onClick={handleLoadMore} disabled={loading}>
              {loading ? 'Loading...' : 'Load More Jobs'}
            </LoadMoreButton>
          ) : (
            <NoMoreResults>No more results</NoMoreResults>
          )}
        </LoadMoreContainer>
      )}

      {showResumeModal && resumeData && (
        <Modal>
          <ModalContent>
            <ModalHeader>
              <h3>Enhanced Resume</h3>
              <CloseButton onClick={() => setShowResumeModal(false)}>&times;</CloseButton>
            </ModalHeader>
            <DocumentPreview>
              <p>Your enhanced resume has been generated successfully!</p>
              <DownloadButton onClick={() => downloadDocument(resumeData, 'enhanced_resume.docx')}>
                Download Resume
              </DownloadButton>
            </DocumentPreview>
          </ModalContent>
        </Modal>
      )}
      
      {showCoverLetterModal && coverLetterData && (
        <Modal>
          <ModalContent>
            <ModalHeader>
              <h3>Cover Letter</h3>
              <CloseButton onClick={() => setShowCoverLetterModal(false)}>&times;</CloseButton>
            </ModalHeader>
            <DocumentPreview>
              <p>Your cover letter has been generated successfully!</p>
              <DownloadButton onClick={() => downloadDocument(coverLetterData, 'cover_letter.docx')}>
                Download Cover Letter
              </DownloadButton>
            </DocumentPreview>
          </ModalContent>
        </Modal>
      )}
    </Container>
  );
};

// Styled Components
const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background: ${COLORS.background.primary};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: ${COLORS.text.primary};
  font-weight: 700;
`;

const Description = styled.p`
  margin-bottom: 2rem;
  color: ${COLORS.text.secondary};
  line-height: 1.6;
  font-size: 1.1rem;
`;

const CreditsInfo = styled.div`
  display: flex;
  gap: 1rem;
`;

const CreditsItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const CreditsLabel = styled.span`
  font-weight: 600;
  color: ${COLORS.text.primary};
  margin-bottom: 0.5rem;
`;

const CreditsValue = styled.span`
  font-weight: 500;
  color: ${COLORS.text.secondary};
`;

const SearchForm = styled.form`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
  padding: 2rem;
  background: ${COLORS.background.secondary};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: ${COLORS.text.primary};
  font-size: 0.95rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 2px solid ${COLORS.border};
  border-radius: 4px;
  font-size: 1rem;
  transition: all 0.2s ease;
  
  &:focus {
    outline: none;
    border-color: ${COLORS.primary.main};
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: 2px solid ${COLORS.border};
  border-radius: 4px;
  font-size: 1rem;
  background: white;
  transition: all 0.2s ease;
  
  &:focus {
    outline: none;
    border-color: ${COLORS.primary.main};
  }
`;

const SearchButton = styled.button`
  grid-column: 1 / -1;
  background-color: ${COLORS.primary.main};
  color: ${COLORS.text.white};
  border: 2px solid ${COLORS.primary.main};
  padding: 1rem;
  border-radius: 4px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  &:disabled {
    background-color: ${COLORS.text.light};
    border-color: ${COLORS.text.light};
    cursor: not-allowed;
    transform: none;
  }
`;

const ResultsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;
`;

const JobCard = styled.div`
  background: #FFFFFF;
  border-radius: 12px;
  padding: 1.75rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  border: 1px solid #F0F0F0;
  display: flex;
  flex-direction: column;
  
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  }
`;

const JobTitle = styled.h3`
  margin: 0 0 0.5rem;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.3;
  
  a {
    color: #0055FF;
    text-decoration: none;
    transition: color 0.2s ease;
    
    &:hover {
      color: #0044CC;
      text-decoration: underline;
    }
  }
`;

const JobLink = styled.a`
  display: inline-block;
  color: #0066cc;
  text-decoration: none;
  margin-bottom: 1rem;
  font-weight: 500;
  font-size: 0.95rem;
  position: relative;
  padding-left: 1.2rem;
  
  &:before {
    content: "→";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.2s ease;
  }
  
  &:hover {
    text-decoration: underline;
    
    &:before {
      transform: translate(3px, -50%);
    }
  }
`;

const JobCompany = styled.div`
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
`;

const JobLocation = styled.div`
  color: #555;
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  
  &:before {
    content: "📍";
    margin-right: 0.5rem;
    font-size: 1rem;
  }
`;

const JobDate = styled.div`
  color: #666;
  margin-bottom: 1.25rem;
  font-size: 0.9rem;
  font-style: italic;
`;

const HiringManagerSection = styled.div`
  margin-top: 1rem;
  padding-top: 1.25rem;
  border-top: 1px solid #EEEEEE;
`;

const HiringManagerTitle = styled.h4`
  margin: 0 0 1rem;
  color: #333;
  font-size: 1.1rem;
  font-weight: 600;
`;

const HiringManagerInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
`;

const HiringManagerName = styled.div`
  font-weight: 600;
  margin-bottom: 0.25rem;
  color: #333;
`;

const HiringManagerPosition = styled.div`
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.75rem;
  line-height: 1.4;
`;

const LinkedInButton = styled.a`
  display: inline-flex;
  align-items: center;
  background-color: #0077B5;
  color: white;
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  text-decoration: none;
  font-size: 0.85rem;
  font-weight: 500;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: #005E93;
    transform: translateY(-2px);
  }
  
  &:before {
    content: "in";
    font-weight: bold;
    margin-right: 0.5rem;
    font-size: 1rem;
  }
`;

const UnlockButton = styled.button`
  width: 100%;
  background-color: #000000;
  color: white;
  border: none;
  padding: 0.9rem 1.5rem;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 0.5rem;
  font-size: 1rem;
  
  &:hover {
    background-color: #333333;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  &:disabled {
    background-color: #CCCCCC;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const GenerateDocsButton = styled(UnlockButton)`
  background-color: #0055FF;
  margin-top: 1rem;
  
  &:hover {
    background-color: #0044CC;
  }
`;

const EmailContainer = styled.div`
  margin-top: 1rem;
`;

const EmailValue = styled.div`
  padding: 0.5rem;
  background: #f8f9fa;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  word-break: break-all;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: ${COLORS.background.primary};
  border-radius: 8px;
  padding: 2.5rem;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  color: #666;
  
  &:hover {
    color: #333;
  }
`;

const DocumentPreview = styled.div`
  background: ${COLORS.background.secondary};
  border-radius: 8px;
  padding: 1.5rem;
  margin-top: 1.5rem;
  border: 2px solid ${COLORS.border};
`;

const DownloadButton = styled.button`
  width: 100%;
  background-color: #0066cc;
  color: white;
  border: none;
  padding: 0.75rem;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: #0055aa;
  }
`;

const ErrorMessage = styled.div`
  color: #dc3545;
  padding: 1rem;
  background-color: #f8d7da;
  border-radius: 4px;
  margin-bottom: 1.5rem;
  font-weight: 500;
`;

const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  padding: 1rem;
`;

const LoadMoreButton = styled(SearchButton)`
  min-width: 200px;
  margin: 0 auto;
`;

const NoMoreResults = styled.div`
  text-align: center;
  color: ${COLORS.text.secondary};
  font-size: 1.1rem;
  padding: 1rem;
`;

const SearchStats = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding: 1rem;
  background: ${COLORS.background.secondary};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const LoadingMessage = styled.div`
  text-align: center;
  color: ${COLORS.text.secondary};
  font-size: 1.1rem;
  padding: 1rem;
`;

const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 1.5rem;
`;

const Button = styled.button`
  background-color: ${COLORS.background.primary};
  color: ${COLORS.text.primary};
  border: 2px solid ${COLORS.primary.main};
  padding: 0.75rem 1rem;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: ${COLORS.primary.main};
    color: ${COLORS.text.white};
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  &:disabled {
    background-color: ${COLORS.text.light};
    border-color: ${COLORS.text.light};
    color: ${COLORS.text.white};
    cursor: not-allowed;
    transform: none;
  }
`;

const FindButton = styled(Button)`
  background-color: #0066cc;
  color: white;
`;

export default HiringManager; 
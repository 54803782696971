import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import styled from 'styled-components';
import profilePic from '../images/Anonymous-Profile-pic.jpg';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background: #FFFFFF;
  border-bottom: 1px solid #EAEAEA;

  @media (max-width: 768px) {
    padding: 1rem;
    flex-direction: column;
    gap: 1rem;
  }
`;

const Logo = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  color: #000000;
  cursor: pointer;
  margin: 0;
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  gap: 1.5rem;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    width: 100%;
  }
`;

const NavButton = styled.button`
  background: none;
  border: none;
  color: #666;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.5rem 0;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  white-space: nowrap;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #000;
    transition: width 0.2s ease;
  }

  &:hover {
    color: #000;
    &:after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    font-size: 0.85rem;
    padding: 0.4rem 0.8rem;
  }
`;

const ActionButton = styled(NavButton)`
  background-color: ${props => props.primary ? '#000' : '#FFF'};
  color: ${props => props.primary ? '#FFF' : '#000'};
  border: 1px solid #000;
  padding: 0.5rem 1rem;
  border-radius: 4px;

  &:hover {
    background-color: ${props => props.primary ? '#333' : '#F5F5F5'};
    &:after {
      width: 0;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    margin-top: 0.5rem;
    order: -1;
  }
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    order: -2;
  }
`;

const ProfilePic = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  object-fit: cover;

  @media (max-width: 768px) {
    height: 28px;
    width: 28px;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownButton = styled(NavButton)`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  
  &:after {
    display: none;
  }
`;

const DropdownContent = styled.div`
  display: ${props => props.isOpen ? 'block' : 'none'};
  position: absolute;
  background-color: #fff;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 4px;
  top: 100%;
  left: 0;
  margin-top: 0.5rem;
`;

const DropdownItem = styled.button`
  color: #666;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 0.9rem;
  
  &:hover {
    background-color: #f1f1f1;
    color: #000;
  }
`;

const DropdownDivider = styled.div`
  height: 1px;
  background-color: #e1e1e1;
  margin: 0.25rem 0;
`;

const ChevronIcon = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-right: 2px solid #666;
  border-bottom: 2px solid #666;
  transform: ${props => props.isOpen ? 'rotate(225deg)' : 'rotate(45deg)'};
  transition: transform 0.2s ease;
  margin-top: ${props => props.isOpen ? '5px' : '0'};
`;

const Header = () => {
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const [toolsDropdownOpen, setToolsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleNavigation = (path) => () => {
    navigate(path);
    setToolsDropdownOpen(false);
  };

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('authToken');
    navigate('/');
  };

  const toggleToolsDropdown = () => {
    setToolsDropdownOpen(!toolsDropdownOpen);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setToolsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <HeaderContainer>
      <Logo onClick={handleNavigation('/')}>CvAnalyst</Logo>
      <Nav>
        {/*<NavButton onClick={handleNavigation('/search-jobs')}>
          Job Search
        </NavButton>
        <NavButton onClick={handleNavigation('/resume-enhancer')}>
          Resume
        </NavButton>
        <NavButton onClick={handleNavigation('/cover-letter-generator')}>
          Cover Letter
        </NavButton>*/}

        <DropdownContainer ref={dropdownRef}>
          <DropdownButton onClick={toggleToolsDropdown}>
            Tools <ChevronIcon isOpen={toolsDropdownOpen} />
          </DropdownButton>
          <DropdownContent isOpen={toolsDropdownOpen}>
            
            <DropdownDivider />
            <DropdownItem onClick={handleNavigation('/resume-enhancer')}>
              Resume Enhancer
            </DropdownItem>
            <DropdownItem onClick={handleNavigation('/cover-letter-generator')}>
              Cover Letter Generator
            </DropdownItem>
            <DropdownItem onClick={handleNavigation('/linkedin-job-id')}>
              LinkedIn URL To Resume + CV
            </DropdownItem>
            <DropdownItem onClick={handleNavigation('/hiring-manager')}>
              Job Postings with Hiring Manager
            </DropdownItem>

          </DropdownContent>
        </DropdownContainer>
        <NavButton onClick={handleNavigation('/billing')}>
          Billing
        </NavButton>
        {user ? (
          <ProfileContainer>
            <NavButton onClick={handleNavigation('/profile')}>
              Profile
            </NavButton>
            <ActionButton onClick={handleLogout}>
              Logout
            </ActionButton>
            <ProfilePic src={profilePic} alt="Profile" />
          </ProfileContainer>
        ) : (
          <ActionButton primary onClick={handleNavigation('/signin')}>
            Login
          </ActionButton>
        )}
      </Nav>
    </HeaderContainer>
  );
};

export default Header;
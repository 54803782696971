import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import styled from 'styled-components';
import axios from 'axios';

const BACKEND_API_URL = 'http://localhost:8000';

const LinkedinEmailFinder = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [credits, setCredits] = useState(0);
  const [searchType, setSearchType] = useState('url');
  const [formData, setFormData] = useState({
    linkedin_url: '',
    full_name: '',
    company: ''
  });
  const [result, setResult] = useState(null);

  useEffect(() => {
    if (!user) {
      navigate('/signin');
      return;
    }
    
    // Check available credits
    checkCredits();
  }, [user, navigate]);

  const checkCredits = async () => {
    try {
      const response = await axios.get(`${BACKEND_API_URL}/check-credits`, {
        withCredentials: true
      });
      
      if (response.data && response.data.credits_remaining !== undefined) {
        setCredits(response.data.credits_remaining);
      }
    } catch (err) {
      console.error('Error checking credits:', err);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSearchTypeChange = (type) => {
    setSearchType(type);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess(false);
    setResult(null);

    try {
      // Prepare data based on search type
      const data = {};
      if (searchType === 'url') {
        if (!formData.linkedin_url) {
          throw new Error('LinkedIn URL is required');
        }
        data.linkedin_url = formData.linkedin_url;
      } else {
        if (!formData.full_name || !formData.company) {
          throw new Error('Both full name and company are required');
        }
        data.full_name = formData.full_name;
        data.company = formData.company;
      }

      // Make API request
      const response = await axios.post(`${BACKEND_API_URL}/find-email`, data, {
        withCredentials: true
      });

      if (response.data && response.data.success) {
        setSuccess(true);
        setResult(response.data.data);
        setCredits(response.data.credits_remaining);
      }
    } catch (err) {
      console.error('Error finding email:', err);
      setError(err.response?.data?.detail || err.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const handleBulkSearch = () => {
    // Navigate to bulk search page (to be implemented)
    navigate('/bulk-email-finder');
  };

  return (
    <Container>
      <Title>LinkedIn Email Finder</Title>
      <Description>
        Find email addresses associated with LinkedIn profiles to connect with potential employers or clients.
      </Description>

      <CreditsInfo>
        <strong>Available Credits:</strong> {credits}
      </CreditsInfo>

      <SearchTypeToggle>
        <ToggleButton 
          active={searchType === 'url'} 
          onClick={() => handleSearchTypeChange('url')}
        >
          Search by LinkedIn URL
        </ToggleButton>
        <ToggleButton 
          active={searchType === 'name'} 
          onClick={() => handleSearchTypeChange('name')}
        >
          Search by Name & Company
        </ToggleButton>
      </SearchTypeToggle>

      <Form onSubmit={handleSubmit}>
        {searchType === 'url' ? (
          <FormGroup>
            <Label htmlFor="linkedin_url">LinkedIn Profile URL</Label>
            <Input
              type="text"
              id="linkedin_url"
              name="linkedin_url"
              value={formData.linkedin_url}
              onChange={handleInputChange}
              placeholder="https://www.linkedin.com/in/username"
              required={searchType === 'url'}
            />
          </FormGroup>
        ) : (
          <>
            <FormGroup>
              <Label htmlFor="full_name">Full Name</Label>
              <Input
                type="text"
                id="full_name"
                name="full_name"
                value={formData.full_name}
                onChange={handleInputChange}
                placeholder="John Doe"
                required={searchType === 'name'}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="company">Company</Label>
              <Input
                type="text"
                id="company"
                name="company"
                value={formData.company}
                onChange={handleInputChange}
                placeholder="Company Inc."
                required={searchType === 'name'}
              />
            </FormGroup>
          </>
        )}

        <ButtonGroup>
          <SubmitButton type="submit" disabled={loading}>
            {loading ? 'Searching...' : 'Find Email'}
          </SubmitButton>
          <BulkButton type="button" onClick={handleBulkSearch}>
            Bulk Email Search
          </BulkButton>
        </ButtonGroup>
      </Form>

      {error && <ErrorMessage>{error}</ErrorMessage>}

      {success && result && (
        <ResultContainer>
          <ResultTitle>Email Found!</ResultTitle>
          <ResultItem>
            <ResultLabel>Name:</ResultLabel>
            <ResultValue>{result.name || 'N/A'}</ResultValue>
          </ResultItem>
          <ResultItem>
            <ResultLabel>Email:</ResultLabel>
            <ResultValue>{result.email || 'N/A'}</ResultValue>
          </ResultItem>
          <ResultItem>
            <ResultLabel>Confidence:</ResultLabel>
            <ResultValue>{result.confidence || 'N/A'}%</ResultValue>
          </ResultItem>
          {result.company && (
            <ResultItem>
              <ResultLabel>Company:</ResultLabel>
              <ResultValue>{result.company}</ResultValue>
            </ResultItem>
          )}
          {result.position && (
            <ResultItem>
              <ResultLabel>Position:</ResultLabel>
              <ResultValue>{result.position}</ResultValue>
            </ResultItem>
          )}
        </ResultContainer>
      )}
    </Container>
  );
};

// Styled Components
const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #333;
`;

const Description = styled.p`
  margin-bottom: 2rem;
  color: #666;
  line-height: 1.5;
`;

const CreditsInfo = styled.div`
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1.5rem;
  font-size: 1rem;
`;

const SearchTypeToggle = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  border-radius: 5px;
  overflow: hidden;
`;

const ToggleButton = styled.button`
  flex: 1;
  padding: 0.75rem;
  background-color: ${props => props.active ? '#333' : '#f5f5f5'};
  color: ${props => props.active ? 'white' : '#333'};
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: ${props => props.active ? 'bold' : 'normal'};

  &:hover {
    background-color: ${props => props.active ? '#222' : '#e5e5e5'};
  }
`;

const Form = styled.form`
  margin-bottom: 2rem;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  
  &:focus {
    outline: none;
    border-color: #333;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
`;

const SubmitButton = styled.button`
  background-color: #333;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #222;
  }
  
  &:disabled {
    background-color: #999;
    cursor: not-allowed;
  }
`;

const BulkButton = styled.button`
  background-color: #f5f5f5;
  color: #333;
  border: 1px solid #ddd;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: #e5e5e5;
  }
`;

const ErrorMessage = styled.div`
  color: #d9534f;
  padding: 1rem;
  background-color: #f8d7da;
  border-radius: 4px;
  margin-bottom: 1.5rem;
`;

const ResultContainer = styled.div`
  background-color: #f8f9fa;
  border-radius: 5px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ResultTitle = styled.h3`
  margin-bottom: 1rem;
  color: #333;
`;

const ResultItem = styled.div`
  display: flex;
  margin-bottom: 0.75rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #eee;
  
  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

const ResultLabel = styled.span`
  font-weight: 600;
  width: 120px;
  color: #555;
`;

const ResultValue = styled.span`
  flex: 1;
`;

export default LinkedinEmailFinder; 
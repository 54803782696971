import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import styled from 'styled-components';
import axios from 'axios';

const BACKEND_API_URL = 'http://localhost:8000';

const ActiveJobs = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [totalResults, setTotalResults] = useState(0);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const resultsPerPage = 10;
  
  // Helper function to format dates
  const formatDate = (dateString) => {
    if (!dateString || !dateString.startsWith('202')) return dateString || 'Recently';
    
    try {
      const dateObj = new Date(dateString);
      if (isNaN(dateObj.getTime())) return dateString;
      
      // Format as MM/DD/YYYY
      return `${(dateObj.getMonth() + 1).toString().padStart(2, '0')}/${dateObj.getDate().toString().padStart(2, '0')}/${dateObj.getFullYear()}`;
    } catch (e) {
      console.error('Error formatting date:', e);
      return dateString;
    }
  };
  
  const [formData, setFormData] = useState({
    query: '',
    location: '',
    company: '',
    job_type: '',
    experience: ''
  });

  useEffect(() => {
    if (!user) {
      navigate('/signin');
    }
  }, [user, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const getAuthToken = () => {
    // Try multiple possible storage locations for the token
    const localToken = localStorage.getItem('token');
    const sessionToken = sessionStorage.getItem('token');
    const jwtToken = localStorage.getItem('jwtToken');
    
    // Check if the user context has a token
    const userToken = user?.token;
    
    // Log for debugging
    console.log('Auth tokens available:', { 
      localToken: !!localToken, 
      sessionToken: !!sessionToken, 
      jwtToken: !!jwtToken,
      userToken: !!userToken,
      user: user
    });
    
    // Return the first available token
    return localToken || sessionToken || jwtToken || userToken;
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess(false);
    setSearchResults([]);
    setSelectedJob(null);
    setPage(1);

    try {
      // Validate at least one search parameter
      if (!formData.query && !formData.location && !formData.company) {
        throw new Error('Please provide at least one search parameter (job title, location, or company)');
      }

      // Prepare search parameters
      const params = {};
      if (formData.query) params.query = formData.query;
      if (formData.location) params.location = formData.location;
      if (formData.company) params.company = formData.company;
      if (formData.job_type) params.job_type = formData.job_type;
      if (formData.experience) params.experience = formData.experience;
      
      params.page = page;
      params.limit = resultsPerPage;

      console.log('Sending search request with params:', params);

      // Get JWT token
      const token = getAuthToken();
      console.log('Using token for request:', token ? 'Token available' : 'No token found');
      
      // For development/testing, proceed even without a token
      // In production, you would want to enforce token presence
      
      // Configure headers with JWT token if available
      const config = {
        headers: {},
        withCredentials: true
      };
      
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      // Make API request - try both POST and GET methods
      let response;
      try {
        // First try POST method
        console.log('Attempting POST request with config:', config);
        response = await axios.post(`${BACKEND_API_URL}/search-jobs`, params, config);
      } catch (postError) {
        console.log('POST request failed, trying GET method:', postError);
        
        // If POST fails, try GET method with query parameters
        const getConfig = { ...config, params };
        console.log('Attempting GET request with config:', getConfig);
        response = await axios.get(`${BACKEND_API_URL}/search-active-jobs`, getConfig);
      }

      if (response.data && response.data.success) {
        setSuccess(true);
        setSearchResults(response.data.data.jobs || []);
        setTotalResults(response.data.data.total || 0);
      }
    } catch (err) {
      console.error('Error searching jobs:', err);
      if (err.message === 'Authentication token not found. Please sign in again.') {
        // If token is missing, redirect to login
        navigate('/dev-login');
      } else {
        setError(err.response?.data?.detail || err.message || 'An error occurred');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLoadMore = async () => {
    if (loading || searchResults.length >= totalResults) return;
    
    setLoading(true);
    const nextPage = page + 1;
    
    try {
      // Prepare search parameters
      const params = {};
      if (formData.query) params.query = formData.query;
      if (formData.location) params.location = formData.location;
      if (formData.company) params.company = formData.company;
      if (formData.job_type) params.job_type = formData.job_type;
      if (formData.experience) params.experience = formData.experience;
      
      params.page = nextPage;
      params.limit = resultsPerPage;
      
      console.log('Sending load more request with params:', params);

      // Get JWT token
      const token = getAuthToken();
      
      // Configure headers with JWT token if available
      const config = {
        headers: {},
        withCredentials: true
      };
      
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      // Make API request - try both POST and GET methods
      let response;
      try {
        // First try POST method
        response = await axios.post(`${BACKEND_API_URL}/search-jobs`, params, config);
      } catch (postError) {
        console.log('POST request failed, trying GET method:', postError);
        
        // If POST fails, try GET method with query parameters
        const getConfig = { ...config, params };
        response = await axios.get(`${BACKEND_API_URL}/search-active-jobs`, getConfig);
      }
      
      if (response.data && response.data.success) {
        const newJobs = response.data.data.jobs || [];
        setSearchResults(prevResults => [...prevResults, ...newJobs]);
        setPage(nextPage);
      }
    } catch (err) {
      console.error('Error loading more jobs:', err);
      setError(err.response?.data?.detail || err.message || 'An error occurred while loading more jobs');
    } finally {
      setLoading(false);
    }
  };

  const handleViewJobDetails = async (jobId) => {
    setLoading(true);
    setError('');
    
    try {
      // Get JWT token
      const token = getAuthToken();
      
      // Configure headers with JWT token if available
      const config = {
        headers: {},
        withCredentials: true
      };
      
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      const response = await axios.get(`${BACKEND_API_URL}/job-details/${jobId}`, config);

      if (response.data && response.data.success) {
        setSelectedJob(response.data.data);
      }
    } catch (err) {
      console.error('Error fetching job details:', err);
      setError(err.response?.data?.detail || err.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const handleBackToResults = () => {
    setSelectedJob(null);
  };

  const handleFindHiringManager = (job) => {
    // Navigate to hiring manager page with job details
    navigate('/hiring-manager', { 
      state: { 
        job_url: job.url,
        company_name: job.company,
        job_title: job.title
      } 
    });
  };

  const renderSearchForm = () => (
    <Form onSubmit={handleSearch}>
      <FormGroup>
        <Label htmlFor="query">Job Title / Keywords</Label>
        <Input
          type="text"
          id="query"
          name="query"
          value={formData.query}
          onChange={handleInputChange}
          placeholder="Software Engineer, Marketing, etc."
        />
      </FormGroup>
      
      <FormRow>
        <FormGroup>
          <Label htmlFor="location">Location</Label>
          <Input
            type="text"
            id="location"
            name="location"
            value={formData.location}
            onChange={handleInputChange}
            placeholder="New York, Remote, etc."
          />
        </FormGroup>
        
        <FormGroup>
          <Label htmlFor="company">Company</Label>
          <Input
            type="text"
            id="company"
            name="company"
            value={formData.company}
            onChange={handleInputChange}
            placeholder="Google, Amazon, etc."
          />
        </FormGroup>
      </FormRow>
      
      <FormRow>
        <FormGroup>
          <Label htmlFor="job_type">Job Type</Label>
          <Select
            id="job_type"
            name="job_type"
            value={formData.job_type}
            onChange={handleInputChange}
          >
            <option value="">Any</option>
            <option value="full_time">Full-time</option>
            <option value="part_time">Part-time</option>
            <option value="contract">Contract</option>
            <option value="internship">Internship</option>
            <option value="temporary">Temporary</option>
          </Select>
        </FormGroup>
        
        <FormGroup>
          <Label htmlFor="experience">Experience Level</Label>
          <Select
            id="experience"
            name="experience"
            value={formData.experience}
            onChange={handleInputChange}
          >
            <option value="">Any</option>
            <option value="entry_level">Entry Level</option>
            <option value="mid_level">Mid Level</option>
            <option value="senior_level">Senior Level</option>
            <option value="director">Director</option>
            <option value="executive">Executive</option>
          </Select>
        </FormGroup>
      </FormRow>
      
      <SubmitButton type="submit" disabled={loading}>
        {loading ? 'Searching...' : 'Search Jobs'}
      </SubmitButton>
    </Form>
  );

  const renderSearchResults = () => {
    if (!searchResults.length) {
      return success ? (
        <NoResults>No jobs found matching your search criteria. Try adjusting your search parameters.</NoResults>
      ) : null;
    }

    return (
      <ResultsContainer>
        <ResultsHeader>
          <ResultsCount>{searchResults.length} of {totalResults} jobs found</ResultsCount>
        </ResultsHeader>
        
        <JobList>
          {searchResults.map((job, index) => (
            <JobCard key={index}>
              <JobTitle>{job.title}</JobTitle>
              <JobCompany>{job.company}</JobCompany>
              <JobLocation>{job.location}</JobLocation>
              {job.salary && <JobSalary>{job.salary}</JobSalary>}
              <JobDate>Posted: {formatDate(job.date_posted)}</JobDate>
              
              <JobActions>
                <ActionButton onClick={() => handleViewJobDetails(job.id)}>
                  View Details
                </ActionButton>
                <ActionButton secondary onClick={() => handleFindHiringManager(job)}>
                  Find Hiring Manager
                </ActionButton>
              </JobActions>
            </JobCard>
          ))}
        </JobList>
        
        {searchResults.length < totalResults && (
          <LoadMoreButton onClick={handleLoadMore} disabled={loading}>
            {loading ? 'Loading...' : 'Load More Jobs'}
          </LoadMoreButton>
        )}
      </ResultsContainer>
    );
  };

  const renderJobDetails = () => {
    if (!selectedJob) return null;

    return (
      <JobDetailsContainer>
        <BackButton onClick={handleBackToResults}>
          ← Back to Results
        </BackButton>
        
        <JobDetailsHeader>
          <JobDetailsTitle>{selectedJob.title}</JobDetailsTitle>
          <JobDetailsCompany>{selectedJob.company}</JobDetailsCompany>
          <JobDetailsLocation>{selectedJob.location}</JobDetailsLocation>
          {selectedJob.salary && <JobDetailsSalary>{selectedJob.salary}</JobDetailsSalary>}
          <JobDetailsDate>Posted: {formatDate(selectedJob.date_posted)}</JobDetailsDate>
        </JobDetailsHeader>
        
        <JobDetailsSection>
          <SectionTitle>Job Description</SectionTitle>
          <JobDescription dangerouslySetInnerHTML={{ __html: selectedJob.description }} />
        </JobDetailsSection>
        
        {selectedJob.requirements && (
          <JobDetailsSection>
            <SectionTitle>Requirements</SectionTitle>
            <JobRequirements dangerouslySetInnerHTML={{ __html: selectedJob.requirements }} />
          </JobDetailsSection>
        )}
        
        {selectedJob.benefits && (
          <JobDetailsSection>
            <SectionTitle>Benefits</SectionTitle>
            <JobBenefits dangerouslySetInnerHTML={{ __html: selectedJob.benefits }} />
          </JobDetailsSection>
        )}
        
        <JobDetailsActions>
          {selectedJob.url && (
            <ActionButton as="a" href={selectedJob.url} target="_blank" rel="noopener noreferrer">
              Apply on Company Site
            </ActionButton>
          )}
          <ActionButton secondary onClick={() => handleFindHiringManager(selectedJob)}>
            Find Hiring Manager
          </ActionButton>
        </JobDetailsActions>
      </JobDetailsContainer>
    );
  };

  return (
    <Container>
      <Title>Active Jobs Database</Title>
      <Description>
        Search for active job listings across thousands of companies. Find detailed job descriptions, requirements, and connect with hiring managers.
      </Description>
      
      {!selectedJob && renderSearchForm()}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      
      {selectedJob ? renderJobDetails() : renderSearchResults()}
    </Container>
  );
};

// Styled Components
const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #333;
`;

const Description = styled.p`
  margin-bottom: 2rem;
  color: #666;
  line-height: 1.5;
`;

const Form = styled.form`
  margin-bottom: 2rem;
  background-color: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const FormRow = styled.div`
  display: flex;
  gap: 1rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
  flex: 1;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  
  &:focus {
    outline: none;
    border-color: #333;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  background-color: white;
  
  &:focus {
    outline: none;
    border-color: #333;
  }
`;

const SubmitButton = styled.button`
  background-color: #333;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #222;
  }
  
  &:disabled {
    background-color: #999;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  color: #d9534f;
  padding: 1rem;
  background-color: #f8d7da;
  border-radius: 4px;
  margin-bottom: 1.5rem;
`;

const NoResults = styled.div`
  text-align: center;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  color: #666;
`;

const ResultsContainer = styled.div`
  margin-top: 2rem;
`;

const ResultsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const ResultsCount = styled.div`
  font-size: 1rem;
  color: #666;
`;

const JobList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const JobCard = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
`;

const JobTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: #333;
`;

const JobCompany = styled.div`
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: #555;
`;

const JobLocation = styled.div`
  color: #666;
  margin-bottom: 0.5rem;
`;

const JobSalary = styled.div`
  color: #28a745;
  font-weight: 500;
  margin-bottom: 0.5rem;
`;

const JobDate = styled.div`
  font-size: 0.9rem;
  color: #888;
  margin-bottom: 1rem;
`;

const JobActions = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

const ActionButton = styled.button`
  background-color: ${props => props.secondary ? '#f8f9fa' : '#333'};
  color: ${props => props.secondary ? '#333' : 'white'};
  border: ${props => props.secondary ? '1px solid #ddd' : 'none'};
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  display: inline-block;
  
  &:hover {
    background-color: ${props => props.secondary ? '#e9ecef' : '#222'};
  }
`;

const LoadMoreButton = styled.button`
  background-color: #f8f9fa;
  color: #333;
  border: 1px solid #ddd;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 2rem auto;
  display: block;
  
  &:hover {
    background-color: #e9ecef;
  }
  
  &:disabled {
    background-color: #f8f9fa;
    color: #999;
    cursor: not-allowed;
  }
`;

const JobDetailsContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: #0066cc;
  cursor: pointer;
  padding: 0;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  display: inline-block;
  
  &:hover {
    text-decoration: underline;
  }
`;

const JobDetailsHeader = styled.div`
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #eee;
`;

const JobDetailsTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
  color: #333;
`;

const JobDetailsCompany = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: #555;
`;

const JobDetailsLocation = styled.div`
  color: #666;
  margin-bottom: 0.5rem;
`;

const JobDetailsSalary = styled.div`
  color: #28a745;
  font-weight: 500;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
`;

const JobDetailsDate = styled.div`
  font-size: 0.9rem;
  color: #888;
`;

const JobDetailsSection = styled.div`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h3`
  font-size: 1.3rem;
  margin-bottom: 1rem;
  color: #333;
`;

const JobDescription = styled.div`
  line-height: 1.6;
  color: #444;
  
  ul, ol {
    padding-left: 1.5rem;
    margin-bottom: 1rem;
  }
  
  p {
    margin-bottom: 1rem;
  }
`;

const JobRequirements = styled.div`
  line-height: 1.6;
  color: #444;
  
  ul, ol {
    padding-left: 1.5rem;
    margin-bottom: 1rem;
  }
  
  p {
    margin-bottom: 1rem;
  }
`;

const JobBenefits = styled.div`
  line-height: 1.6;
  color: #444;
  
  ul, ol {
    padding-left: 1.5rem;
    margin-bottom: 1rem;
  }
  
  p {
    margin-bottom: 1rem;
  }
`;

const JobDetailsActions = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export default ActiveJobs; 
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://cvanalystiobackend.onrender.com';

const COLORS = {
  primary: {
    main: '#000000',
    light: '#1A1A1A',
    dark: '#000000'
  },
  text: {
    primary: '#000000',
    secondary: '#4A4A4A',
    light: '#717171',
    white: '#FFFFFF'
  },
  background: {
    primary: '#FFFFFF',
    secondary: '#F8F8F8',
    accent: '#F3F4F6'
  },
  border: '#E5E5E5'
};

const FreeHiringManager = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [jobs, setJobs] = useState([]);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [selectedJobForUnlock, setSelectedJobForUnlock] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [searchParams, setSearchParams] = useState({
    title: '',
    location: '',
    remote: false,
    visa_sponsorship: false,
    timeframe: '24h'
  });
  const [searchesRemaining, setSearchesRemaining] = useState(0);

  const handleSearchParamsChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSearchParams(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSearch = async (e, isLoadMore = false) => {
    if (e) e.preventDefault();
    
    if (!isLoadMore) {
      setOffset(0);
      setJobs([]);
      setHasMore(true);
    }
    
    setLoading(true);
    setError('');

    try {
      // Only check search limit for new searches, not when loading more results
      if (!isLoadMore) {
        // Check if search is allowed
        const checkResponse = await axios.post(`${BACKEND_API_URL}/check_search_limit`);
        
        if (!checkResponse.data.allowed) {
          // Redirect to upgrade page
          window.location.href = "https://analyticpinnacle.thrivecart.com/cv-analyst/";
          setLoading(false);
          return;
        }
        
        // Update remaining searches
        setSearchesRemaining(checkResponse.data.remaining);
      }
      
      const queryParams = new URLSearchParams({
        title: searchParams.title,
        location: searchParams.location,
        remote: searchParams.remote,
        visa_sponsorship: searchParams.visa_sponsorship,
        offset: isLoadMore ? offset : 0
      });

      const response = await axios.get(
        `${BACKEND_API_URL}/jobs/${searchParams.timeframe}?${queryParams}`
      );

      const jobsData = response.data;
      if (Array.isArray(jobsData)) {
        const transformedJobs = jobsData.map(job => ({
          id: job.id,
          title: job.title,
          company: job.organization,
          location: job.locations_derived ? job.locations_derived[0] : 'Remote',
          url: job.url,
          date_posted: job.date_posted,
          hiring_manager: {
            name: job.li_hiring_manager_name || job.ai_hiring_manager_name || 'Unknown',
            title: job.li_hiring_manager_title || '',
            linkedin_url: job.li_hiring_manager_url || '',
            has_email: job.li_hiring_manager_url ? true : false
          }
        }));

        if (isLoadMore) {
          setJobs(prev => [...prev, ...transformedJobs]);
        } else {
          setJobs(transformedJobs);
        }

        setHasMore(transformedJobs.length === 20);
        setOffset(prev => isLoadMore ? prev + 20 : 20);
      } else {
        setError('Invalid response format from server');
      }
    } catch (err) {
      setError(err.response?.data?.detail || 'Failed to search jobs');
    } finally {
      setLoading(false);
    }
  };

  const handleLoadMore = () => {
    handleSearch(null, true);
  };

  const handleUnlockClick = (job) => {
    // Redirect to payment page instead of showing email modal
    window.location.href = 'https://analyticpinnacle.thrivecart.com/cv-analyst/';
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const email = e.target.email.value;
      
      // First check if this email/IP has already used their free trial
      const checkResponse = await axios.post(`${BACKEND_API_URL}/check-free-trial`, {
        email: email
      });

      if (!checkResponse.data.can_use_trial) {
        setError('You have already used your free trial. Please upgrade to unlock more hiring manager emails.');
        return;
      }

      // If they can use the trial, proceed with the unlock
      const response = await axios.post(`${BACKEND_API_URL}/free-unlock-email`, {
        email: email,
        linkedin_url: selectedJobForUnlock.hiring_manager.linkedin_url
      });

      if (response.data.success) {
        const emailData = response.data.data;
        const updatedJobs = jobs.map(job => {
          if (job.id === selectedJobForUnlock.id) {
            return {
              ...job,
              hiring_manager: {
                ...job.hiring_manager,
                email: emailData.email,
                email_confidence: emailData.confidence,
                email_status: emailData.status,
                email_type: emailData.type
              }
            };
          }
          return job;
        });
        setJobs(updatedJobs);
        setShowEmailModal(false);
      }
    } catch (err) {
      setError(err.response?.data?.detail || 'Failed to unlock email');
    } finally {
      setLoading(false);
    }
  };

  const handleBothDocuments = async (job) => {
    // Store job info in localStorage
    localStorage.setItem('selectedJob', JSON.stringify({
      id: job.id,
      title: job.title,
      company: job.company,
      url: job.url,
      hiring_manager: job.hiring_manager
    }));

    // Redirect to FreeResumeOptimization with job URL
    navigate(`/free-hiring-resume?jobUrl=${encodeURIComponent(job.url)}`);
  };

  // Add useEffect to check for returning from FreeResume
  useEffect(() => {
    const storedJob = localStorage.getItem('selectedJob');
    const hasGeneratedResume = localStorage.getItem('hasGeneratedResume');
    
    if (storedJob && hasGeneratedResume) {
      // Clear the flags
      localStorage.removeItem('hasGeneratedResume');
      localStorage.removeItem('selectedJob');
      
      // Parse the stored job
      const job = JSON.parse(storedJob);
      
      // Show email unlock modal
      setSelectedJobForUnlock(job);
      setShowEmailModal(true);
    }
  }, []);

  return (
    <Container>
      <Title>Find Hiring Managers for Any Company</Title>
      <Description>
        You have 10 free searches & 1 Resume + Cover Letter + Outreach Email. After your free trial, upgrade to unlock hiring manager emails and searches.
      </Description>

      <SearchForm onSubmit={handleSearch}>
        <FormGroup>
          <Label>Job Title</Label>
          <Input
            name="title"
            value={searchParams.title}
            onChange={handleSearchParamsChange}
            placeholder="e.g. Software Engineer"
            required
          />
        </FormGroup>

        <FormGroup>
          <Label>Location</Label>
          <Input
            name="location"
            value={searchParams.location}
            onChange={handleSearchParamsChange}
            placeholder="United States"
            required
          />
        </FormGroup>

        {searchesRemaining !== null && searchesRemaining !== undefined && (
          <div style={{ fontSize: '0.8rem', marginBottom: '10px', color: '#666', gridColumn: '1 / -1' }}>
            Searches remaining today: {searchesRemaining}/3
          </div>
        )}

        <SearchButton type="submit" disabled={loading}>
          {loading ? 'Searching...' : 'Search Jobs'}
        </SearchButton>
      </SearchForm>

      {error && <ErrorMessage>{error}</ErrorMessage>}

      <ResultsContainer>
        {jobs.map(job => (
          <JobCard key={job.id}>
            <JobTitle>{job.title}</JobTitle>
            <JobLink href={job.url} target="_blank" rel="noopener noreferrer">
              View Job Posting
            </JobLink>
            <JobCompany>{job.company}</JobCompany>
            <JobLocation>{job.location}</JobLocation>
            <JobDetails>
              {job.remote && <Tag>Remote</Tag>}
              {job.visa_sponsorship && <Tag>Visa Sponsorship</Tag>}
            </JobDetails>

            <ButtonGroup>
              <GenerateDocsButton onClick={() => handleBothDocuments(job)} disabled={loading}>
                Generate Tailored Resume + Cover Letter + Outreach Email
              </GenerateDocsButton>
            </ButtonGroup>
            
            <HiringManagerSection>
              <HiringManagerTitle>Hiring Manager</HiringManagerTitle>
              <HiringManagerInfo>
                <div>
                  <strong>{job.hiring_manager.name}</strong>
                  <p>{job.hiring_manager.title}</p>
                </div>
                {job.hiring_manager.linkedin_url && (
                  <LinkedInLink 
                    href={job.hiring_manager.linkedin_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Hiring Manager LinkedIn
                  </LinkedInLink>
                )}
              </HiringManagerInfo>
              
              {job.hiring_manager.has_email && !job.hiring_manager.email && (
                <UnlockButton
                  onClick={() => handleUnlockClick(job)}
                  disabled={loading}
                >
                  Unlock Email (Pro Users Only)
                </UnlockButton>
              )}
              
              {job.hiring_manager.email && (
                <EmailContainer>
                  <EmailValue>
                    <div><strong>Email:</strong> {job.hiring_manager.email}</div>
                    {job.hiring_manager.email_confidence && (
                      <div><strong>Confidence:</strong> {(job.hiring_manager.email_confidence * 100).toFixed(0)}%</div>
                    )}
                    {job.hiring_manager.email_status && (
                      <div><strong>Status:</strong> {job.hiring_manager.email_status}</div>
                    )}
                    {job.hiring_manager.email_type && (
                      <div><strong>Type:</strong> {job.hiring_manager.email_type}</div>
                    )}
                  </EmailValue>
                  <UpgradeButton onClick={() => navigate('/pricing')}>
                    Upgrade to Send Emails
                  </UpgradeButton>
                </EmailContainer>
              )}
            </HiringManagerSection>
          </JobCard>
        ))}
      </ResultsContainer>

      {jobs.length > 0 && (
        <LoadMoreContainer>
          {hasMore ? (
            <LoadMoreButton onClick={handleLoadMore} disabled={loading}>
              {loading ? 'Loading...' : 'Load More Jobs'}
            </LoadMoreButton>
          ) : (
            <NoMoreResults>No more jobs to load</NoMoreResults>
          )}
        </LoadMoreContainer>
      )}

      {showEmailModal && (
        <Modal>
          <ModalContent>
            <ModalHeader>
              <h3>Enter Your Email to Unlock</h3>
              <CloseButton onClick={() => setShowEmailModal(false)}>&times;</CloseButton>
            </ModalHeader>
            
            <EmailForm onSubmit={handleEmailSubmit}>
              <FormGroup>
                <Label>Email Address</Label>
                <Input
                  name="email"
                  type="email"
                  placeholder="Enter your email address"
                  required
                />
              </FormGroup>
              
              <TermsText>
                By unlocking this email, you agree to our Terms of Service and Privacy Policy.
                You will receive one free hiring manager email unlock.
              </TermsText>
              
              <UnlockEmailButton type="submit" disabled={loading}>
                {loading ? 'Unlocking...' : 'Unlock Email'}
              </UnlockEmailButton>
            </EmailForm>
          </ModalContent>
        </Modal>
      )}
    </Container>
  );
};

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background: ${COLORS.background.primary};
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: ${COLORS.text.primary};
  font-weight: 700;
`;

const Description = styled.p`
  margin-bottom: 2rem;
  color: ${COLORS.text.secondary};
  line-height: 1.6;
  font-size: 1.1rem;
`;

const SearchForm = styled.form`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
  padding: 2rem;
  background: ${COLORS.background.secondary};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: ${COLORS.text.primary};
  font-size: 0.95rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 2px solid ${COLORS.border};
  border-radius: 4px;
  font-size: 1rem;
  transition: all 0.2s ease;
  
  &:focus {
    outline: none;
    border-color: ${COLORS.primary.main};
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: 2px solid ${COLORS.border};
  border-radius: 4px;
  font-size: 1rem;
  background: white;
  transition: all 0.2s ease;
  
  &:focus {
    outline: none;
    border-color: ${COLORS.primary.main};
  }
`;

const Button = styled.button`
  background-color: ${COLORS.background.primary};
  color: ${COLORS.text.primary};
  border: 2px solid ${COLORS.primary.main};
  padding: 0.75rem 1rem;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: ${COLORS.primary.main};
    color: ${COLORS.text.white};
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  &:disabled {
    background-color: ${COLORS.text.light};
    border-color: ${COLORS.text.light};
    color: ${COLORS.text.white};
    cursor: not-allowed;
    transform: none;
  }
`;

const SearchButton = styled(Button)`
  grid-column: 1 / -1;
  background-color: ${COLORS.primary.main};
  color: ${COLORS.text.white};
`;

const ResultsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;
`;

const JobCard = styled.div`
  background: ${COLORS.background.primary};
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
  }
`;

const JobTitle = styled.h3`
  margin: 0 0 0.75rem;
  color: ${COLORS.text.primary};
  font-size: 1.5rem;
  font-weight: 600;
`;

const JobLink = styled.a`
  display: inline-block;
  color: #0066cc;
  text-decoration: none;
  margin-bottom: 0.75rem;
  font-weight: 500;
  font-size: 0.95rem;
  
  &:hover {
    text-decoration: underline;
  }
`;

const JobCompany = styled.div`
  font-weight: 500;
  color: #666;
  margin-bottom: 0.5rem;
`;

const JobLocation = styled.div`
  color: #666;
  margin-bottom: 1rem;
`;

const JobDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const Tag = styled.span`
  background: ${COLORS.background.accent};
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  font-size: 0.875rem;
  color: ${COLORS.text.secondary};
  font-weight: 500;
`;

const HiringManagerSection = styled.div`
  border-top: 1px solid #eee;
  padding-top: 1rem;
  margin-top: 1rem;
`;

const HiringManagerTitle = styled.h4`
  margin: 0 0 0.5rem;
  color: #333;
`;

const HiringManagerInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
`;

const LinkedInLink = styled.a`
  color: #0066cc;
  text-decoration: none;
  font-weight: 500;
  
  &:hover {
    text-decoration: underline;
  }
`;

const UnlockButton = styled(Button)`
  width: 100%;
  background-color: ${COLORS.primary.main};
  color: ${COLORS.text.white};
`;

const EmailContainer = styled.div`
  margin-top: 1rem;
`;

const EmailValue = styled.div`
  padding: 0.5rem;
  background: #f8f9fa;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  word-break: break-all;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`  background: ${COLORS.background.primary};
  border-radius: 8px;
  padding: 2.5rem;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  color: #666;
  
  &:hover {
    color: #333;
  }
`;

const EmailForm = styled.form`
  margin-bottom: 2rem;
`;

const ErrorMessage = styled.div`
  color: #dc3545;
  padding: 1rem;
  background-color: #f8d7da;
  border-radius: 4px;
  margin-bottom: 1.5rem;
  font-weight: 500;
`;

const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  padding: 1rem;
`;

const LoadMoreButton = styled(Button)`
  min-width: 200px;
  margin: 0 auto;
`;

const NoMoreResults = styled.div`
  text-align: center;
  color: ${COLORS.text.secondary};
  font-size: 1.1rem;
  padding: 1rem;
`;

const TermsText = styled.p`
  font-size: 0.875rem;
  color: ${COLORS.text.secondary};
  margin: 1rem 0;
  line-height: 1.5;
`;

const UnlockEmailButton = styled(Button)`
  width: 100%;
  background-color: ${COLORS.primary.main};
  color: ${COLORS.text.white};
`;

const UpgradeButton = styled(Button)`
  width: 100%;
  background-color: #0066cc;
  color: white;
  border: none;
  margin-top: 1rem;
  
  &:hover {
    background-color: #0055aa;
  }
`;

const ButtonGroup = styled.div`
  margin: 1rem 0;
  display: grid;
  gap: 1rem;
`;

const GenerateDocsButton = styled(Button)`
  width: 100%;
  background-color: ${COLORS.primary.main};
  color: ${COLORS.text.white};
  border: none;
  
  &:hover {
    background-color: ${COLORS.primary.light};
  }
`;

export default FreeHiringManager; 

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { FaSpinner, FaCheckCircle, FaUpload } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://cvanalystiobackend.onrender.com';

const FreeResume = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [step, setStep] = useState('upload');
  const [linkedinUrl, setLinkedinUrl] = useState('');
  const [resume, setResume] = useState(null);
  const [resumeName, setResumeName] = useState('');
  const [email, setEmail] = useState('');
  const [enhancedResume, setEnhancedResume] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [processingStep, setProcessingStep] = useState(0);
  const [preview, setPreview] = useState(null);
  const [success, setSuccess] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const [showEmailForm, setShowEmailForm] = useState(false);

  const processingSteps = [
    "Scanning your resume...",
    "Identifying missing keywords...",
    "Enhancing formatting for recruiters...",
    "Generating your brand-new resume!"
  ];

  const extractJobIdFromUrl = (url) => {
    // Handle search result format
    const searchMatch = url.match(/currentJobId=(\d+)/);
    if (searchMatch) return searchMatch[1];
    
    // Handle direct job view format with text in between
    const viewMatch = url.match(/jobs\/view\/[^\/]*?(\d+)/);
    if (viewMatch) return viewMatch[1];
    
    // Handle clean direct match (ending with ID)
    const cleanDirectMatch = url.match(/(\d+)\/?$/);
    if (cleanDirectMatch) return cleanDirectMatch[1];
    
    // If the input is just a number, return it directly
    if (/^\d+$/.test(url)) return url;
    
    return null;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
        if (!file.name.toLowerCase().endsWith('.docx')) {
            setError("Please upload a DOCX file only. PDFs are not supported.");
            setResume(null);
            setResumeName('');
            return;
        }
        if (file.size > 5 * 1024 * 1024) {
            setError("File size too large. Please upload a file under 5MB.");
            return;
        }
        setResume(file);
        setResumeName(file.name);
        setError(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!resume || !linkedinUrl) {
      setError("Please provide your resume and LinkedIn job URL");
      return;
    }

    try {
      setStep('processing');
      setLoading(true);
      setError(null);

      const jobId = extractJobIdFromUrl(linkedinUrl);
      if (!jobId) {
        setError('Invalid LinkedIn job URL');
        setLoading(false);
        setStep('upload');
        return;
      }

      // Create form data with resume and job ID
      const formData = new FormData();
      formData.append('resume', resume);
      formData.append('job_id', jobId);
      
      const response = await axios.post(
        `${BACKEND_API_URL}/free_resume_enhancement`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          timeout: 300000, // 5 minute timeout
          timeoutErrorMessage: 'Request timed out - please try again',
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProcessingStep(Math.min(Math.floor(percentCompleted / 25), 3));
          }
        }
      );

      if (response.data.preview) {
        setPreviewData(response.data);
        setShowEmailForm(true);
        setStep('emailCapture');
      } else {
        throw new Error('No preview received from server');
      }
    } catch (err) {
      const errorMessage = err.response?.data?.detail || 
                         err.message === 'Network Error' ? 'Network error - please check your connection and try again' :
                         err.message === 'Request timed out - please try again' ? err.message :
                         (typeof err.message === 'string' ? err.message : 'Something went wrong');
      setError(errorMessage);
      setStep('upload');
    } finally {
      setLoading(false);
    }
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setError("Please enter your email");
      return;
    }
    
    try {
      setLoading(true);
      setError(null);
      
      const formData = new FormData();
      formData.append('email', email);
      formData.append('resume_text', previewData.full_text);
      formData.append('resume_file', previewData.file);
      
      const response = await axios.post(
        `${BACKEND_API_URL}/save_free_trial`, 
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          timeout: 60000,
          timeoutErrorMessage: 'Request timed out - please try again'
        }
      );
      
      setStep('success');
      setSuccess(true);

      // Download the file
      const blob = new Blob([Buffer.from(previewData.file, 'base64')], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'enhanced_resume.docx';
      a.click();

      // Set flag that resume was generated
      localStorage.setItem('hasGeneratedResume', 'true');

      // If we came from FreeHiringManager, go back there
      const storedJob = localStorage.getItem('selectedJob');
      if (storedJob) {
        navigate('/free-hiring-manager');
      }
    } catch (err) {
      let errorMessage;
      
      if (err.response?.status === 400 && err.response?.data?.detail?.includes('Free trial already used')) {
        errorMessage = 'This email has already been used for a free trial. Please use a different email or upgrade to Pro for unlimited access.';
      } else if (err.response?.status === 500 && (
        err.response?.data?.detail?.includes('duplicate key') || 
        err.response?.data?.detail?.includes('already exists')
      )) {
        errorMessage = 'This email has already been used for a free trial. Please use a different email or upgrade to Pro for unlimited access.';
      } else if (err.message === 'Network Error') {
        errorMessage = 'Network error - please check your connection and try again';
      } else if (err.message === 'Request timed out - please try again') {
        errorMessage = err.message;
      } else {
        errorMessage = 'Something went wrong. Please try again later.';
      }
      
      setError(errorMessage);
      
      // If it's a duplicate email error, show the upgrade button
      if (errorMessage.includes('already been used')) {
        setStep('upgrade');
      }
    } finally {
      setLoading(false);
    }
  };

  // Add useEffect to handle pre-filled job URL
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const jobUrl = params.get('jobUrl');
    if (jobUrl) {
      setLinkedinUrl(jobUrl);
    }
  }, [location]);

  return (
    <Container>
      {step === 'upload' && (
        <UploadSection>
          <Title>Instantly Generate a Job-Winning Resume</Title>
          <Subtitle>Upload your resume and paste the job URL. Get a fully optimized resume in minutes.</Subtitle>
          
          <Form onSubmit={handleSubmit}>
            <FileUploadContainer>
              <FileInput
                type="file"
                onChange={handleFileChange}
                accept=".docx"
                id="resume-upload"
              />
              <FileUploadLabel htmlFor="resume-upload" hasError={!!error}>
                <FaUpload />
                {resumeName || (
                  <>
                    <DocxIcon>DOCX</DocxIcon>
                    Upload Resume (DOCX files only)
                  </>
                )}
              </FileUploadLabel>
              <FileHelperText isError={!!error}>
                {error || "Please upload your resume in DOCX format only. PDFs are not supported for optimal AI enhancement."}
              </FileHelperText>
            </FileUploadContainer>

            <Input
              type="text"
              value={linkedinUrl}
              onChange={(e) => setLinkedinUrl(e.target.value)}
              placeholder="Paste LinkedIn Job URL"
              required
            />

            <SubmitButton type="submit" disabled={loading || !resume || !linkedinUrl}>
              {loading ? <FaSpinner className="spinning" /> : "Get My AI Resume Now"}
            </SubmitButton>
          </Form>
          
          {error && <ErrorMessage>{typeof error === 'string' ? error : 'An error occurred'}</ErrorMessage>}
        </UploadSection>
      )}

      {step === 'processing' && (
        <ProcessingSection>
          <ProcessingTitle>Optimizing Your Resume</ProcessingTitle>
          {processingSteps.map((step, index) => (
            <ProcessingStep key={index} active={index === processingStep}>
              {index < processingStep ? <FaCheckCircle color="#4CAF50" /> : <FaSpinner className={index === processingStep ? 'spinning' : ''} />}
              <span>{step}</span>
            </ProcessingStep>
          ))}
        </ProcessingSection>
      )}

      {step === 'emailCapture' && (
        <EmailCaptureSection>
          <BlurredPreview>
            {previewData?.preview_text}
            Your New Resume is Ready!
          </BlurredPreview>
          
          {previewData && showEmailForm && (
            <EmailForm onSubmit={handleEmailSubmit}>
              <EmailTitle>Enter your email to download the enhanced resume</EmailTitle>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
              />
              <SubmitButton type="submit" disabled={loading}>
                {loading ? 'Processing...' : 'Download Resume'}
              </SubmitButton>
              <SmallText>One free attempt per user. No spam, just your new resume.</SmallText>
            </EmailForm>
          )}
          
          {error && <ErrorMessage>{typeof error === 'string' ? error : 'An error occurred'}</ErrorMessage>}
        </EmailCaptureSection>
      )}

      {step === 'success' && (
        <SuccessSection>
          <SuccessTitle>🎉 Check Your Email!</SuccessTitle>
          <SuccessMessage>
            We've sent your AI-optimized resume to {email}. 
            Don't forget to check your spam folder if you don't see it in your inbox.
          </SuccessMessage>
          <UpgradeMessage>
            Want unlimited resume optimizations and cover letters?
          </UpgradeMessage>
          <UpgradeButton
            as="a"
            href="https://analyticpinnacle.thrivecart.com/cv-analyst/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Get Full Access Now
          </UpgradeButton>
        </SuccessSection>
      )}

      {step === 'upgrade' && (
        <UpgradeSection>
          <UpgradeTitle>You've Already Used Your Free Trial</UpgradeTitle>
          <UpgradeDescription>
            Upgrade now to unlock unlimited access and premium features!
          </UpgradeDescription>
          <FeatureList>
            <FeatureItem>Unlimited Resume Optimizations</FeatureItem>
            <FeatureItem>AI-Powered Cover Letter Generator</FeatureItem>
            <FeatureItem>ATS Scanner & Score Improvement</FeatureItem>
            <FeatureItem>Interview Question Generator</FeatureItem>
            <FeatureItem>Job Search Strategy Tools</FeatureItem>
          </FeatureList>
          <UpgradeButton
            as="a"
            href="https://analyticpinnacle.thrivecart.com/cv-analyst/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Upgrade Now - Special Offer
          </UpgradeButton>
        </UpgradeSection>
      )}
    </Container>
  );
};

// Styled Components
const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
  color: #1a1a1a;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  text-align: center;
  color: #666;
  margin-bottom: 2rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Input = styled.input`
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
`;

const FileUploadContainer = styled.div`
  position: relative;
  margin-bottom: 1rem;
`;

const FileInput = styled.input`
  display: none;
`;

const FileUploadLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;
  border: 2px dashed #ddd;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
  background-color: ${props => props.isDragOver ? '#f0f0f0' : 'transparent'};

  &:hover {
    border-color: #4A25E1;
    background-color: #f8f8f8;
  }

  ${props => props.hasError && `
    border-color: #ff4444;
    color: #ff4444;
  `}
`;

const FileHelperText = styled.p`
  font-size: 0.9rem;
  color: ${props => props.isError ? '#ff4444' : '#666'};
  margin-top: 0.5rem;
  text-align: center;
`;

const DocxIcon = styled.div`
  background-color: #2b579a;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  margin-right: 8px;
`;

const SubmitButton = styled.button`
  padding: 1rem;
  background-color: #4A25E1;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: #3615D0;
  }
`;

const ProcessingSection = styled.div`
  text-align: center;
  padding: 2rem;
`;

const ProcessingTitle = styled.h2`
  margin-bottom: 2rem;
`;

const ProcessingStep = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem 0;
  opacity: ${props => props.active ? 1 : 0.5};

  .spinning {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

const EmailCaptureSection = styled.div`
  text-align: center;
`;

const BlurredPreview = styled.div`
  background: rgba(74, 37, 225, 0.1);
  padding: 3rem;
  margin: 2rem 0;
  border-radius: 8px;
  font-size: 1.5rem;
  color: #4A25E1;
`;

const EmailForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 400px;
  margin: 0 auto;
`;

const EmailTitle = styled.h3`
  margin-bottom: 1rem;
`;

const SmallText = styled.p`
  font-size: 0.8rem;
  color: #666;
`;

const SuccessSection = styled.div`
  text-align: center;
  padding: 2rem;
`;

const SuccessTitle = styled.h2`
  color: #4CAF50;
  margin-bottom: 1rem;
`;

const SuccessMessage = styled.p`
  margin-bottom: 2rem;
`;

const UpgradeMessage = styled.p`
  font-size: 1.2rem;
  margin-bottom: 1rem;
`;

const UpgradeButton = styled(SubmitButton)`
  display: inline-block;
  text-decoration: none;
  background-color: #4CAF50;

  &:hover {
    background-color: #45a049;
  }
`;

const ErrorMessage = styled.p`
  color: #ff4444;
  margin-top: 1rem;
`;

const UploadSection = styled.div``;

const UpgradeSection = styled.div`
  text-align: center;
  padding: 2rem;
  background: #f8f9fa;
  border-radius: 12px;
  border: 2px solid #4A25E1;
  margin: 2rem auto;
  max-width: 600px;
`;

const UpgradeTitle = styled.h2`
  color: #4A25E1;
  margin-bottom: 1.5rem;
  font-size: 2rem;
`;

const UpgradeDescription = styled.p`
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 2rem;
  line-height: 1.6;
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 2rem 0;
  text-align: left;
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  color: #333;
  
  &:before {
    content: "✓";
    color: #4CAF50;
    margin-right: 10px;
    font-weight: bold;
  }
`;

export default FreeResume;